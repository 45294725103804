import { useReview } from "../../../services/review/apiProvider";
import { withLoader } from "../../../services/routing/useLoader";
import {
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../services/design-system/base-layout";
import { useTranslation } from "react-i18next";
import {
  ExtendAccess,
  MaterialType,
  DailyTonnage,
} from "../../../services/review/review";
import Routes from "../../../services/routing/Routes";
import { Route } from "react-router-dom";
import ReviewDetails from "./reviews/ReviewDetails";
import {
  REVIEW_DETAILS,
  REVIEW_DETAILS_LINK,
} from "../../../routes/super-admin/reviews";
import Link from "../../../services/routing/components/Link";
import PageContent from "../../../services/ui/elements/PageContent";
import Table from "../../../services/ui/elements/Table";

const Reviews = (): JSX.Element => {
  const { reviews } = useReview();
  const { t } = useTranslation(["review"]);

  return (
    <>
      <Head />

      <ViewName name={"Avis"} />

      <Content>
        <PageContent>
          <Table>
            <thead>
              <tr>
                <th>Nom</th>
                <th>Ville</th>
                <th>Email</th>
                <th>Note</th>
                <th>Type Matériaux</th>
                <th>Tonnage</th>
                <th>Bénéfices apportés</th>
                <th>Améliorations souhaitées</th>
                <th>Avis sur la prolongation</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {[...reviews.values()].map((review) => (
                <tr key={review.id}>
                  <td>{review.User.Company.name}</td>
                  <td>{review.User.Company.city}</td>
                  <td>{review.User.email}</td>
                  <td>{review.note}/5</td>
                  <td>
                    {review.materialType !== null &&
                      t(
                        `review:material-type.${
                          MaterialType[
                            review.materialType
                          ] as keyof typeof MaterialType
                        }` as const,
                      )}
                  </td>
                  <td>
                    {review.dailyTonnage !== null &&
                      t(
                        `review:daily-tonnage.${
                          DailyTonnage[
                            review.dailyTonnage
                          ] as keyof typeof DailyTonnage
                        }` as const,
                      )}
                  </td>
                  <td>{review.profitsBrought}</td>
                  <td>{review.improvementsWanted}</td>
                  <td>
                    {review.extendAccess !== null &&
                      t(
                        `review:extend-access.${
                          ExtendAccess[
                            review.extendAccess
                          ] as keyof typeof ExtendAccess
                        }` as const,
                      )}
                  </td>
                  <td>
                    <Link
                      className={"btn btn-1"}
                      params={{ reviewId: review.id }}
                      to={REVIEW_DETAILS_LINK}
                    >
                      Détails
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </PageContent>
      </Content>

      <Routes>
        <Route element={<ReviewDetails />} path={REVIEW_DETAILS} />
      </Routes>
    </>
  );
};

const useLoad = () => {
  const { loadReviews } = useReview();

  return loadReviews;
};

export default withProvideHead(withLoader(Reviews, useLoad));
