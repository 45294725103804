import { AxiosPromise } from "axios";
import { Review, ReviewForm } from "./review";
import baseAPI from "../auth/api";
import { User } from "../auth/user";
import { Company } from "../company/company";

export function createReview(review: ReviewForm): AxiosPromise<void> {
  return baseAPI.post("/reviews", review);
}

export interface GetReviewsResponse extends Review {
  User: User & {
    Company: Company;
  };
}

export function getReviews(): AxiosPromise<GetReviewsResponse[]> {
  return baseAPI.get("/reviews");
}
