import {
  useMaterial,
  withProvideMaterial,
} from "../../../../services/material/apiProvider";
import useLoader from "../../../../services/routing/useLoader";
import { useParams } from "../../../../services/routing/useParams";
import { MATERIAL_LINK } from "../../../../routes/user/materials";
import LoaderErrors from "../../../../services/routing/components/LoaderErrors";
import Loading from "../../../../services/routing/components/Loading";
import {
  ActionBar,
  ViewName,
} from "../../../../services/design-system/base-layout";
import Form from "../../../../services/forms/Form";
import AutoField from "../../../../services/forms/AutoField";
import ValidationsErrors from "../../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../../services/forms/SubmitButton";
import { MaterialFormSchema } from "../../../../services/material/material";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import Button from "../../../../services/ui/elements/Button";
import { useConfirmationWithIntl } from "../../../../services/ui/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { useNonNullableReference } from "../../../../services/ui/utility";
import { useCompany } from "../../../../services/company/apiProvider";
import { MATERIALS_LINK } from "../../../../routes/user";
import PageContent from "../../../../services/ui/elements/PageContent";
import AutoObject from "../../../../services/forms/AutoObject";
import iconTrashWhite from "../../../../assets/img/icons/icon-trash-white.svg";
import Section from "src/services/ui/elements/Section";

const Material = (): JSX.Element => {
  const { toastError, toastSuccess } = useToastsWithIntl(["material"]);
  const {
    material: nullableMaterial,
    loadMaterialById,
    updateMaterial,
    deleteMaterial,
  } = useMaterial();
  const { confirm } = useConfirmationWithIntl(["material"]);
  const navigate = useNavigate();
  const { loadMaterials } = useCompany();

  const { materialId } = useParams<typeof MATERIAL_LINK>();

  const { error, loading, reload } = useLoader(
    () => loadMaterialById(Number(materialId)),
    [loadMaterialById, materialId],
  );

  const material = useNonNullableReference(nullableMaterial);

  if (error) return <LoaderErrors error={error} reload={reload} />;
  if (loading) return <Loading />;

  return (
    <PageContent>
      <ViewName
        actionsBar={
          <Button
            onClick={() =>
              !material.usedInProject
                ? confirm(
                    "material:confirm-delete-material",
                    () =>
                      deleteMaterial().then(
                        () => {
                          toastSuccess("material:delete-material.SUCCESS");
                          loadMaterials();
                          navigate(MATERIALS_LINK);
                        },
                        () => {
                          toastError("material:delete-material.ERROR");
                        },
                      ),
                    true,
                  )
                : toastError("material:delete-material.USED_BY_PROJECT")
            }
            variant={"danger"}
          >
            <img alt={"delete icon"} className={"icon"} src={iconTrashWhite} />
          </Button>
        }
        name={material.name}
      />

      {material.usedInProject && (
        <Section>
          <div className={"info info-m info-secondary"}>
            <p>
              La mise à jour de ce matériau est impossible, car il est déjà
              affecté à un projet
            </p>
          </div>
        </Section>
      )}

      <Form
        initialValues={material}
        onSubmit={(newMaterial) => {
          return updateMaterial(newMaterial).then(
            () => {
              toastSuccess("material:update-material.SUCCESS");
            },
            () => {
              toastError("material:update-material.ERROR");
            },
          );
        }}
        schema={MaterialFormSchema}
        schemaContext={{ usedInProject: material.usedInProject }}
      >
        <AutoObject name={""}>
          <AutoField name={"name"} />
          <AutoField name={"density"} unit={"T/m3"} />

          <ValidationsErrors />

          <ActionBar>
            {!material.usedInProject && (
              <SubmitButton>Enregistrer</SubmitButton>
            )}
          </ActionBar>
        </AutoObject>
      </Form>
    </PageContent>
  );
};

export default withProvideMaterial(Material);
