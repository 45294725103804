import { FullOrder, FullOrderRaw } from "./order";
import { WrapUp, WrapUpRaw } from "./wrap-up";
import { parseISO } from "date-fns";
import { Department } from "../department/department";
import { Truck } from "../truck/truck";
import {
  FullDeliverySlip,
  FullDeliverySlipRaw,
  mapDeliverySlipRawToDeliverySlip,
} from "./deliverySlip";
import { Provider } from "../provider/provider";
import { date, number, object, string } from "yup";

export interface ProjectBase {
  affairId: string;
  address: string | null;
  city: string | null;
  country: string | null;
  postalCode: string | null;
  description: string;
  density: number | null;
  thickness: number | null;
  area: number | null;
  syncStartDate: string | null;
}

export interface ProjectRaw extends ProjectBase {
  id: number;
  date: string;
}

export interface Project extends ProjectBase {
  id: number;
  date: Date;
}

export interface ProjectForm extends ProjectBase {
  date: Date;
}

export interface FullProjectRaw extends ProjectRaw {
  Orders: FullOrderRaw[];
  WrapUps: WrapUpRaw[];
  DeliverySlips: FullDeliverySlipRaw[];
}

export interface FullProject extends Project {
  Orders: FullOrder[];
  WrapUps: WrapUp[];
  DeliverySlips: FullDeliverySlip[];
}

export function getEstimatedTonnage({
  area,
  thickness,
  density,
}: Project): number {
  return area !== null && thickness !== null && density !== null
    ? ((area * thickness) / 100) * density
    : 0;
}

export function mapProjectRawToProject(project: FullProjectRaw): FullProject;
export function mapProjectRawToProject(project: ProjectRaw): Project;
export function mapProjectRawToProject(
  project: FullProjectRaw | ProjectRaw,
): FullProject | Project {
  return {
    ...project,
    date: parseISO(project.date),
    WrapUps:
      "WrapUps" in project
        ? project.WrapUps.map((wrapUp) => ({
            ...wrapUp,
            createdAt: parseISO(wrapUp.createdAt),
          }))
        : undefined,
    DeliverySlips:
      "DeliverySlips" in project
        ? project.DeliverySlips.map((ds) =>
            mapDeliverySlipRawToDeliverySlip(ds),
          )
        : undefined,
    Orders:
      "Orders" in project
        ? project.Orders.map((order) => ({
            ...order,
            createdAt: parseISO(order.createdAt),
          }))
        : undefined,
  };
}

/* Recap */
export interface DepartmentRecap {
  id: Department["id"];
  name: Department["name"];
  orderCount: number;
  orderedTonnage: number | null;
  deliverySlipCount: number;
  deliveredTonnage: number | null;
  toBeDeliveredTonnage: number | null;
}

export interface TruckRecap extends Truck {
  deliverySlipCount: number;
  Provider: Provider;
}

export const ProjectSchema = object({
  affairId: string().label("Numéro d'affaire").nullable().required(),
  address: string().label("Adresse").nullable(),
  city: string().label("Ville").nullable().required(),
  postalCode: string()
    .label("Code postal ou département")
    .nullable()
    .matches(/^(\d{5}|\d{2})$/)
    .required(),
  country: string().label("Pays").nullable().required(),
  description: string().label("Description").nullable().required(),
  date: date().label("Date").nullable().required(),
  density: number()
    .label("Densité")
    .nullable()
    .required()
    .meta({ disabled: true }),
  thickness: number()
    .label("Épaisseur")
    .nullable()
    .required()
    .maxFloatingPoints(),
  area: number().label("Surface à réaliser").nullable().required(),
  MaterialId: number().label("Matériau").nullable().required(),
});
