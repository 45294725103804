import {
  ActionBar,
  ViewName,
} from "../../../../services/design-system/base-layout";
import Form from "../../../../services/forms/Form";
import AutoField from "../../../../services/forms/AutoField";
import ValidationsErrors from "../../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../../services/forms/SubmitButton";
import { TruckForm, TruckFormSchema } from "../../../../services/truck/truck";
import { useCompany } from "../../../../services/company/apiProvider";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import { useNavigate } from "react-router-dom";
import { TRUCKS_LINK } from "../../../../routes/user";
import SelectProviderField from "../../../../services/provider/components/SelectProviderField";
import { withLoader } from "../../../../services/routing/useLoader";
import AutoObject from "../../../../services/forms/AutoObject";
import axios from "axios";

const New = (): JSX.Element => {
  const { createTruck } = useCompany();
  const { toastError, toastSuccess } = useToastsWithIntl(["truck"]);
  const navigate = useNavigate();

  return (
    <div>
      <ViewName name={"Nouveau camion"} />

      <Form
        initialValues={{}}
        onSubmit={(truck: TruckForm) => {
          return createTruck(truck).then(
            () => {
              toastSuccess("truck:create-truck.SUCCESS");
              navigate(TRUCKS_LINK);
            },
            (error) => {
              if (axios.isAxiosError(error) && error.response?.status === 409) {
                toastError(
                  "truck:create-truck.REGISTRATION_NUMBER_ALREADY_USED",
                  undefined,
                  { transporterName: error.response.data.providerName },
                );
              } else {
                toastError("truck:create-truck.ERROR");
              }
            },
          );
        }}
        schema={TruckFormSchema}
      >
        <SelectProviderField name={"ProviderId"} />
        <AutoField name={"type"} placeholder={"Semi, 20T, ..."} />
        <AutoField name={"registrationNumber"} />
        <AutoField name={"phoneNumber"} />
        <AutoObject name={"TruckUserData"}>
          <AutoField name={"TruckUserData.identificationNumber"} />
        </AutoObject>

        <ValidationsErrors />

        <ActionBar>
          <SubmitButton>Enregistrer</SubmitButton>
        </ActionBar>
      </Form>
    </div>
  );
};

const useLoad = () => {
  const { loadProviders } = useCompany();

  return loadProviders;
};

export default withLoader(New, useLoad);
