import React, { FunctionComponent, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { boolean, number, object, ref, SchemaOf, string } from "yup";
import { LOGIN_LINK } from "../../../routes/public";
import Link from "../../routing/components/Link";
import { Fonctions, Gender, UserToRegisterForm } from "../user";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";
import useQueryParams from "../../routing/useQueryParams";
import { CompanySchema } from "../../company/company";
import FormFooter from "../../forms/FormFooter";
import Button from "../../ui/elements/Button";
import Form from "../../forms/Form";
import AutoField from "../../forms/AutoField";
import ExternalLink from "../../ui/elements/ExternalLink";
import Section from "src/services/ui/elements/Section";
import SectionTitle from "src/services/ui/elements/SectionTitle";

interface Props {
  onSubmit: (values: UserToRegisterForm) => Promise<void>;
  onCancel?: () => void;
  type: "user" | "company";
}

const RegisterForm: FunctionComponent<Props> = ({
  onSubmit,
  onCancel,
  type,
}) => {
  /* Hooks */
  const { t } = useTranslation(["auth"]);
  const { code } = useQueryParams() as { code: string };

  /* Models */
  const RegisterSchema: SchemaOf<UserToRegisterForm> = useMemo(() => {
    const BaseSchema = object()
      .shape({
        email: string().label(t("auth:email")).nullable().required().email(),
        gender: number()
          .label("Civilité")
          .nullable()
          .required()
          .oneOfEnum(Gender, "auth", "user.gender"),
        password: string()
          .label(t("auth:password"))
          .password()
          .nullable()
          .required()
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            {
              message: { key: "user.password.matches" },
            },
          ),
        passwordConfirmation: string()
          .label(t("auth:password-confirm"))
          .password()
          .nullable()
          .required()
          .equals([ref("password")], { key: "user.password.equals" }),
        lastname: string().label("Nom").nullable().required(),
        firstname: string().label("Prénom").nullable().required(),
        cgu: boolean()
          .label("Conditions générales d'utilisation")
          .accept(
            <span>
              J'ai lu et j'accepte les{" "}
              <ExternalLink
                href={process.env.PUBLIC_URL + "/CGU.pdf"}
                target={"_blank"}
              >
                conditions générales de l'application
              </ExternalLink>
            </span>,
          ),
      })
      .defined();

    return type === "user"
      ? BaseSchema.concat(
          object({
            CompanyCode: string().label("Code société").nullable().required(),
          }).defined(),
        )
      : BaseSchema.concat(
          object({
            Company: CompanySchema.label("Entreprise").defined(),
            phoneNumber: string()
              .label("Numéro de téléphone")
              .default("")
              .test(
                "isPhoneNumber",
                () => ({ key: "user.phoneNumber.matches" }),
                (value) => /^(0|[+]33|0033)[1-9]([0-9]{8})$/g.test(value),
              )
              .required(),
            fonction: number()
              .label("Fonction")
              .nullable()
              .required()
              .oneOfEnum(Fonctions, "auth", "user.fonction"),
          }),
        );
  }, [t, type]);

  return (
    <Form
      initialValues={
        type === "company"
          ? {
              Company: {
                name: "",
                siret: "",
                address: "",
                postalCode: "",
                city: "",
                country: "",
              },
            }
          : {
              CompanyCode: code ? code : "",
            }
      }
      onSubmit={(values: UserToRegisterForm) => onSubmit(values)}
      schema={RegisterSchema}
    >
      <div className={"grid"}>
        {type === "company" && (
          <div className={"col-m-1-2"}>
            <SectionTitle title={"Votre société"}></SectionTitle>
            <AutoField name={"Company.name"} />

            <AutoField name={"Company.group"} />

            <AutoField name={"Company.siret"} />

            <AutoField name={"Company.address"} />

            <AutoField name={"Company.postalCode"} />

            <AutoField name={"Company.city"} />

            <AutoField name={"Company.country"} />

            <AutoField name={"Company.landlineNumber"} />
          </div>
        )}
        <div className={"col"}>
          {type === "company" && (
            <SectionTitle title={"Vos coordonnées"}></SectionTitle>
          )}
          <AutoField name={"email"} placeholder={"e.g. john.doe@mail.com"} />

          <div className={"form-block"}>
            <AutoField name={"gender"} placeholder={"Civilité"} />

            <AutoField name={"lastname"} placeholder={"Doe"} />

            <AutoField name={"firstname"} placeholder={"John"} />

            {type === "user" && <AutoField name={"CompanyCode"} />}
          </div>
          {type === "user" && (
            <div className={"invite-admin-cta"}>
              <ExternalLink
                href={`mailto:?subject=${encodeURIComponent(
                  "Invitation à créer un compte RegulAppro",
                )}&body=${encodeURIComponent(
                  `Bonjour,\nJe pense que l'application REGULAPPRO pourrait nous être utile, mais pour pouvoir la tester, il est préalablement nécessaire de créer un compte administrateur. \n Je vous invite donc à créer ce compte administrateur via le lien suivant : ${window.location.origin}.\nLes informations concernant l'application REGULAPPRO sont disponibles sur le site internet : www.regulappro.fr.`,
                )}`}
              >
                J'invite mon responsable à créer un compte administrateur pour
                obtenir mon code société
              </ExternalLink>
            </div>
          )}

          <div className={"form-block"}>
            <AutoField name={"password"} placeholder={"**********"}>
              <p className={"input-tip"}>
                8 caractères min. dont au moins une lettre, un chiffre et un
                caractère spécial.
              </p>
            </AutoField>
          </div>

          <div className={"form-block"}>
            <AutoField
              name={"passwordConfirmation"}
              placeholder={"**********"}
            />
          </div>

          {type === "company" && (
            <div className={"form-block"}>
              <AutoField name={"phoneNumber"} />

              <AutoField name={"fonction"} placeholder={"Choisir"} />
            </div>
          )}
        </div>
      </div>

      <ValidationsErrors />

      <FormFooter>
        <Section size={"s"}>
          <AutoField name={"cgu"} />
        </Section>
        {onCancel && <Button onClick={onCancel}>Annuler</Button>}
        <SubmitButton block>S'inscrire</SubmitButton>
        {!onCancel && (
          <div className={"go-back-to-login-cta"}>
            <Link to={LOGIN_LINK}>Connexion</Link>
          </div>
        )}
      </FormFooter>
    </Form>
  );
};

export default RegisterForm;
