import React, {
  ComponentType,
  createContext,
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { cx } from "@emotion/css";
import NavLink, { Props as NavLinkProps } from "../routing/components/NavLink";
import { replaceInArray } from "../data-structures/array";
import { UNSAFE_RouteContext } from "react-router-dom";
import { createPortal } from "react-dom";
import iconChevronRightGrey from "../../assets/img/icons/icon-chevron-right-grey.svg";
import iconChevronLeftGrey from "../../assets/img/icons/icon-chevron-left-grey.svg";

export const Container = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "container")} />;
};

export const AppLayout = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "app-layout")} />;
};

export const SiteLayout = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "site-layout")} />;
};

export const Header = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "header")} />;
};

export const LayoutContent = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "layout-content")} />;
};

export const Sidebar = ({
  className,
  expanded,
  children,
}: {
  className?: string;
  expanded?: boolean;
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div className={cx(className, "sidebar", expanded && "expanded")}>
      {children}
    </div>
  );
};

export const Nav = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "nav")} />;
};

export const ContentView = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "content-view")} />;
};

export interface BreadcrumbsAPI {
  push(name: string, link?: string): void;
  pop(): void;
  thread: { name: string; link?: string }[];
}

export const BreadcrumbsContext = createContext<BreadcrumbsAPI | null>(null);

export const ProvideBreadcrumbs = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [thread, setThread] = useState<BreadcrumbsAPI["thread"]>([]);

  const push = useCallback((name, link) => {
    setThread((thread) => thread.concat([{ name, link }]));
  }, []);

  const pop = useCallback(() => {
    setThread((thread) => thread.slice(0, thread.length - 1));
  }, []);

  return (
    <BreadcrumbsContext.Provider value={{ thread, push, pop }}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export function withProvideBreadcrumbs<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideBreadcrumbs(props: P) {
    return (
      <ProvideBreadcrumbs>
        <WrappedComponent {...props} />
      </ProvideBreadcrumbs>
    );
  }

  WithProvideBreadcrumbs.displayName = `withProvideBreadcrumbs(${displayName})`;

  return WithProvideBreadcrumbs;
}

export function useBreadcrumbsAPI(): BreadcrumbsAPI {
  return useContext(BreadcrumbsContext) as BreadcrumbsAPI;
}

/**
 * This function is not accessible, please use component
 *
 * This function needs to be called inside a component to be executed in the right order
 * @param name
 * @param link
 */
function useBreadcrumbs(name: string, link?: string): void {
  const { push, pop } = useBreadcrumbsAPI();

  useEffect(() => {
    push(name, link);

    return pop;
  }, [push, pop, name, link]);
}

export interface ActionsBarAPI {
  domElement: MutableRefObject<Element | null>;
}

export const ActionsBarContext = createContext<ActionsBarAPI | null>(null);

export function useActionsBarAPI(): ActionsBarAPI {
  return useContext(ActionsBarContext) as ActionsBarAPI;
}

export const ProvideActionsBar = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const domElement = useRef<Element | null>(null);

  return (
    <ActionsBarContext.Provider value={{ domElement }}>
      {children}
    </ActionsBarContext.Provider>
  );
};

export function withProvideActionsBar<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideActionsBar(props: P) {
    return (
      <ProvideActionsBar>
        <WrappedComponent {...props} />
      </ProvideActionsBar>
    );
  }

  WithProvideActionsBar.displayName = `withProvideActionsBar(${displayName})`;

  return WithProvideActionsBar;
}

export const ViewName = ({
  name,
  actionsBar,
}: {
  name: string;
  actionsBar?: ReactNode;
}): JSX.Element | null => {
  const { matches } = useContext(UNSAFE_RouteContext);
  useBreadcrumbs(name, matches[matches.length - 1].pathnameBase);

  const { domElement } = useActionsBarAPI();
  if (actionsBar && domElement.current) {
    return createPortal(actionsBar, domElement.current);
  }

  return null;
};

export const Head = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  const { thread } = useBreadcrumbsAPI();
  const { tabs } = useTabsAPI();
  const { domElement } = useActionsBarAPI();
  const backLink = thread[thread.length - 2]?.link; // link to previous route in breadcrumb navigation tree

  return (
    <div {...props} className={cx(className, "head", "container")}>
      <div className={"row row-v-center"}>
        {backLink && (
          <NavLink className={"back-button"} to={backLink}>
            <img alt={"retour"} src={iconChevronLeftGrey} />
          </NavLink>
        )}

        <div className={"col"}>
          <div className={"breadcrumb"}>
            {thread
              .slice(0, -1)
              .map(({ name, link }) =>
                link ? (
                  <NavLink to={link} end>
                    {name}
                  </NavLink>
                ) : (
                  <span>{name}</span>
                ),
              )
              .reduce(
                (acc, element) =>
                  acc === null ? (
                    element
                  ) : (
                    <>
                      {acc}{" "}
                      <img
                        alt={"breadcrumb-separator"}
                        className={"breadcrumb-chevron"}
                        src={iconChevronRightGrey}
                      />{" "}
                      {element}
                    </>
                  ),
                null,
              )}
          </div>
          <div className={"view-title"}>{thread[thread.length - 1]?.name}</div>
        </div>
        <div
          ref={(element) => (domElement.current = element)}
          className={"col-fit view-head-actions"}
        />
      </div>
      {tabs.length > 0 && <TabsDisplay tabs={tabs[tabs.length - 1]} />}
      {children}
    </div>
  );
};

export interface TabsAPI {
  pushList<U extends string, T extends Tab<U>>(
    tabs: T[],
    replace?: boolean,
  ): void;
  popList(): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tabs: Tab<any>[][];
}

export const TabsContext = createContext<TabsAPI | null>(null);

export function useTabsAPI(): TabsAPI {
  return useContext(TabsContext) as TabsAPI;
}

/**
 * @deprecated use Tabs component instead
 * @param tabs tabs list
 */
export function useTabs<U extends string>(tabs: Tab<U>[]): void {
  const { pushList, popList } = useTabsAPI();

  useEffect(() => {
    pushList<U, Tab<U>>(tabs);

    return popList;
  }, [pushList, popList, tabs]);
}

export const ProvideTabs = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [tabs, setTabs] = useState<TabsAPI["tabs"]>([]);

  const pushList: TabsAPI["pushList"] = useCallback((tabs, replace = true) => {
    setTabs((tabsList) =>
      replace
        ? tabsList.concat([tabs])
        : replaceInArray(
            tabsList,
            tabsList.length - 1,
            tabsList[tabsList.length - 1].concat(tabs),
          ),
    );
  }, []);

  const popList: TabsAPI["popList"] = useCallback(() => {
    setTabs((tabsList) => tabsList.slice(0, tabsList.length - 1));
  }, []);

  return (
    <TabsContext.Provider value={{ pushList, popList, tabs }}>
      {children}
    </TabsContext.Provider>
  );
};

export function withProvideTabs<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideTabs(props: P) {
    return (
      <ProvideTabs>
        <WrappedComponent {...props} />
      </ProvideTabs>
    );
  }

  WithProvideTabs.displayName = `withProvideTabs(${displayName})`;

  return WithProvideTabs;
}

export interface Tab<U extends string> {
  name: string;
  linkProps?: Omit<NavLinkProps<U>, "children">;
}

export const TabsDisplay = <U extends string, T extends Tab<U>[]>({
  className,
  tabs,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & { tabs: T }): JSX.Element => {
  return (
    <div {...props} className={cx(className, "tabs")}>
      {tabs.map((tab) =>
        tab.linkProps ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <NavLink key={tab.name} className={"tab-item"} {...tab.linkProps}>
            {tab.name}
          </NavLink>
        ) : (
          <div key={tab.name} className={"tab-item"}>
            {tab.name}
          </div>
        ),
      )}
    </div>
  );
};

export function Tabs<U extends string>({
  tabs,
}: {
  tabs: Tab<U>[];
}): JSX.Element | null {
  // eslint-disable-next-line deprecation/deprecation
  useTabs(tabs);

  return null;
}

export const Content = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "content")} />;
};

export const ActionBar = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>): JSX.Element => {
  return <div {...props} className={cx(className, "action-bar")} />;
};

export function withProvideHead<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideHead(props: P) {
    return (
      <ProvideActionsBar>
        <ProvideTabs>
          <ProvideBreadcrumbs>
            <WrappedComponent {...props} />
          </ProvideBreadcrumbs>
        </ProvideTabs>
      </ProvideActionsBar>
    );
  }

  WithProvideHead.displayName = `withProvideHead(${displayName})`;

  return WithProvideHead;
}
