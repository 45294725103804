import {
  ComponentType,
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  createReview as apiCreateReview,
  getReviews,
  GetReviewsResponse,
} from "./api";
import { Review, ReviewForm } from "./review";

export interface ReviewAPI {
  reviews: Map<Review["id"], GetReviewsResponse>;

  loadReviews(): Promise<void>;

  createReview(review: ReviewForm): Promise<void>;
}

export const ReviewContext = createContext<ReviewAPI | null>(null);

export function useReview(): ReviewAPI {
  return useContext(ReviewContext) as ReviewAPI;
}

export const ProvideReview = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const [reviews, setReviews] = useState<ReviewAPI["reviews"]>(new Map());

  const loadReviews: ReviewAPI["loadReviews"] = useCallback(
    () =>
      getReviews().then(({ data }) =>
        setReviews(new Map(data.map((review) => [review.id, review]))),
      ),
    [],
  );

  const createReview: ReviewAPI["createReview"] = useCallback((review) => {
    return apiCreateReview(review).then(() => Promise.resolve());
  }, []);

  return (
    <ReviewContext.Provider value={{ reviews, loadReviews, createReview }}>
      {children}
    </ReviewContext.Provider>
  );
};

export function withProvideReview<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideReview(props: P) {
    return (
      <ProvideReview>
        <WrappedComponent {...props} />
      </ProvideReview>
    );
  }

  WithProvideReview.displayName = `withProvideReview(${displayName})`;

  return WithProvideReview;
}
