import { CompanyForm } from "../../../../services/company/CompanyForm";
import React from "react";

const Index = (): JSX.Element => {
  return (
    <div>
      <CompanyForm />
    </div>
  );
};

export default Index;
