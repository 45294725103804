import {
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../../services/design-system/base-layout";
import Routes from "../../../../services/routing/Routes";
import { Route, Outlet } from "react-router-dom";
import New from "./New";
import {
  DEPARTMENT,
  DEPARTMENT_LINK,
  NEW_DEPARTMENT,
  NEW_DEPARTMENT_LINK,
} from "../../../../routes/user/departments";
import Link from "../../../../services/routing/components/Link";
import Department from "./Department";
import {
  CompanyAPILoaded,
  useCompany,
} from "../../../../services/company/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import PageContent from "../../../../services/ui/elements/PageContent";
import Section from "../../../../services/ui/elements/Section";
import List from "../../../../services/ui/elements/List";
import { useSearch } from "../../../../services/data-structures/array";
import InputSearch from "../../../../services/ui/elements/InputSearch";
import CreateLink from "../../../../services/ui/elements/CreateLink";

const useLoad = () => {
  const { loadDepartments } = useCompany();

  return loadDepartments;
};

const Index = withLoader(() => {
  const { departments } = useCompany() as CompanyAPILoaded;

  const [searchedDepartments, setSearch] = useSearch([...departments.values()]);

  return (
    <div>
      <Section size={"xs"}>
        <CreateLink to={NEW_DEPARTMENT_LINK}>Nouvelle centrale</CreateLink>
      </Section>

      <Section size={"xs"}>
        <InputSearch onChange={(event) => setSearch(event.target.value)} />
      </Section>

      <Section>
        <List>
          {searchedDepartments.map((department) => (
            <Link
              key={department.id}
              className={"list-item"}
              params={{ departmentId: department.id }}
              to={DEPARTMENT_LINK}
            >
              <div className={"item-title"}>{department.name}</div>
              <div className={"item-meta-primary"}>
                {department.postalCode} {department.city}
              </div>

              {department.storageCapacity && (
                <div className={"item-meta-secondary"}>
                  Capacité de stockage : {department.storageCapacity}T
                </div>
              )}
              {department.productionCapacity && (
                <div className={"item-meta-secondary"}>
                  Capacité de fabrication : {department.productionCapacity}T/h
                </div>
              )}
              {department.landlineNumber && (
                <div className={"item-meta-secondary"}>
                  Tel : {department.landlineNumber}
                </div>
              )}
              {department.email && (
                <div className={"item-meta-secondary"}>
                  Email : {department.email}
                </div>
              )}
            </Link>
          ))}
        </List>
      </Section>
    </div>
  );
}, useLoad);

const Departments = (): JSX.Element => {
  return (
    <>
      <ViewName name={"Centrales"} />
      <Head />

      <Content>
        <Routes>
          <Route
            element={
              <PageContent>
                <Outlet />
              </PageContent>
            }
          >
            <Route element={<Index />} index />
            <Route element={<New />} path={NEW_DEPARTMENT} />
            <Route element={<Department />} path={DEPARTMENT} />
          </Route>
        </Routes>
      </Content>
    </>
  );
};

export default withProvideHead(Departments);
