import AutoField from "../../forms/AutoField";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";
import Form from "../../forms/Form";
import { ActionBar } from "../../design-system/base-layout";
import { TruckForm, TruckFormSchema } from "../truck";
import React from "react";
import AutoObject from "src/services/forms/AutoObject";
import { Provider } from "src/services/provider/provider";

interface Props {
  onSubmit: (values: TruckForm) => Promise<void>;
  providerId?: Provider["id"];
}

const NewTruckForm = ({ onSubmit, providerId }: Props): JSX.Element => {
  return (
    <Form
      initialValues={{ ProviderId: providerId }}
      onSubmit={(truck: TruckForm) => onSubmit(truck)}
      schema={TruckFormSchema}
    >
      <AutoField name={"type"} placeholder={"Semi, 20T, ..."} />
      <AutoField name={"registrationNumber"} />
      <AutoField name={"phoneNumber"} />
      <AutoObject name={"TruckUserData"}>
        <AutoField name={"TruckUserData.identificationNumber"} />
      </AutoObject>

      <ValidationsErrors />

      <ActionBar>
        <SubmitButton>Enregistrer</SubmitButton>
      </ActionBar>
    </Form>
  );
};

export default NewTruckForm;
