import { useYupField } from "../../forms/Form";
import { AnySchema } from "yup";
import { useMemo, useState } from "react";
import { CompanyAPILoaded, useCompany } from "../../company/apiProvider";
import SxSelect from "../../forms/SxSelect";
import { useField } from "formik";
import Dialog, {
  DialogCloseAPI,
  useDialogClose,
} from "../../ui/elements/Dialog";
import { useSearch } from "../../data-structures/array";
import InputSearch from "../../ui/elements/InputSearch";
import Button from "../../ui/elements/Button";
import CardHead from "../../ui/elements/CardHead";
import CardBody from "../../ui/elements/CardBody";
import { ProviderForm } from "../provider";
import NewProviderForm from "./NewProviderForm";
import { useToastsWithIntl } from "../../toast-notifications";
import useLoader from "../../routing/useLoader";
import Section from "../../ui/elements/Section";
import List from "../../ui/elements/List";
import Loading from "../../routing/components/Loading";
import LoaderErrors from "../../routing/components/LoaderErrors";

const SelectProviderModal = ({ name }: { name: string }): JSX.Element => {
  const [openedCreateModal, setOpenedCreateModal] = useState(false);
  const [, , helpers] = useField(name);
  const onClose = useDialogClose() as NonNullable<DialogCloseAPI>;

  const { providers, createProvider } = useCompany() as CompanyAPILoaded;

  const [searchedProviders, setSearch] = useSearch([...providers.values()]);

  const { toastError, toastSuccess } = useToastsWithIntl(["provider"]);

  return (
    <Dialog className={"theme-light"} panel>
      <CardHead title={"Sélectionner un transporteur"} />

      <CardBody>
        <InputSearch
          onChange={(event) => setSearch(event.currentTarget.value)}
        />

        <Section size={"s"}>
          <Button
            onClick={() => setOpenedCreateModal(true)}
            type={"button"}
            link
          >
            + Nouveau transporteur
          </Button>
        </Section>

        <List>
          {searchedProviders.map((provider) => (
            <Button
              key={provider.id}
              className={"list-item"}
              onClick={() => {
                helpers.setValue(provider.id);
                onClose();
              }}
              variant={2}
              block
              link
            >
              {provider.name}
            </Button>
          ))}
        </List>
      </CardBody>

      {openedCreateModal && (
        <Dialog onClose={() => setOpenedCreateModal(false)}>
          <CardHead title={"Nouveau transporteur"} />

          <CardBody>
            <NewProviderForm
              onSubmit={(provider: ProviderForm) => {
                return createProvider(provider).then(
                  (newProvider) => {
                    toastSuccess("provider:create-provider.SUCCESS");
                    helpers.setValue(newProvider.id);
                    onClose();
                  },
                  () => {
                    toastError("provider:create-provider.ERROR");
                  },
                );
              }}
            />
          </CardBody>
        </Dialog>
      )}
    </Dialog>
  );
};

const useLoad = () => {
  const { loadProviders } = useCompany();

  return loadProviders;
};

const SelectProviderField = ({ name }: { name: string }): JSX.Element => {
  const { providers } = useCompany() as CompanyAPILoaded;
  const fieldSchema = useYupField(name) as AnySchema;

  const isRequired = useMemo(
    () => fieldSchema.tests.find((t) => t.OPTIONS.name === "required"),
    [fieldSchema],
  );

  const isDisabled = useMemo(() => fieldSchema.meta()?.disabled, [fieldSchema]);

  const { error, reload, loading } = useLoader(useLoad());

  return (
    <div className={"form-block"}>
      <label className={"input-label"} htmlFor={name}>
        {fieldSchema.spec.label} {isRequired && "*"}
      </label>

      {loading ? (
        <Loading />
      ) : error ? (
        <LoaderErrors error={error} reload={reload} />
      ) : (
        <SxSelect<number>
          disabled={isDisabled}
          getElementName={(providerId) => providers.get(providerId)?.name || ""}
          id={name}
          name={name}
          selectModal={<SelectProviderModal name={name} />}
        />
      )}
    </div>
  );
};

export default SelectProviderField;
