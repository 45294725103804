import {
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../../services/design-system/base-layout";
import Routes from "../../../../services/routing/Routes";
import { Route, Outlet } from "react-router-dom";
import Link from "../../../../services/routing/components/Link";
import Project from "./Project";
import {
  NEW,
  NEW_LINK,
  PROJECT,
  PROJECT_LINK,
} from "../../../../routes/user/projects";
import {
  CompanyAPILoaded,
  useCompany,
} from "../../../../services/company/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import PageContent from "../../../../services/ui/elements/PageContent";
import ProjectForm from "../../../../services/project/components/ProjectForm";
import Section from "../../../../services/ui/elements/Section";
import List from "../../../../services/ui/elements/List";
import { getEstimatedTonnage } from "../../../../services/project/project";
import {
  useSearch,
  useSearchStart,
} from "../../../../services/data-structures/array";
import InputSearch from "../../../../services/ui/elements/InputSearch";
import CreateLink from "../../../../services/ui/elements/CreateLink";
import { useMemo } from "react";
import compareDesc from "date-fns/compareDesc";
import { useDate } from "src/services/date/DateContext";

const useLoad = () => {
  const { loadProjects } = useCompany();

  return loadProjects;
};

const Index = withLoader(() => {
  const { projects } = useCompany() as CompanyAPILoaded;
  const { formatDate } = useDate();

  const projectsList = useMemo(() => [...projects.values()], [projects]);
  const [searchedProjects, setSearch] = useSearch(projectsList);
  const [searchedProjectsByPostalCode, setSearchPostalCode] = useSearchStart(
    searchedProjects,
    (p) => p.postalCode,
  );

  const postalCodeDataList = useMemo(
    () => (
      <datalist id={"postal-code-projects"}>
        {[...new Set(projectsList.map((p) => p.postalCode)).values()]
          .filter((postalCode) => postalCode !== null)
          .map((postalCode) => (
            <option key={postalCode}>{postalCode}</option>
          ))}
      </datalist>
    ),
    [projectsList],
  );

  const searchedProjectsByPostalCodeOrdered = useMemo(
    () =>
      searchedProjectsByPostalCode.sort((a, b) => compareDesc(a.date, b.date)),
    [searchedProjectsByPostalCode],
  );

  return (
    <>
      <Section size={"xs"}>
        <CreateLink to={NEW_LINK}>Nouveau projet</CreateLink>
      </Section>

      <Section size={"xs"}>
        <div className={"grid"}>
          <div className={"col-m-2-3"}>
            <InputSearch
              onChange={(event) => setSearch(event.target.value)}
              placeholder={"Recherche libre"}
            />
          </div>
          <div className={"col-m-1-3"}>
            {postalCodeDataList}
            <InputSearch
              list={"postal-code-projects"}
              onChange={(event) => setSearchPostalCode(event.target.value)}
              placeholder={"Recherche par département"}
            />
          </div>
        </div>
      </Section>

      <Section>
        <h4>Liste des projets</h4>
        <List>
          {searchedProjectsByPostalCodeOrdered.map((project) => (
            <Link
              key={project.id}
              className={"list-item"}
              params={{ projectId: project.id }}
              to={PROJECT_LINK}
            >
              <div className={"item-title"}>{project.affairId}</div>
              <div className={"item-meta-primary"}>{project.description}</div>
              <div className={"item-meta-secondary"}>
                {project.address} {project.city}
              </div>
              <div className={"item-meta-secondary"}>
                {formatDate(project.date, "P")}
              </div>
              <div className={"item-meta-primary"}>
                Tonnage prév. : {getEstimatedTonnage(project).toFixed(2)} T
              </div>
            </Link>
          ))}
        </List>
      </Section>
    </>
  );
}, useLoad);

const Projects = (): JSX.Element => {
  return (
    <>
      <ViewName name={"Projets"} />
      <Head />

      <Content>
        <Routes>
          <Route
            element={
              <PageContent>
                <Outlet />
              </PageContent>
            }
          >
            <Route element={<Index />} index />
            <Route element={<ProjectForm />} path={NEW} />
            <Route element={<Project />} path={`${PROJECT}/*`} />
          </Route>
        </Routes>
      </Content>
    </>
  );
};

export default withProvideHead(Projects);
