import {
  Content,
  Tab,
  Tabs,
  ViewName,
} from "../../../../services/design-system/base-layout";
import { useParams } from "../../../../services/routing/useParams";
import { useCallback, useMemo } from "react";
import Routes from "../../../../services/routing/Routes";
import { Route } from "react-router-dom";
import Infos from "./Infos";
import Departments from "./departments/Departments";
import {
  DELIVERY_SLIPS,
  DELIVERY_SLIPS_LINK,
  DEPARTMENTS,
  DEPARTMENTS_LINK,
  POS,
  POS_LINK,
  PROJECT,
  PROJECT_LINK,
  RECAP,
  RECAP_LINK,
  WRAP_UP,
  WRAP_UP_LINK,
} from "../../../../routes/user/projects";
import {
  ProjectAPILoaded,
  useProject,
  withProvideProject,
} from "../../../../services/project/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import WrapUp from "./WrapUp";
import Recap from "./Recap";
import DeliverySlips from "./deliverySlips/DeliverySlips";
import PurchaseOrders from "./pos/PurchaseOrders";
import { AuthAPIConnected, useAuth } from "src/services/auth/apiProvider";

const Project = (): JSX.Element => {
  const { projectId } = useParams<typeof PROJECT>();
  const { project } = useProject() as ProjectAPILoaded;
  const { user } = useAuth() as AuthAPIConnected;

  const tabs = useMemo<
    Tab<
      | typeof PROJECT_LINK
      | typeof DEPARTMENTS_LINK
      | typeof POS_LINK
      | typeof WRAP_UP_LINK
      | typeof RECAP_LINK
      | typeof DELIVERY_SLIPS_LINK
    >[]
  >(
    () =>
      user.AdministratedCompanyId === null
        ? [
            {
              name: "infos",
              linkProps: { to: PROJECT_LINK, params: { projectId }, end: true },
            },
            {
              name: "bons de commande",
              linkProps: { to: POS_LINK, params: { projectId } },
            },
            {
              name: "centrales",
              linkProps: { to: DEPARTMENTS_LINK, params: { projectId } },
            },
            {
              name: "livraisons",
              linkProps: { to: DELIVERY_SLIPS_LINK, params: { projectId } },
            },
            {
              name: "bouclage",
              linkProps: { to: WRAP_UP_LINK, params: { projectId } },
            },
            {
              name: "récap",
              linkProps: { to: RECAP_LINK, params: { projectId } },
            },
          ]
        : [
            {
              name: "infos",
              linkProps: { to: PROJECT_LINK, params: { projectId }, end: true },
            },
            {
              name: "bons de commande",
              linkProps: { to: POS_LINK, params: { projectId } },
            },
            {
              name: "centrales",
              linkProps: { to: DEPARTMENTS_LINK, params: { projectId } },
            },
            {
              name: "livraisons",
              linkProps: { to: DELIVERY_SLIPS_LINK, params: { projectId } },
            },
            {
              name: "récap",
              linkProps: { to: RECAP_LINK, params: { projectId } },
            },
          ],
    [projectId, user.AdministratedCompanyId],
  );

  return (
    <>
      <ViewName name={`Projet ${project.affairId}`} />
      <Tabs tabs={tabs} />

      <Content>
        <Routes>
          <Route element={<Infos />} index />
          <Route element={<PurchaseOrders />} path={`${POS}/*`} />
          <Route element={<Departments />} path={`${DEPARTMENTS}/*`} />
          <Route element={<WrapUp />} path={WRAP_UP} />
          <Route element={<Recap />} path={`${RECAP}/*`} />
          <Route element={<DeliverySlips />} path={`${DELIVERY_SLIPS}/*`} />
        </Routes>
      </Content>
    </>
  );
};

function useLoad() {
  const { loadProjectById } = useProject();
  const { projectId } = useParams<typeof PROJECT>();

  return useCallback(() => loadProjectById(Number(projectId)), [
    projectId,
    loadProjectById,
  ]);
}

export default withProvideProject(withLoader(Project, useLoad));
