import { CompanyAPILoaded, useCompany } from "./apiProvider";
import { useToastsWithIntl } from "../toast-notifications";
import Form from "../forms/Form";
import { CompanySchema } from "./company";
import AutoField from "../forms/AutoField";
import { Link } from "react-router-dom";
import { PROFILE_LINK } from "../../routes/private";
import AutoList from "../forms/AutoList";
import { User } from "../auth/user";
import AutoObject from "../forms/AutoObject";
import ValidationsErrors from "../forms/ValidationsErrors";
import SubmitButton from "../forms/SubmitButton";
import React from "react";
import { ActionBar } from "../design-system/base-layout";
import Section from "../../services/ui/elements/Section";
import SectionTitle from "../../services/ui/elements/SectionTitle";

export const CompanyForm = (): JSX.Element => {
  const {
    company,
    administrators,
    updateCompany,
  } = useCompany() as CompanyAPILoaded;
  const { toastSuccess, toastError } = useToastsWithIntl(["company"]);

  return (
    <Form
      initialValues={{
        ...company,
        Administrators: [...administrators.values()],
      }}
      onSubmit={(values) =>
        updateCompany(values).then(
          () => toastSuccess("company:update-company.SUCCESS"),
          () => toastError("company:update-company.ERROR"),
        )
      }
      schema={CompanySchema}
      schemaContext={{ code: company.code }}
    >
      <AutoField name={"name"} />
      <AutoField name={"group"} />
      <AutoField name={"siret"} />
      <AutoField name={"address"} />
      <AutoField name={"postalCode"} />
      <AutoField name={"city"} />
      <AutoField name={"landlineNumber"} />
      <AutoField name={"code"} />

      <Section size={"s"}>
        <SectionTitle title={"Responsable compte client"} />
        <Link to={PROFILE_LINK}>Modifier</Link>

        <Section size={"xs"}>
          <AutoList<User> name={"Administrators"}>
            {(administrators) => (
              <div>
                {administrators.map((value, index) => (
                  <AutoObject key={value.id} name={`Administrators.${index}`}>
                    <AutoField name={`Administrators.${index}.email`} />
                    <AutoField name={`Administrators.${index}.gender`} />
                    <AutoField name={`Administrators.${index}.firstname`} />
                    <AutoField name={`Administrators.${index}.lastname`} />
                    <AutoField name={`Administrators.${index}.phoneNumber`} />
                    <AutoField name={`Administrators.${index}.fonction`} />
                  </AutoObject>
                ))}
              </div>
            )}
          </AutoList>
        </Section>
      </Section>

      <ValidationsErrors />

      <ActionBar>
        <SubmitButton>Enregistrer</SubmitButton>
      </ActionBar>
    </Form>
  );
};
