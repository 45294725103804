import {
  ProjectAPILoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import {
  Content,
  ViewName,
} from "../../../../../services/design-system/base-layout";
import Routes from "../../../../../services/routing/Routes";
import { Route, Outlet } from "react-router-dom";
import { useDate } from "../../../../../services/date/DateContext";
import Link from "../../../../../services/routing/components/Link";
import {
  DELIVERY_SLIP,
  DELIVERY_SLIP_LINK,
  NEW,
  NEW_LINK,
} from "../../../../../routes/user/delivery-slips";
import New from "./New";
import DeliverySlip from "./DeliverySlip";
import CreateLink from "../../../../../services/ui/elements/CreateLink";
import Section from "../../../../../services/ui/elements/Section";
import List from "../../../../../services/ui/elements/List";
import { useSearch } from "../../../../../services/data-structures/array";
import InputSearch from "../../../../../services/ui/elements/InputSearch";
import { useToastsWithIntl } from "../../../../../services/toast-notifications";
import Button from "../../../../../services/ui/elements/Button";
import { useMemo, useState } from "react";
import { Department } from "src/services/department/department";

enum ValidationFilter {
  ALL,
  VALIDATED,
  NOT_VALIDATED,
}

const Index = (): JSX.Element => {
  const { toastSuccess, toastError } = useToastsWithIntl(["project"]);
  const {
    project,
    validateDeliverySlip,
    invalidateDeliverySlip,
    syncDeliverySlips,
    unsyncDeliverySlips,
  } = useProject() as ProjectAPILoaded;
  const { formatDate } = useDate();

  const [searchDeliverySlips, setSearch] = useSearch(project.DeliverySlips);
  const [validationFilter, setValidationFilter] = useState(
    ValidationFilter.ALL,
  );
  const [departmentFilter, setDepartmentFilter] = useState<number>(0);
  const [materialFilter, setMaterialFilter] = useState<string>("");

  const departmentList = useMemo(() => {
    const distinctDatas: Department[] = [];
    searchDeliverySlips.forEach((deliverySlip) => {
      if (!distinctDatas.find((data) => data.id === deliverySlip.Department.id))
        distinctDatas.push(deliverySlip.Department);
    });
    return distinctDatas;
  }, [searchDeliverySlips]);

  const materialList = useMemo(() => {
    const distinctDatas: string[] = [];
    searchDeliverySlips.forEach((deliverySlip) => {
      if (
        deliverySlip.material &&
        !distinctDatas.find((data) => data === deliverySlip.material)
      )
        distinctDatas.push(deliverySlip.material);
    });
    return distinctDatas;
  }, [searchDeliverySlips]);

  const filteredDeliverySlips = useMemo(() => {
    let deliverySlips = searchDeliverySlips;

    if (validationFilter === ValidationFilter.VALIDATED)
      deliverySlips = deliverySlips.filter(
        (deliverySlip) => deliverySlip.validationDate !== null,
      );

    if (validationFilter === ValidationFilter.NOT_VALIDATED)
      deliverySlips = deliverySlips.filter(
        (deliverySlip) => deliverySlip.validationDate === null,
      );

    if (departmentFilter) {
      deliverySlips = deliverySlips.filter(
        (deliverySlip) => deliverySlip.Department.id === departmentFilter,
      );
    }

    if (materialFilter) {
      deliverySlips = deliverySlips.filter(
        (deliverySlip) => deliverySlip.material === materialFilter,
      );
    }

    return deliverySlips;
  }, [departmentFilter, materialFilter, searchDeliverySlips, validationFilter]);

  return (
    <div>
      <Section size={"xs"}>
        <div className={"grid"}>
          <div className={"col-m-1-2"}>
            <CreateLink params={{ projectId: project.id }} to={NEW_LINK}>
              Nouvelle livraison
            </CreateLink>
            <p className={"input-label"}>pour créer un bon de livraison</p>
          </div>
          <div className={"col-m-1-2"}>
            <Button
              onClick={() => {
                if (project.syncStartDate) {
                  unsyncDeliverySlips(project.id).then(
                    () => toastSuccess("project:unsync-delivery-slips.SUCCESS"),
                    () => toastError("project:unsync-delivery-slips.ERROR"),
                  );
                } else {
                  syncDeliverySlips(project.id).then(
                    () => {
                      // check what msg will be used
                      if (!project.Orders.length) {
                        return toastSuccess(
                          "project:sync-delivery-slips.SUCESS_NO_ORDER",
                        );
                      } else {
                        return toastSuccess(
                          "project:sync-delivery-slips.SUCCESS",
                        );
                      }
                    },
                    () => toastError("project:sync-delivery-slips.ERROR"),
                  );
                }
              }}
              variant={2}
            >
              {project.syncStartDate
                ? "Arrêter l'import des bons"
                : "Import des bons"}
            </Button>
            <p className={"input-label"}>
              Générés automatiquement par les centrales connectées à REGULAPPRO
            </p>
          </div>
        </div>
      </Section>

      <Section size={"s"}>
        <label className={"input-label"}>Filtrer par</label>
        <div className={"filter"}>
          <label className={"input-label"}>
            Centrale
            <select
              className={"select fit-content"}
              onChange={(ev) => setDepartmentFilter(Number(ev.target.value))}
              value={departmentFilter}
            >
              <option value={0}>--</option>
              {departmentList.map((department) => (
                <option key={department.id} value={department.id}>
                  {department.name}
                </option>
              ))}
            </select>
          </label>

          <label className={"input-label"}>
            Matériau
            <select
              className={"select fit-content"}
              onChange={(ev) => setMaterialFilter(ev.target.value)}
              value={materialFilter}
            >
              <option value={""}>--</option>
              {materialList.map((material) => (
                <option key={material} value={material}>
                  {material}
                </option>
              ))}
            </select>
          </label>
          <label className={"input-label"}>
            Validation
            <select
              className={"select fit-content"}
              onChange={(ev) => setValidationFilter(Number(ev.target.value))}
              value={validationFilter}
            >
              <option value={ValidationFilter.ALL}>--</option>
              <option value={ValidationFilter.VALIDATED}>Validée</option>
              <option value={ValidationFilter.NOT_VALIDATED}>
                Non validée
              </option>
            </select>
          </label>
        </div>
      </Section>

      <Section size={"xs"}>
        <InputSearch onChange={(event) => setSearch(event.target.value)} />
      </Section>
      <p className={"body-alert"}>
        Pensez à VALIDER les bons de livraison des matériaux mis en oeuvre pour
        qu'ils soient pris en compte dans le bouclage.
      </p>
      <Section>
        <List>
          {filteredDeliverySlips.map((deliverySlip) => (
            <div key={deliverySlip.id} className={"list-item has-actions"}>
              <Link
                params={{
                  projectId: project.id,
                  deliverySlipId: deliverySlip.id,
                }}
                to={DELIVERY_SLIP_LINK}
              >
                <div className={"item-title"}>Livraison</div>
                <div className={"item-meta-primary"}>
                  {deliverySlip.Department.name}
                </div>
                <div className={"item-meta-primary"}>
                  {deliverySlip.tonnageDelivered} T
                </div>
                <div className={"item-meta-secondary"}>
                  Camion : {deliverySlip?.Truck?.registrationNumber}
                </div>
                {deliverySlip.loadingDate !== null && (
                  <div className={"item-meta-secondary"}>
                    Chargement :{" "}
                    {formatDate(deliverySlip.loadingDate, "dd MMMM yyyy HH:mm")}
                  </div>
                )}
                {deliverySlip.deliverySlipDepartmentNumber !== null && (
                  <div className={"item-meta-secondary"}>
                    N° BL : {deliverySlip.deliverySlipDepartmentNumber}
                  </div>
                )}
                <div className={"item-meta-secondary"}>
                  Statut :{" "}
                  <span
                    className={!deliverySlip.validationDate ? "body-alert" : ""}
                  >
                    {!deliverySlip.validationDate ? "À valider" : "Validé"}
                  </span>
                </div>
              </Link>
              <div className={"item-actions"}>
                <Button
                  onClick={() => {
                    if (deliverySlip.validationDate) {
                      invalidateDeliverySlip(deliverySlip.id).then(
                        () =>
                          toastSuccess(
                            "project:invalidate-delivery-slip.SUCCESS",
                          ),
                        () =>
                          toastError("project:invalidate-delivery-slip.ERROR"),
                      );
                    } else {
                      validateDeliverySlip(deliverySlip.id).then(
                        () =>
                          toastSuccess(
                            "project:validate-delivery-slip.SUCCESS",
                          ),
                        () =>
                          toastError("project:validate-delivery-slip.ERROR"),
                      );
                    }
                  }}
                  size={"s"}
                  variant={2}
                >
                  {deliverySlip.validationDate ? "Invalider" : "Valider"}
                </Button>
              </div>
            </div>
          ))}
        </List>
      </Section>
    </div>
  );
};

const DeliverySlips = (): JSX.Element => {
  return (
    <div>
      <Content>
        <Routes>
          <Route element={<Index />} index />

          <Route
            element={
              <>
                <ViewName name={"Bons de livraison"} />
                <Outlet />
              </>
            }
          >
            <Route element={<New />} path={NEW} />
            <Route element={<DeliverySlip />} path={DELIVERY_SLIP} />
          </Route>
        </Routes>
      </Content>
    </div>
  );
};

export default DeliverySlips;
