import {
  ProjectAPIDepartmentsRecapLoaded,
  ProjectAPILoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import { withLoader } from "../../../../../services/routing/useLoader";
import Section from "../../../../../services/ui/elements/Section";
import Table from "../../../../../services/ui/elements/Table";
import { getEstimatedTonnage } from "src/services/project/project";
import { useMemo } from "react";

const OrdersAndDeliveries = (): JSX.Element => {
  const {
    departmentsRecap,
    project,
  } = useProject() as ProjectAPIDepartmentsRecapLoaded;

  const initialTonnage = useMemo(() => getEstimatedTonnage(project), [project]);
  const deliveredTonnage = useMemo(
    () =>
      departmentsRecap.reduce((acc, dr) => acc + (dr.deliveredTonnage || 0), 0),
    [departmentsRecap],
  );

  return (
    <div>
      <Section>
        <Table>
          <thead>
            <tr>
              <th>Centrale</th>
              <th>Tonnage prévisionnel initial</th>
              <th>Tonnage prévisionnel recalé suite au dernier bouclage</th>
              <th>Tonnage mis en oeuvre</th>
              <th>
                Écart entre tonnage mis en oeuvre et tonnage prévisionnel
                initial
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={"table-recap-row"}>
              <td>CUMUL</td>
              <td>{initialTonnage.toFixed(2)}</td>
              <td>
                {project.WrapUps.length
                  ? project.WrapUps[
                      project.WrapUps.length - 1
                    ].adjustedTonnage.toFixed(2)
                  : "N/A"}
              </td>
              <td>{deliveredTonnage.toFixed(2)}</td>
              <td>{(initialTonnage - deliveredTonnage).toFixed(2)}</td>
            </tr>
            {departmentsRecap.map((dr) => (
              <tr key={dr.id}>
                <td>{dr.name}</td>
                <td className={"unused-cell"}></td>
                <td className={"unused-cell"}></td>
                <td>{dr.deliveredTonnage}</td>
                <td className={"unused-cell"}></td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </div>
  );
};

const useLoad = () => {
  const { loadDepartmentsRecap } = useProject() as ProjectAPILoaded;
  return loadDepartmentsRecap;
};

export default withLoader(OrdersAndDeliveries, useLoad);
