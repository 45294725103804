import { parse } from "query-string";
import { useLocation } from "react-router-dom";

export interface QueryParams<T = string | number | boolean> {
  [key: string]: T | null | Array<T | null>;
}

export default function useQueryParams(): QueryParams {
  return Object.fromEntries(
    Object.entries(
      parse(useLocation().search, { parseBooleans: true, parseNumbers: true }),
    ).map(([key, value]) => [key, value === null ? true : value]),
  );
}
