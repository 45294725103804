import { ReactNode, useState } from "react";
import { useField } from "formik";
import Button from "../ui/elements/Button";
import { DialogCloseContext } from "../ui/elements/Dialog";

interface Props<E> {
  id?: string;
  name: string;
  selectModal: ReactNode;
  getElementName(element: NonNullable<E>): string;
  disabled?: boolean;
}

const SxSelect = <E extends unknown>({
  id,
  name,
  selectModal,
  getElementName,
  disabled,
}: Props<E>): JSX.Element => {
  const [openedSelectModal, setOpenedSelectModal] = useState(false);
  const [field, , helpers] = useField<E | null>(name);

  return (
    <div className={"sx-select"}>
      {!!field.value && (
        <span className={"selected-name"}>
          {getElementName(field.value as NonNullable<E>)}
        </span>
      )}
      {!disabled && (
        <Button
          id={id}
          onClick={() => setOpenedSelectModal(true)}
          type={"button"}
        >
          +
        </Button>
      )}

      {!!field.value && !disabled && (
        <Button
          onClick={() => helpers.setValue(null)}
          type={"button"}
          variant={"danger"}
        >
          -
        </Button>
      )}

      <DialogCloseContext.Provider value={() => setOpenedSelectModal(false)}>
        {openedSelectModal && selectModal}
      </DialogCloseContext.Provider>
    </div>
  );
};

export default SxSelect;
