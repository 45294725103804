import React, {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { FullTruck, Truck, TruckForm } from "./truck";
import {
  getTruckById,
  updateTruck as apiUpdateTruck,
  deleteTruck as apiDeleteTruck,
} from "./api";

export interface TruckAPI {
  truck: FullTruck | null;

  loadTruckById(truckId: Truck["id"]): Promise<void>;

  updateTruck(truck: TruckForm): Promise<void>;

  deleteTruck(): Promise<void>;
}

export interface TruckAPILoaded extends TruckAPI {
  truck: NonNullable<TruckAPI["truck"]>;
}

export const TruckContext = createContext<TruckAPI | null>(null);

export function useTruck(): TruckAPI {
  return useContext(TruckContext) as TruckAPI;
}

export const ProvideTruck = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [truck, setTruck] = useState<TruckAPI["truck"]>(null);

  const loadTruckById: TruckAPI["loadTruckById"] = useCallback((truckId) => {
    return getTruckById(truckId).then(({ data }) => {
      setTruck(data);
    });
  }, []);

  const updateTruck: TruckAPI["updateTruck"] = useCallback(
    (newTruck) => {
      return apiUpdateTruck(
        (truck as NonNullable<typeof truck>).id,
        newTruck,
      ).then(({ data }) => {
        setTruck(data);
      });
    },
    [truck],
  );

  const deleteTruck: TruckAPI["deleteTruck"] = useCallback(() => {
    return apiDeleteTruck((truck as NonNullable<typeof truck>).id).then(() => {
      setTruck(null);
    });
  }, [truck]);

  return (
    <TruckContext.Provider
      value={{ truck, loadTruckById, updateTruck, deleteTruck }}
    >
      {children}
    </TruckContext.Provider>
  );
};

export function withProvideTruck<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideTruck(props: P) {
    return (
      <ProvideTruck>
        <WrappedComponent {...props} />
      </ProvideTruck>
    );
  }

  WithProvideTruck.displayName = `withProvideTruck(${displayName})`;

  return WithProvideTruck;
}
