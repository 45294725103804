import {
  DepartmentRecap,
  FullProjectRaw,
  Project,
  ProjectForm,
  TruckRecap,
} from "./project";
import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { WrapUpForm } from "./wrap-up";
import { Order, OrderForm } from "./order";
import {
  DeliverySlip,
  DeliverySlipFormRaw,
  FullDeliverySlipRaw,
} from "./deliverySlip";

export function getProjectById(
  projectId: Project["id"],
): AxiosPromise<FullProjectRaw> {
  return baseAPI.get(`/projects/${projectId}`);
}

export function updateProject(
  projectId: Project["id"],
  project: ProjectForm,
): AxiosPromise<FullProjectRaw> {
  return baseAPI.put(`/projects/${projectId}`, project);
}

export function deleteProject(projectId: Project["id"]): AxiosPromise<void> {
  return baseAPI.delete(`/projects/${projectId}`);
}

export function createWrapUp(
  projectId: Project["id"],
  wrapUpForm: WrapUpForm,
): AxiosPromise<FullProjectRaw> {
  return baseAPI.post(`/projects/${projectId}/wrap-ups`, wrapUpForm);
}

export function getDepartmentsRecap(
  projectId: Project["id"],
): AxiosPromise<DepartmentRecap[]> {
  return baseAPI.get(`/projects/${projectId}/departments-recap`);
}

export function getTrucksRecap(
  projectId: Project["id"],
): AxiosPromise<TruckRecap[]> {
  return baseAPI.get(`/projects/${projectId}/trucks-recap`);
}

export function createOrder(
  projectId: Project["id"],
  order: OrderForm,
): AxiosPromise<FullProjectRaw> {
  return baseAPI.post(`/projects/${projectId}/orders`, order);
}

export function deleterOrder(orderId: Order["id"]): AxiosPromise<void> {
  return baseAPI.delete(`/orders/${orderId}`);
}

export function createDeliverySlip(
  projectId: Project["id"],
  deliverySlip: DeliverySlipFormRaw,
): AxiosPromise<FullProjectRaw> {
  return baseAPI.post(`/projects/${projectId}/delivery-slips`, deliverySlip);
}

export function updateDeliverySlip(
  deliverySlipId: DeliverySlip["id"],
  deliverySlip: DeliverySlipFormRaw,
): AxiosPromise<FullDeliverySlipRaw> {
  return baseAPI.put(`/delivery-slips/${deliverySlipId}`, deliverySlip);
}

export function deleterDeliverySlip(
  deliverySlipId: DeliverySlip["id"],
): AxiosPromise<void> {
  return baseAPI.delete(`/delivery-slips/${deliverySlipId}`);
}

export function validateDeliverySlip(
  deliverySlipId: DeliverySlip["id"],
): AxiosPromise<FullDeliverySlipRaw> {
  return baseAPI.post(`/delivery-slips/${deliverySlipId}/validate`);
}

export function invalidateDeliverySlip(
  deliverySlipId: DeliverySlip["id"],
): AxiosPromise<FullDeliverySlipRaw> {
  return baseAPI.post(`/delivery-slips/${deliverySlipId}/invalidate`);
}

export function syncDeliverySlips(
  projectId: Project["id"],
): AxiosPromise<FullProjectRaw> {
  return baseAPI.post(`/projects/${projectId}/sync`);
}

export function unsyncDeliverySlips(
  projectId: Project["id"],
): AxiosPromise<FullProjectRaw> {
  return baseAPI.post(`/projects/${projectId}/unsync`);
}

export function sendReport(
  projectId: Project["id"],
  users: string[],
): AxiosPromise<void> {
  return baseAPI.post(`/projects/${projectId}/report`, users);
}
