import {
  ActionBar,
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../services/design-system/base-layout";
import Form from "../../../services/forms/Form";
import AutoField from "../../../services/forms/AutoField";
import ValidationsErrors from "../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../services/forms/SubmitButton";
import { useReview } from "../../../services/review/apiProvider";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import { ReviewForm, ReviewSchema } from "../../../services/review/review";
import PageContent from "../../../services/ui/elements/PageContent";
import Section from "../../../services/ui/elements/Section";

const Review = (): JSX.Element => {
  const { createReview } = useReview();
  const { toastSuccess, toastError } = useToastsWithIntl(["review"]);

  return (
    <>
      <ViewName name={"Avis"} />
      <Head />

      <Content>
        <PageContent>
          <Form
            initialValues={{}}
            onSubmit={(values: ReviewForm) =>
              createReview(values).then(
                () => toastSuccess("review:create-review.SUCCESS"),
                () => {
                  toastError("review:create-review.ERROR");
                  return Promise.reject();
                },
              )
            }
            schema={ReviewSchema}
            resetFormOnSubmit
          >
            <AutoField name={"note"} />
            <Section size={"s"}>
              <h3 className={"title-xxs"}>
                Caractéristiques des chantiers généralement objet d'une
                utilisation de REGULAPPRO
              </h3>
              <Section size={"s"} indent>
                <AutoField name={"materialType"} />
                <AutoField name={"dailyTonnage"} />
              </Section>
            </Section>
            <AutoField name={"profitsBrought"} />
            <AutoField name={"improvementsWanted"} />
            <AutoField name={"details"} />
            <AutoField name={"extendAccess"} />
            <AutoField name={"extendAccessPrecisions"} />

            <ValidationsErrors />

            <ActionBar>
              <SubmitButton>Envoyer</SubmitButton>
            </ActionBar>
          </Form>
        </PageContent>
      </Content>
    </>
  );
};

export default withProvideHead(Review);
