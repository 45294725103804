import { useCompany } from "../../../../services/company/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import { useDate } from "../../../../services/date/DateContext";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import Section from "../../../../services/ui/elements/Section";
import Table from "../../../../services/ui/elements/Table";
import Button from "../../../../services/ui/elements/Button";
import { useConfirmationWithIntl } from "../../../../services/ui/ConfirmationDialog";
import { INVITE_USERS_LINK } from "src/routes/admin";
import CreateLink from "src/services/ui/elements/CreateLink";

const Users = (): JSX.Element => {
  const { users, deleteUser } = useCompany();
  const { formatDate } = useDate();
  const { toastSuccess, toastError } = useToastsWithIntl(["company", "auth"]);
  const { confirm } = useConfirmationWithIntl(["auth"]);

  return (
    <div>
      <CreateLink to={INVITE_USERS_LINK}>Inviter un utilisateur</CreateLink>
      <Section>
        <Table>
          <thead>
            <tr>
              <th>Prénom</th>
              <th>Nom</th>
              <th>E-mail</th>
              <th>Tel</th>
              <th>Date de création</th>
              <th>Date dernière co.</th>
              <th>Nb conn.</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {[...users.values()].map((u) => (
              <tr key={u.id}>
                <td>{u.firstname}</td>
                <td>{u.lastname}</td>
                <td>{u.email}</td>
                <td>{u.phoneNumber}</td>
                <td>{formatDate(u.createdAt)}</td>
                <td>{u.lastLogin !== null && formatDate(u.lastLogin)}</td>
                <td>{u.connectionNumber}</td>
                <td>
                  {u.AdministratedCompanyId === null && (
                    <Button
                      onClick={() =>
                        confirm(
                          "auth:delete-user.CONFIRM",
                          () =>
                            deleteUser(u.id).then(
                              () => toastSuccess("auth:delete-user.SUCCESS"),
                              () => toastError("auth:delete-user.ERROR"),
                            ),
                          true,
                        )
                      }
                    >
                      Supprimer
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </div>
  );
};

const useLoad = () => {
  const { loadUsers } = useCompany();

  return loadUsers;
};

export default withLoader(Users, useLoad);
