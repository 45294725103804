import { useCallback, useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";
import Dialog, {
  DialogCloseAPI,
  useDialogClose,
} from "../../ui/elements/Dialog";
import { CompanyAPILoaded, useCompany } from "../../company/apiProvider";
import { compareBoolean, useSearch } from "../../data-structures/array";
import InputSearch from "../../ui/elements/InputSearch";
import Button from "../../ui/elements/Button";
import SxSelect from "../../forms/SxSelect";
import { useYupField } from "../../forms/Form";
import { AnySchema } from "yup";
import useLoader from "../../routing/useLoader";
import CardHead from "../../ui/elements/CardHead";
import CardBody from "../../ui/elements/CardBody";
import Section from "../../ui/elements/Section";
import List from "../../ui/elements/List";
import Loading from "../../routing/components/Loading";
import LoaderErrors from "../../routing/components/LoaderErrors";
import NewTruckForm from "./NewTruckForm";
import { TruckForm } from "../truck";
import axios from "axios";
import { useToastsWithIntl } from "src/services/toast-notifications";
import { DeliverySlipForm } from "src/services/project/deliverySlip";
import { useOnChange } from "src/services/react-hooks/useOnChange";
import { PartialNullable } from "src/services/types/utility";

const useLoad = () => {
  const { loadTrucks } = useCompany();

  return loadTrucks;
};

const SelectTruckModal = ({ name }: { name: string }): JSX.Element => {
  const { trucks, createTruck } = useCompany() as CompanyAPILoaded;
  const { toastError, toastSuccess } = useToastsWithIntl(["truck"]);
  const {
    values: { ProviderId: providerId },
  } = useFormikContext<
    PartialNullable<Omit<DeliverySlipForm, "ProvideId">> &
      Pick<DeliverySlipForm, "ProviderId">
  >();
  const [, , helpers] = useField(name);
  const onClose = useDialogClose() as NonNullable<DialogCloseAPI>;
  const [openedCreateModal, setOpenedCreateModal] = useState(false);

  const sortedTrucks = useMemo(() => {
    const providerTrucks = [...trucks.values()].filter(
      (truck) => truck.Provider.id === providerId,
    );

    return providerTrucks.sort((truckA, truckB) =>
      compareBoolean(truckA.isFavorite, truckB.isFavorite),
    );
  }, [providerId, trucks]);

  const [searchedTrucks, setSearch] = useSearch(sortedTrucks);

  return (
    <Dialog className={"theme-light"} panel>
      <CardHead title={"Sélectionner un camion"} />

      <CardBody>
        <InputSearch
          onChange={(event) => setSearch(event.currentTarget.value)}
        />

        <Section size={"s"}>
          <Button
            onClick={() => setOpenedCreateModal(true)}
            type={"button"}
            link
          >
            + Nouveau camion
          </Button>
        </Section>

        <Section>
          <List>
            {searchedTrucks.map((truck) => (
              <Button
                key={truck.id}
                className={"list-item"}
                onClick={() => {
                  helpers.setValue(truck.id);
                  onClose();
                }}
                variant={2}
                block
                link
              >
                {truck.registrationNumber}
              </Button>
            ))}
          </List>
        </Section>
      </CardBody>

      {openedCreateModal && (
        <Dialog onClose={() => setOpenedCreateModal(false)}>
          <CardHead title={"Nouveau camion"} />

          <CardBody>
            <NewTruckForm
              onSubmit={(truck: TruckForm) => {
                return createTruck(truck).then(
                  (newTruck) => {
                    toastSuccess("truck:create-truck.SUCCESS");
                    helpers.setValue(newTruck.data.id);
                    onClose();
                  },
                  (error) => {
                    if (
                      axios.isAxiosError(error) &&
                      error.response?.status === 409
                    ) {
                      toastError(
                        "truck:create-truck.REGISTRATION_NUMBER_ALREADY_USED",
                        undefined,
                        { transporterName: error.response.data.providerName },
                      );
                    } else {
                      toastError("truck:create-truck.ERROR");
                    }
                  },
                );
              }}
              providerId={providerId}
            />
          </CardBody>
        </Dialog>
      )}
    </Dialog>
  );
};

const SelectTruckField = ({ name }: { name: string }): JSX.Element => {
  const { trucks } = useCompany() as CompanyAPILoaded;
  const fieldSchema = useYupField(name) as AnySchema;
  const {
    values: { ProviderId: providerId },
    setFieldValue,
  } = useFormikContext<PartialNullable<DeliverySlipForm>>();

  useOnChange(
    providerId,
    useCallback(() => setFieldValue(name, null), [name, setFieldValue]),
  );

  const isRequired = useMemo(
    () => fieldSchema.tests.find((t) => t.OPTIONS.name === "required"),
    [fieldSchema],
  );

  const { error, reload, loading } = useLoader(useLoad());

  return (
    <div className={"form-block"}>
      <label htmlFor={name}>
        {fieldSchema.spec.label} {isRequired && "*"}
      </label>

      {loading ? (
        <Loading />
      ) : error ? (
        <LoaderErrors error={error} reload={reload} />
      ) : (
        <SxSelect<number>
          disabled={providerId === null || providerId === undefined}
          getElementName={(truckId) =>
            trucks.get(truckId)?.registrationNumber || ""
          }
          id={name}
          name={name}
          selectModal={<SelectTruckModal name={name} />}
        />
      )}
    </div>
  );
};

export default SelectTruckField;
