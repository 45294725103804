import { SUPER_ADMIN_LINK } from "./private";

export const CLIENTS = "clients" as const;
export const CLIENTS_LINK = `${SUPER_ADMIN_LINK}/${CLIENTS}` as const;

export const USERS = "users" as const;
export const USERS_LINK = `${SUPER_ADMIN_LINK}/${USERS}` as const;

export const REVIEWS = "reviews" as const;
export const REVIEWS_LINK = `${SUPER_ADMIN_LINK}/${REVIEWS}` as const;
