import Form from "../../../services/forms/Form";
import SectionTitle from "../../../services/ui/elements/SectionTitle";
import ContentBlock from "../../../services/ui/elements/ContentBlock";
import Section from "../../../services/ui/elements/Section";
import React, { useCallback, useMemo } from "react";
import { number, object, string } from "yup";
import { useTranslation } from "react-i18next";
import AutoField from "../../../services/forms/AutoField";
import { Fonctions, Gender, getFullName } from "../../../services/auth/user";
import { ActionBar } from "../../../services/design-system/base-layout";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import { AxiosError } from "axios";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import ValidationsErrors from "../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../services/forms/SubmitButton";
import { PRIVATE } from "../../../routes/private";
import Link from "../../../services/routing/components/Link";
import { CompanyAPI, useCompany } from "src/services/company/apiProvider";
import { withLoader } from "src/services/routing/useLoader";

const Index = (): JSX.Element => {
  const { user, updateUser } = useAuth() as AuthAPIConnected;
  const { company } = useCompany() as CompanyAPI;
  const { t } = useTranslation(["auth"]);
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);

  const ProfileSchema = useMemo(
    () =>
      object().shape({
        lastname: string().label("Nom").required(),
        firstname: string().label("Prénom").required(),
        email: string().label(t("auth:email")).notEditable(),
        phoneNumber: string().label("Numéro de téléphone").nullable(),
        gender: number()
          .label("Civilité")
          .nullable()
          .oneOfEnum(Gender, "auth", "user.gender"),
        fonction: number()
          .label("Fonction")
          .nullable()
          .optional()
          .oneOfEnum(Fonctions, "auth", "user.fonction", true),
      }),
    [t],
  );

  return (
    <div>
      {company !== null && (
        <Section>
          <div className={"info info-secondary info-m"}>
            <p>Code société : {company.code}</p>
            <p>
              Administrateur du compte :{" "}
              {getFullName(company.Administrators[0])} -{" "}
              {company.Administrators[0].email}
            </p>
          </div>
        </Section>
      )}
      <Form
        initialValues={user}
        onSubmit={(values) =>
          updateUser(values).then(
            () => {
              toastSuccess("auth:update-user.SUCCESS");
            },
            (err: AxiosError) => {
              toastError("auth:update-user.ERROR");
            },
          )
        }
        schema={ProfileSchema}
      >
        <Section size={"xs"}>
          <SectionTitle title={"Mes informations personnelles"} />
          <ContentBlock size={"s"}>
            <AutoField name={"firstname"} />
            <AutoField name={"lastname"} />
            <AutoField name={"email"} />
            <AutoField name={"phoneNumber"} />
            <AutoField name={"gender"} />
            <AutoField name={"fonction"} />
          </ContentBlock>
        </Section>

        <ValidationsErrors />

        <ActionBar>
          <Link className={"btn-2"} to={PRIVATE}>
            Retour
          </Link>
          <SubmitButton>Enregistrer</SubmitButton>
        </ActionBar>
      </Form>
    </div>
  );
};

function useLoad() {
  const { user } = useAuth() as AuthAPIConnected;
  const { loadCompanyById } = useCompany();

  return useCallback(() => {
    if (user.CompanyId === null) {
      return Promise.resolve();
    }
    return loadCompanyById(user.CompanyId);
  }, [loadCompanyById, user]);
}

export default withLoader(Index, useLoad);
