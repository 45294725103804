import {
  DetailedHTMLProps,
  FunctionComponent,
  InputHTMLAttributes,
} from "react";

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {}

const Input: FunctionComponent<InputProps> = ({ ...inputProps }) => {
  return <input {...inputProps} className={"input"} />;
};

export default Input;
