import {
  TruckAPILoaded,
  useTruck,
  withProvideTruck,
} from "../../../../services/truck/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import { useParams } from "../../../../services/routing/useParams";
import { TRUCK_LINK } from "../../../../routes/user/trucks";
import {
  ActionBar,
  ViewName,
} from "../../../../services/design-system/base-layout";
import Form from "../../../../services/forms/Form";
import AutoField from "../../../../services/forms/AutoField";
import ValidationsErrors from "../../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../../services/forms/SubmitButton";
import { TruckForm, TruckFormSchema } from "../../../../services/truck/truck";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import Button from "../../../../services/ui/elements/Button";
import { useConfirmationWithIntl } from "../../../../services/ui/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { TRUCKS_LINK } from "../../../../routes/user";
import SelectProviderField from "../../../../services/provider/components/SelectProviderField";
import AutoObject from "../../../../services/forms/AutoObject";
import { useCallback } from "react";
import { useNonNullableReference } from "../../../../services/ui/utility";
import iconTrashWhite from "../../../../assets/img/icons/icon-trash-white.svg";
import Section from "src/services/ui/elements/Section";
import axios from "axios";

const Truck = (): JSX.Element => {
  const { toastError, toastSuccess } = useToastsWithIntl(["truck"]);
  const {
    truck: nullableTruck,
    updateTruck,
    deleteTruck,
  } = useTruck() as TruckAPILoaded;
  const { confirm } = useConfirmationWithIntl(["truck"]);
  const navigate = useNavigate();

  const truck = useNonNullableReference(nullableTruck);

  return (
    <div>
      <ViewName
        actionsBar={
          <Button
            onClick={() =>
              !truck.usedInProject
                ? confirm(
                    "truck:confirm-delete-truck",
                    () =>
                      deleteTruck().then(
                        () => {
                          toastSuccess("truck:delete-truck.SUCCESS");
                          navigate(TRUCKS_LINK);
                        },
                        () => {
                          toastError("truck:delete-truck.ERROR");
                        },
                      ),
                    true,
                  )
                : toastError("truck:delete-truck.USED_BY_PROJECT")
            }
            variant={"danger"}
          >
            <img alt={"delete icon"} className={"icon"} src={iconTrashWhite} />
          </Button>
        }
        name={truck.Provider.name}
      />

      {truck.usedInProject && (
        <Section>
          <div className={"info info-m info-secondary"}>
            <p>
              Le champ grisé n'est pas modifiable car ce camion est déjà affecté
              à un projet
            </p>
          </div>
        </Section>
      )}

      <Form
        initialValues={truck}
        onSubmit={(newTruck: TruckForm) => {
          return updateTruck(newTruck).then(
            () => {
              toastSuccess("truck:update-truck.SUCCESS");
            },
            (error) => {
              if (axios.isAxiosError(error) && error.response?.status === 409) {
                toastError(
                  "truck:update-truck.REGISTRATION_NUMBER_ALREADY_USED",
                  undefined,
                  { transporterName: error.response.data.providerName },
                );
              } else {
                toastError("truck:update-truck.ERROR");
              }
            },
          );
        }}
        schema={TruckFormSchema}
        schemaContext={{ usedInProject: truck.usedInProject }}
      >
        <SelectProviderField name={"ProviderId"} />
        <AutoField name={"type"} />
        <AutoField name={"registrationNumber"} />
        <AutoField name={"phoneNumber"} />
        <AutoObject name={"TruckUserData"}>
          <AutoField name={"TruckUserData.identificationNumber"} />
        </AutoObject>

        <ValidationsErrors />

        <ActionBar>
          <SubmitButton>Enregistrer</SubmitButton>
        </ActionBar>
      </Form>
    </div>
  );
};

const useLoad = () => {
  const { loadTruckById } = useTruck();
  const { truckId } = useParams<typeof TRUCK_LINK>();

  return useCallback(() => loadTruckById(Number(truckId)), [
    loadTruckById,
    truckId,
  ]);
};

export default withProvideTruck(withLoader(Truck, useLoad));
