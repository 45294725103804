import {
  useDepartment,
  withProvideDepartment,
} from "../../../../services/department/apiProvider";
import useLoader from "../../../../services/routing/useLoader";
import { useParams } from "../../../../services/routing/useParams";
import { DEPARTMENT_LINK } from "../../../../routes/user/departments";
import LoaderErrors from "../../../../services/routing/components/LoaderErrors";
import Loading from "../../../../services/routing/components/Loading";
import { ViewName } from "../../../../services/design-system/base-layout";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import Button from "../../../../services/ui/elements/Button";
import { useConfirmationWithIntl } from "../../../../services/ui/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import { useNonNullableReference } from "../../../../services/ui/utility";
import { DEPARTMENTS_LINK } from "../../../../routes/user";
import iconTrashWhite from "../../../../assets/img/icons/icon-trash-white.svg";
import DepartmentForm from "../../../../services/department/components/DepartmentForm";
import Section from "src/services/ui/elements/Section";

const Department = (): JSX.Element => {
  const { toastError, toastSuccess } = useToastsWithIntl(["department"]);
  const {
    department: nullableDepartment,
    loadDepartmentById,
    updateDepartment,
    deleteDepartment,
  } = useDepartment();
  const { confirm } = useConfirmationWithIntl(["department"]);
  const navigate = useNavigate();

  const { departmentId } = useParams<typeof DEPARTMENT_LINK>();

  const { error, loading, reload } = useLoader(
    () => loadDepartmentById(Number(departmentId)),
    [loadDepartmentById, departmentId],
  );

  const department = useNonNullableReference(nullableDepartment);

  if (error) return <LoaderErrors error={error} reload={reload} />;
  if (loading) return <Loading />;

  return (
    <div>
      <ViewName
        actionsBar={
          <Button
            onClick={() =>
              !department.usedInProject
                ? confirm(
                    "department:confirm-delete-department",
                    () =>
                      deleteDepartment().then(
                        () => {
                          toastSuccess("department:delete-department.SUCCESS");
                          navigate(DEPARTMENTS_LINK);
                        },
                        () => {
                          toastError("department:delete-department.ERROR");
                        },
                      ),
                    true,
                  )
                : toastError("department:delete-department.USED_IN_PROJECT")
            }
            variant={"danger"}
          >
            <img alt={"delete icon"} className={"icon"} src={iconTrashWhite} />
          </Button>
        }
        name={department.name}
      />

      {department.usedInProject && (
        <Section>
          <div className={"info info-m info-secondary"}>
            <p>
              Les champs grisés ne sont pas modifiables car cette centrale est
              déjà affectée à un projet
            </p>
          </div>
        </Section>
      )}

      <DepartmentForm
        context={{ usedInProject: department.usedInProject }}
        initialValues={department}
        onSubmit={(newDepartment) => {
          return updateDepartment(newDepartment).then(
            () => {
              toastSuccess("department:update-department.SUCCESS");
            },
            () => {
              toastError("department:update-department.ERROR");
            },
          );
        }}
      />
    </div>
  );
};

export default withProvideDepartment(Department);
