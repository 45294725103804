import { AxiosPromise } from "axios";
import { User, UserRaw } from "../auth/user";
import baseAPI from "../auth/api";
import { Company } from "../company/company";

export type GetUsersResponseRaw = UserRaw & {
  Company: Company;
  AdministratedCompany: Company | null;
};
export type GetUsersResponse = User & {
  Company: Company;
  AdministratedCompany: Company | null;
};

export function getUsers(): AxiosPromise<GetUsersResponseRaw[]> {
  return baseAPI.get("/users");
}

export type GetCompaniesResponse = Company & {
  trialActive: boolean;
  activeSubscriptions: number;
  dueSubscriptions: number;
  expiredSubscriptions: number;
  toProcessSubscriptions: number;
};

export function getCompanies(): AxiosPromise<GetCompaniesResponse[]> {
  return baseAPI.get("/companies");
}
