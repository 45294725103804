import { mapUserRawToUser, User } from "../auth/user";
import {
  ComponentType,
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  getCompanies,
  GetCompaniesResponse,
  getUsers,
  GetUsersResponse,
} from "./api";
import { Company } from "../company/company";
import { deleteUserById } from "../auth/api";
import { copyMapAndDelete } from "../data-structures/map";

export interface SuperAdminAPI {
  users: Map<User["id"], GetUsersResponse>;

  loadUsers(): Promise<void>;
  deleteUser(userId: User["id"]): Promise<void>;

  companies: Map<Company["id"], GetCompaniesResponse>;

  loadCompanies(): Promise<void>;
}

export const SuperAdminContext = createContext<SuperAdminAPI | null>(null);

export function useSuperAdmin(): SuperAdminAPI {
  return useContext(SuperAdminContext) as SuperAdminAPI;
}

export const ProvideSuperAdmin = ({
  children,
}: {
  children: ReactElement;
}): JSX.Element => {
  const [users, setUsers] = useState<SuperAdminAPI["users"]>(new Map());

  const loadUsers: SuperAdminAPI["loadUsers"] = useCallback(
    () =>
      getUsers().then(({ data }) =>
        setUsers(new Map(data.map((u) => [u.id, mapUserRawToUser(u)]))),
      ),
    [],
  );

  const deleteUser: SuperAdminAPI["deleteUser"] = useCallback(
    (userId) =>
      deleteUserById(userId).then(() => {
        setUsers((prevUsers) => copyMapAndDelete(prevUsers, userId));
      }),
    [],
  );

  const [companies, setCompanies] = useState<SuperAdminAPI["companies"]>(
    new Map(),
  );

  const loadCompanies: SuperAdminAPI["loadCompanies"] = useCallback(
    () =>
      getCompanies().then(({ data }) =>
        setCompanies(new Map(data.map((c) => [c.id, c]))),
      ),
    [],
  );

  return (
    <SuperAdminContext.Provider
      value={{ users, loadUsers, companies, loadCompanies, deleteUser }}
    >
      {children}
    </SuperAdminContext.Provider>
  );
};

export default function withProvideSuperAdmin<
  P extends Record<string, unknown>
>(WrappedComponent: ComponentType<P>): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideSuperAdmin(props: P) {
    return (
      <ProvideSuperAdmin>
        <WrappedComponent {...props} />
      </ProvideSuperAdmin>
    );
  }

  WithProvideSuperAdmin.displayName = `withProvideSuperAdmin(${displayName})`;

  return WithProvideSuperAdmin;
}
