import React from "react";
import "./services/validations/yup-init";
import "./services/i18n";
import { ProvideToast } from "./services/toast-notifications";
import { ProvideAuth } from "./services/auth/apiProvider";
import { ProvideConfirmation } from "./services/ui/ConfirmationDialog";
import { LOGIN_LINK, PUBLIC } from "./routes/public";
import Public from "./views/public/Public";
import Private from "./views/private/Private";
import { PRIVATE } from "./routes/private";
import { Navigate, Route } from "react-router-dom";
import Routes from "./services/routing/Routes";
import { RequiresAuth } from "./services/auth/hocs/withRedirectToPrivate";

const MainRouter = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Navigate to={PUBLIC} />} index />
      <Route element={<Public />} path={`${PUBLIC}/*`} />

      <Route
        element={
          <RequiresAuth redirectTo={LOGIN_LINK}>
            <Private />
          </RequiresAuth>
        }
        path={`${PRIVATE}/*`}
      />
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <ProvideToast>
      <ProvideAuth>
        <ProvideConfirmation>
          <MainRouter />
        </ProvideConfirmation>
      </ProvideAuth>
    </ProvideToast>
  );
};

export default App;
