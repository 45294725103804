import { Department } from "../department/department";
import { number, object, string } from "yup";

export interface OrderBase {
  tonnage: number;
  orderNum: string;
}

export interface Order extends OrderBase {
  id: number;
  createdAt: Date;
}

export interface OrderRaw extends OrderBase {
  id: number;
  createdAt: string;
}

export interface FullOrder extends Order {
  Department: Department;
}

export interface FullOrderRaw extends OrderRaw {
  Department: Department;
}

export interface OrderForm extends OrderBase {
  DepartmentId: Department["id"];
}

export const OrderFormSchema = object()
  .shape({
    tonnage: number()
      .label("Tonnage de la commande")
      .nullable()
      .required()
      .min(0),
    DepartmentId: number().label("Centrale").nullable().required(),
  })
  .defined();

export const POFormSchema = object()
  .shape({
    orderNum: string().label("N° de cmd").nullable(),
    DepartmentId: number().label("Centrale").nullable().required(),
  })
  .defined();
