// TODO: refactor user types

/* Public User returned from API */
import { getNumericEnumEntries } from "../data-structures/enum";
import { Company } from "../company/company";
import { parseISO } from "date-fns";

export interface UserBase {
  id: number;
  gender: Gender;
  email: string;
  lastname: string;
  firstname: string;
  CompanyId: Company["id"] | null;
  AdministratedCompanyId: Company["id"] | null;
  admin: boolean;
  phoneNumber: string | null;
  connectionNumber: number;
}

export interface User extends UserBase {
  createdAt: Date;
  lastLogin: Date | null;
}

export interface UserRaw extends UserBase {
  createdAt: string;
  lastLogin: string | null;
}

export enum Gender {
  MISTER = 1,
  MADAM,
}

/* User for login */
export interface UserToLogin {
  email: string;
  password: string;
}

/* User sent from login */
export interface LoggedUser extends UserBase {
  xsrfToken: string;
}

/* User register to send to api*/
export interface UserToRegister {
  email: string;
  password: string;
  lastname: string | null;
  firstname: string | null;
}

/* User register for register form*/
export type UserToRegisterForm = (UserToRegister & {
  passwordConfirmation: string;
}) &
  ({ Company: Omit<Company, "id" | "code"> } | { CompanyCode: string });

export interface UserToUpdateForm extends UserBase {}

export interface UserPasswordForm {
  oldPwd: UserToLogin["password"];
  newPwd: UserToLogin["password"];
}

export function getShortName(user: UserBase): string {
  return user.firstname && user.lastname
    ? `${user.firstname.slice(0, 1)}. ${user.lastname}`
    : "Votre compte";
}

export function getFullName(user: UserBase): string {
  return `${user.firstname} ${user.lastname.toUpperCase()}`;
}

export enum Fonctions {
  SELLER,
  BOSS,
  AGENCY_BOSS,
  WORKS_FOREMAN,
  OTHER,
}

export const FONCTIONS_ENTRIES = getNumericEnumEntries(Fonctions);

export function mapUserRawToUser<UR extends UserRaw>(
  user: UR,
): UR & { createdAt: Date; lastLogin: Date | null } {
  return {
    ...user,
    createdAt: parseISO(user.createdAt),
    lastLogin: user.lastLogin === null ? null : parseISO(user.lastLogin),
  };
}
