import { useProject } from "../../../../../services/project/apiProvider";
import { useToastsWithIntl } from "../../../../../services/toast-notifications";
import { object, string } from "yup";
import Form from "../../../../../services/forms/Form";
import SubmitButton from "../../../../../services/forms/SubmitButton";
import AutoField from "../../../../../services/forms/AutoField";
import { ActionBar } from "../../../../../services/design-system/base-layout";
import {
  AuthAPIConnected,
  useAuth,
} from "../../../../../services/auth/apiProvider";
import {
  CompanyAPILoaded,
  useCompany,
} from "../../../../../services/company/apiProvider";

const ReportSchema = object({
  users: string().label("Utilisateurs").nullable().required().multiline(),
});

const Report = (): JSX.Element => {
  const { user } = useAuth() as AuthAPIConnected;
  const { company } = useCompany() as CompanyAPILoaded;
  const { sendReport } = useProject();
  const { toastSuccess, toastError } = useToastsWithIntl(["project"]);

  return (
    <div>
      <p>
        Pour recevoir le rapport par e-mail, renseignez les destinataires puis
        cliquez sur le bouton ci-dessous.
      </p>

      <Form
        initialValues={{
          users: [
            ...new Set([
              user.email,
              ...company.Administrators.map((a) => a.email),
            ]).values(),
          ].join("\n"),
        }}
        onSubmit={({ users }) =>
          sendReport(users.split("\n")).then(
            () => toastSuccess("project:send-report.SUCCESS"),
            () => toastError("project:send-report.ERROR"),
          )
        }
        schema={ReportSchema}
      >
        <AutoField name={"users"} />

        <ActionBar>
          <SubmitButton>Envoyer</SubmitButton>
        </ActionBar>
      </Form>
    </div>
  );
};

export default Report;
