import { useDate } from "src/services/date/DateContext";
import Button from "src/services/ui/elements/Button";
import { useConfirmationWithIntl } from "src/services/ui/ConfirmationDialog";
import { useToastsWithIntl } from "src/services/toast-notifications";
import iconTrashWhite from "../../../../../assets/img/icons/icon-trash-white.svg";
import React, { useMemo } from "react";
import { DEPARTMENT_ORDERS_LINK } from "src/routes/user/project/department";
import {
  useProject,
  ProjectAPIDepartmentsRecapLoaded,
} from "src/services/project/apiProvider";
import { useParams } from "src/services/routing/useParams";
import CardBody from "src/services/ui/elements/CardBody";
import CardHead from "src/services/ui/elements/CardHead";
import Table from "src/services/ui/elements/Table";

const DepartmentOrdersPanel = (): JSX.Element => {
  const {
    project,
    departmentsRecap,
    deleteOrder,
  } = useProject() as ProjectAPIDepartmentsRecapLoaded;

  const { confirm } = useConfirmationWithIntl(["project"]);
  const { toastError, toastSuccess } = useToastsWithIntl(["project"]);

  const { formatDate } = useDate();

  const { departmentId } = useParams<typeof DEPARTMENT_ORDERS_LINK>();

  const orders = useMemo(
    () =>
      project.Orders.filter(
        (order) => order.Department.id === Number(departmentId),
      ),
    [departmentId, project.Orders],
  );

  const selectedDepartment = useMemo(
    () =>
      departmentsRecap.find(
        (department) => department.id === Number(departmentId),
      ),
    [departmentId, departmentsRecap],
  );

  return (
    <div>
      <CardHead title={`Liste des commandes - ${selectedDepartment?.name}`} />

      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Tonnage</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              return !order.orderNum ? (
                <tr key={order.id}>
                  <td>{formatDate(order.createdAt)}</td>
                  <td>{order.tonnage}</td>
                  <td>
                    <Button
                      onClick={() =>
                        confirm(
                          "project:delete-order.CONFIRMATION",
                          () =>
                            deleteOrder(order.id).then(
                              () => {
                                toastSuccess("project:delete-order.SUCCESS");
                              },
                              () => {
                                toastError("project:delete-order.ERROR");
                              },
                            ),
                          true,
                        )
                      }
                      type={"button"}
                      variant={"danger"}
                    >
                      <img
                        alt={"delete icon"}
                        className={"icon"}
                        src={iconTrashWhite}
                      />
                    </Button>
                  </td>
                </tr>
              ) : (
                ""
              );
            })}
          </tbody>
        </Table>
      </CardBody>
    </div>
  );
};

export default DepartmentOrdersPanel;
