import {
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../../services/design-system/base-layout";
import Routes from "../../../../services/routing/Routes";
import { Route } from "react-router-dom";
import New from "./New";
import {
  MATERIAL,
  MATERIAL_LINK,
  NEW_MATERIAL,
  NEW_MATERIAL_LINK,
} from "../../../../routes/user/materials";
import Link from "../../../../services/routing/components/Link";
import Material from "./Material";
import {
  CompanyAPILoaded,
  useCompany,
} from "../../../../services/company/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import PageContent from "../../../../services/ui/elements/PageContent";
import Section from "../../../../services/ui/elements/Section";
import List from "../../../../services/ui/elements/List";
import { useSearch } from "../../../../services/data-structures/array";
import InputSearch from "../../../../services/ui/elements/InputSearch";
import CreateLink from "../../../../services/ui/elements/CreateLink";

const useLoad = () => {
  const { loadMaterials } = useCompany();

  return loadMaterials;
};

const Index = withLoader(() => {
  const { materials } = useCompany() as CompanyAPILoaded;

  const [searchedMaterials, setSearch] = useSearch([...materials.values()]);

  return (
    <PageContent>
      <Section size={"xs"}>
        <CreateLink to={NEW_MATERIAL_LINK}>Nouveau matériau</CreateLink>
      </Section>

      <Section size={"xs"}>
        <InputSearch onChange={(event) => setSearch(event.target.value)} />
      </Section>

      <Section>
        <List>
          {searchedMaterials.map((material) => (
            <Link
              key={material.id}
              className={"list-item"}
              params={{ materialId: material.id }}
              to={MATERIAL_LINK}
            >
              <div className={"item-title"}>{material.name}</div>
              <div className={"item-meta-primary"}>
                {material.density} T/m<sup>3</sup>
              </div>
            </Link>
          ))}
        </List>
      </Section>
    </PageContent>
  );
}, useLoad);

const Materials = (): JSX.Element => {
  return (
    <>
      <ViewName name={"Matériaux"} />
      <Head />

      <Content>
        <Routes>
          <Route element={<Index />} index />
          <Route element={<New />} path={NEW_MATERIAL} />
          <Route element={<Material />} path={MATERIAL} />
        </Routes>
      </Content>
    </>
  );
};

export default withProvideHead(Materials);
