import { useMemo, useState } from "react";
import { useField } from "formik";
import Dialog, {
  DialogCloseAPI,
  useDialogClose,
} from "../../ui/elements/Dialog";
import { CompanyAPILoaded, useCompany } from "../../company/apiProvider";
import { useSearch } from "../../data-structures/array";
import InputSearch from "../../ui/elements/InputSearch";
import Button from "../../ui/elements/Button";
import SxSelect from "../../forms/SxSelect";
import Form, { useYupField } from "../../forms/Form";
import { AnySchema } from "yup";
import useLoader from "../../routing/useLoader";
import CardHead from "../../../services/ui/elements/CardHead";
import CardBody from "../../../services/ui/elements/CardBody";
import Section from "../../../services/ui/elements/Section";
import List from "../../../services/ui/elements/List";
import { MaterialForm, MaterialFormSchema } from "../material";
import AutoField from "../../forms/AutoField";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { ActionBar } from "../../design-system/base-layout";
import SubmitButton from "../../forms/SubmitButton";
import { useToastsWithIntl } from "../../toast-notifications";
import Loading from "../../routing/components/Loading";
import LoaderErrors from "../../routing/components/LoaderErrors";

const SelectMaterialModal = ({ name }: { name: string }): JSX.Element => {
  const { toastSuccess, toastError } = useToastsWithIntl(["material"]);
  const { materials, createMaterial } = useCompany() as CompanyAPILoaded;

  const [openedCreateModal, setOpenedCreateModal] = useState(false);
  const [, , helpers] = useField(name);
  const onClose = useDialogClose() as NonNullable<DialogCloseAPI>;

  const [searchedMaterials, setSearch] = useSearch([...materials.values()]);

  return (
    <Dialog className={"theme-light"} panel>
      <CardHead title={"Sélectionner un matériau"} />
      <CardBody>
        <InputSearch
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
        <Section size={"xs"}>
          <Button
            onClick={() => setOpenedCreateModal(true)}
            type={"button"}
            link
          >
            + Nouveau matériau
          </Button>
        </Section>
        <Section>
          <List>
            {searchedMaterials.map((material) => (
              <Button
                key={material.id}
                className={"list-item"}
                onClick={() => {
                  helpers.setValue(material.id);
                  onClose();
                }}
                variant={2}
                block
                link
              >
                {material.name} - {material.density} T/m<sup>3</sup>
              </Button>
            ))}
          </List>
        </Section>
      </CardBody>

      {openedCreateModal && (
        <Dialog onClose={() => setOpenedCreateModal(false)}>
          <CardHead title={"Nouveau matériau"} />
          <CardBody>
            <Form
              initialValues={{}}
              onSubmit={(material: MaterialForm) => {
                return createMaterial(material).then(
                  () => {
                    toastSuccess("material:create-material.SUCCESS");
                    setOpenedCreateModal(false);
                  },
                  () => {
                    toastError("material:create-material.ERROR");
                  },
                );
              }}
              schema={MaterialFormSchema}
            >
              <AutoField name={"name"} />
              <AutoField name={"density"} unit={"T/m3"} />

              <ValidationsErrors />

              <ActionBar>
                <SubmitButton>Enregistrer</SubmitButton>
              </ActionBar>
            </Form>
          </CardBody>
        </Dialog>
      )}
    </Dialog>
  );
};

const useLoad = () => {
  const { loadMaterials } = useCompany();

  return loadMaterials;
};

type Props = {
  name: string;
};

const SelectMaterialField = ({ name }: Props): JSX.Element => {
  const { materials } = useCompany() as CompanyAPILoaded;
  const fieldSchema = useYupField(name) as AnySchema;

  const isRequired = useMemo(
    () => fieldSchema.tests.find((t) => t.OPTIONS.name === "required"),
    [fieldSchema],
  );

  const { error, reload, loading } = useLoader(useLoad());

  return (
    <div className={"form-block"}>
      <label className={"input-label"} htmlFor={name}>
        {fieldSchema.spec.label} {isRequired && "*"}
      </label>

      {loading ? (
        <Loading />
      ) : error ? (
        <LoaderErrors error={error} reload={reload} />
      ) : (
        <SxSelect<number>
          getElementName={(materialId) => materials.get(materialId)?.name || ""}
          id={name}
          name={name}
          selectModal={<SelectMaterialModal name={name} />}
        />
      )}
    </div>
  );
};

export default SelectMaterialField;
