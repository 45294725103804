import {
  ProjectAPITrucksRecapLoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import { withLoader } from "../../../../../services/routing/useLoader";
import { useCallback, useMemo } from "react";
import { useDate } from "../../../../../services/date/DateContext";
import Dialog from "../../../../../services/ui/elements/Dialog";
import CardHead from "../../../../../services/ui/elements/CardHead";
import CardBody from "../../../../../services/ui/elements/CardBody";
import Link from "../../../../../services/routing/components/Link";
import {
  TRUCKS_DETAILS,
  TRUCKS_DETAILS_LINK,
} from "../../../../../routes/user/recap";
import Routes from "../../../../../services/routing/Routes";
import { Outlet, Route, useNavigate } from "react-router-dom";
import {
  TruckAPILoaded,
  useTruck,
  withProvideTruck,
} from "../../../../../services/truck/apiProvider";
import { useParams } from "../../../../../services/routing/useParams";
import Table from "../../../../../services/ui/elements/Table";

const useTruckLoad = () => {
  const { loadTruckById } = useTruck();
  const { truckId } = useParams<typeof TRUCKS_DETAILS_LINK>();

  return useCallback(() => loadTruckById(Number(truckId)), [
    loadTruckById,
    truckId,
  ]);
};

const TruckPanel = withProvideTruck(
  withLoader(() => {
    const { truck } = useTruck() as TruckAPILoaded;
    const { truckId } = useParams<typeof TRUCKS_DETAILS_LINK>();
    const { project } = useProject() as ProjectAPITrucksRecapLoaded;
    const { formatDate } = useDate();

    const deliverySlips = useMemo(() => {
      return project.DeliverySlips.filter(
        (ds) => ds.Truck.id === Number(truckId),
      );
    }, [truckId, project.DeliverySlips]);

    return (
      <div>
        <CardHead title={`Listing - ${truck.registrationNumber}`} />

        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Quantité</th>
                <th>Centrale</th>
              </tr>
            </thead>
            <tbody>
              {deliverySlips.map((ds) => (
                <tr key={ds.id}>
                  <td>
                    {ds.deliveryDate
                      ? formatDate(ds.deliveryDate, "Pp")
                      : "N/A"}
                  </td>
                  <td>{ds.tonnageDelivered}T</td>
                  <td>{ds.Department.name}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </div>
    );
  }, useTruckLoad),
);

const Trucks = (): JSX.Element => {
  const { trucksRecap } = useProject() as ProjectAPITrucksRecapLoaded;
  const navigate = useNavigate();

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Transporteur</th>
            <th>Type</th>
            <th>Immatriculation</th>
            <th>Nb Livraisons</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {trucksRecap.map((truckRecap) => (
            <tr key={truckRecap.id}>
              <td>{truckRecap.Provider.name}</td>
              <td>{truckRecap.type}</td>
              <td>{truckRecap.registrationNumber}</td>
              <td>{truckRecap.deliverySlipCount}</td>
              <td className={"cell-fit"}>
                <Link
                  className={"btn-1"}
                  params={{ truckId: truckRecap.id }}
                  to={TRUCKS_DETAILS}
                >
                  Voir +
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Routes>
        <Route
          element={
            <Dialog onClose={() => navigate(".")}>
              <Outlet />
            </Dialog>
          }
        >
          <Route element={<TruckPanel />} path={TRUCKS_DETAILS} />
        </Route>
      </Routes>
    </div>
  );
};

const useTrucksLoad = () => {
  const { loadTrucksRecap } = useProject();

  return loadTrucksRecap;
};

export default withLoader(Trucks, useTrucksLoad);
