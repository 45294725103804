import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";

interface NumberFormatAPI {
  toLocale(number: number): string;
  toPrice(number: number): string;
}

export function useNumberFormat(): NumberFormatAPI {
  const { i18n } = useTranslation();

  const toLocaleFactory = useMemo(() => new Intl.NumberFormat(i18n.language), [
    i18n.language,
  ]);

  const toLocale: NumberFormatAPI["toLocale"] = useCallback(
    (number) => toLocaleFactory.format(number),
    [toLocaleFactory],
  );

  const toPriceFactory = useMemo(
    () =>
      new Intl.NumberFormat(i18n.language, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }),
    [i18n.language],
  );

  const toPrice: NumberFormatAPI["toPrice"] = useCallback(
    (number) => toPriceFactory.format(number),
    [toPriceFactory],
  );

  return {
    toLocale,
    toPrice,
  };
}
