import {
  DepartmentForm as DepartmentFormType,
  DepartmentFormSchema,
} from "../department";
import AutoField from "../../forms/AutoField";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { ActionBar } from "../../design-system/base-layout";
import SubmitButton from "../../forms/SubmitButton";
import Form from "../../forms/Form";

const DepartmentForm = ({
  onSubmit,
  context,
  initialValues,
}: {
  onSubmit: (department: DepartmentFormType) => Promise<unknown>;
  context?: { usedInProject: boolean };
  initialValues?: DepartmentFormType;
}): JSX.Element => {
  return (
    <Form
      initialValues={initialValues ? initialValues : {}}
      onSubmit={(department: DepartmentFormType) => onSubmit(department)}
      schema={DepartmentFormSchema}
      schemaContext={context}
    >
      <AutoField name={"name"} />
      <AutoField name={"externalId"} />
      <AutoField name={"address"} />
      <AutoField name={"postalCode"} />
      <AutoField name={"city"} />
      <AutoField name={"country"} />
      <AutoField name={"landlineNumber"} />
      <AutoField name={"email"} />
      <AutoField name={"storageCapacity"} unit={"T"} />
      <AutoField name={"productionCapacity"} unit={"T/h"} />

      <ValidationsErrors />

      <ActionBar>
        <SubmitButton>Enregistrer</SubmitButton>
      </ActionBar>
    </Form>
  );
};

export default DepartmentForm;
