import { useYupField } from "./Form";
import { AnySchema, BaseSchema } from "yup";
import { Namespace, TFuncKey, useTranslation } from "react-i18next";
import { getNumericEnumEntries } from "../data-structures/enum";
import FRadio from "./FRadio";
import List from "../../services/ui/elements/List";

const FRadioGroup = ({
  name,
  disabled,
  className,
}: {
  name: string;
  disabled?: boolean;
  className?: string;
}): JSX.Element => {
  const fieldSchema = useYupField(name) as AnySchema;

  const { enum: enumList, translate } = fieldSchema.meta() as NonNullable<
    BaseSchema["metaInterface"]
  >;
  const { t } = useTranslation(translate ? ([translate[0]] as Namespace) : []);

  return (
    <List>
      {Array.isArray(enumList)
        ? enumList
            .filter((value): value is number => value !== null)
            .map((value) => (
              <li key={value} className={"list-item"}>
                <label>
                  <FRadio name={name} value={value} />{" "}
                  {translate
                    ? t(translate[1] as TFuncKey, { count: value })
                    : value}
                </label>
              </li>
            ))
        : getNumericEnumEntries(enumList).map(([id, name]) => (
            <li key={id}>
              <label>
                <FRadio name={name} value={id} />{" "}
                {t(
                  `${
                    (translate as NonNullable<typeof translate>)[1]
                  }.${name}` as TFuncKey,
                )}
              </label>
            </li>
          ))}
    </List>
  );
};

export default FRadioGroup;
