import { useToastsWithIntl } from "../../../../../services/toast-notifications";
import { useNavigate } from "react-router-dom";
import { ViewName } from "../../../../../services/design-system/base-layout";
import Form from "../../../../../services/forms/Form";
import ValidationsErrors from "../../../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../../../services/forms/SubmitButton";
import {
  ProjectAPILoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import { OrderForm, POFormSchema } from "../../../../../services/project/order";
import AutoField from "../../../../../services/forms/AutoField";
import SelectDepartmentField from "../../../../../services/department/components/SelectDepartmentField";

const New = (): JSX.Element => {
  const { createOrder } = useProject() as ProjectAPILoaded;
  const { toastError, toastSuccess } = useToastsWithIntl(["project"]);
  const navigate = useNavigate();

  return (
    <div>
      <ViewName name={"Nouveau bon de commande"} />

      <Form
        initialValues={{}}
        onSubmit={(order: OrderForm) => {
          return createOrder(order).then(
            () => {
              toastSuccess("project:create-order.SUCCESS");
              navigate("..");
            },
            () => {
              toastError("project:create-order.ERROR");
            },
          );
        }}
        schema={POFormSchema}
      >
        <SelectDepartmentField name={"DepartmentId"} />

        <AutoField name={"orderNum"} />

        <ValidationsErrors />

        <SubmitButton>Enregistrer</SubmitButton>
      </Form>
    </div>
  );
};

export default New;
