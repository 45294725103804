import { useYupField } from "./Form";
import { AnySchema } from "yup";
import { ReactElement, useMemo } from "react";
import TextField from "./TextField";
import EmailField from "./EmailField";
import PasswordField from "./PasswordField";
import NumberField from "./NumberField";
import DateField from "./DateField";
import TextareaField from "./TextareaField";
import FSelect from "./FSelect";
import FRadioGroup from "./FRadioGroup";
import { Field } from "formik";

interface Props {
  name: string;
  placeholder?: string;
  className?: string;
  id?: string;
  unit?: string;
  children?: ReactElement;
}

enum FieldType {
  String,
  Textarea,
  Number,
  Select,
  Email,
  Password,
  Date,
  DateTime,
  Radio,
  Checkbox,
}

function getFieldType(fieldSchema: AnySchema): FieldType {
  if (fieldSchema.tests.find((t) => t.OPTIONS.name === "email")) {
    return FieldType.Email;
  }
  if (fieldSchema.meta()?.password) {
    return FieldType.Password;
  }
  if (fieldSchema.meta()?.multiline) {
    return FieldType.Textarea;
  }
  if (fieldSchema.meta()?.type === "dropdown") {
    return FieldType.Select;
  }
  if (fieldSchema.meta()?.type === "radio") {
    return FieldType.Radio;
  }
  if (fieldSchema.type === "number") {
    return FieldType.Number;
  }
  if (fieldSchema.type === "date") {
    return fieldSchema.meta()?.time ? FieldType.DateTime : FieldType.Date;
  }
  if (fieldSchema.type === "boolean") {
    return FieldType.Checkbox;
  }
  return FieldType.String;
}

function getMin(fieldSchema: AnySchema) {
  return fieldSchema.tests.find((t) => t.OPTIONS.name === "min")?.OPTIONS
    .params!.min;
}

function getMax(fieldSchema: AnySchema) {
  return fieldSchema.tests.find((t) => t.OPTIONS.name === "max")?.OPTIONS
    .params!.max;
}

const AutoField = ({
  name,
  placeholder,
  className,
  id,
  unit,
  children,
}: Props): JSX.Element | null => {
  const fieldSchema = useYupField(name) as AnySchema;

  const isRequired = useMemo(
    () => !!fieldSchema.tests.find((t) => t.OPTIONS.name === "required"),
    [fieldSchema],
  );

  const fieldType = useMemo(() => getFieldType(fieldSchema), [fieldSchema]);

  const meta = useMemo(() => fieldSchema.meta(), [fieldSchema]);
  const isDisabled = useMemo(() => meta?.disabled, [meta]);

  const min = useMemo(() => getMin(fieldSchema), [fieldSchema]);
  const max = useMemo(() => getMax(fieldSchema), [fieldSchema]);

  if (meta?.notVisible) return null;

  if (fieldType === FieldType.Radio)
    return (
      <fieldset className={"form-block"}>
        <legend className={"input-label"}>
          {meta?.label || fieldSchema.spec.label} {unit && ` (${unit})`}
          {isRequired && "*"}
        </legend>

        <FRadioGroup className={className} disabled={isDisabled} name={name} />
      </fieldset>
    );
  if (fieldType === FieldType.Checkbox)
    return (
      <div className={"form-block"}>
        <Field
          className={className}
          disabled={isDisabled}
          id={id || name}
          name={name}
          type={"checkbox"}
        />
        <label className={"input-label"} htmlFor={id || name}>
          {meta?.label || fieldSchema.spec.label} {unit && ` (${unit})`}
          {isRequired && "*"}
        </label>
      </div>
    );

  return (
    <div className={"form-block"}>
      <label className={"input-label"} htmlFor={id || name}>
        {meta?.label || fieldSchema.spec.label} {unit && ` (${unit})`}
        {isRequired && "*"}
      </label>
      {fieldType === FieldType.Email && (
        <EmailField
          className={className}
          disabled={isDisabled}
          id={id || name}
          name={name}
          placeholder={placeholder}
        />
      )}
      {fieldType === FieldType.Password && (
        <PasswordField
          className={className}
          disabled={isDisabled}
          id={id || name}
          name={name}
          placeholder={placeholder}
        />
      )}
      {fieldType === FieldType.String && (
        <TextField
          className={className}
          disabled={isDisabled}
          id={id || name}
          name={name}
          placeholder={placeholder}
        />
      )}
      {fieldType === FieldType.Textarea && (
        <TextareaField
          className={className}
          disabled={isDisabled}
          id={id || name}
          name={name}
          placeholder={placeholder}
        />
      )}
      {fieldType === FieldType.Number && (
        <NumberField
          className={className}
          disabled={isDisabled}
          id={id || name}
          name={name}
          placeholder={placeholder}
        />
      )}
      {(fieldType === FieldType.Date || fieldType === FieldType.DateTime) && (
        <DateField
          className={className}
          disabled={isDisabled}
          id={id || name}
          maxDate={max as Date | undefined}
          minDate={min as Date | undefined}
          name={name}
          placeholderText={placeholder}
          showTimeSelect={fieldType === FieldType.DateTime}
        />
      )}
      {fieldType === FieldType.Select && (
        <FSelect
          className={className}
          disabled={isDisabled}
          id={id || name}
          name={name}
          placeholder={placeholder}
        />
      )}
      {children}
    </div>
  );
};

export default AutoField;
