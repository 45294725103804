import { number, object, string } from "yup";

interface MaterialBase {
  name: string;
  density: number;
}

export interface Material extends MaterialBase {
  id: number;
}

export interface FullMaterial extends Material {
  usedInProject: boolean;
}

export interface MaterialForm extends MaterialBase {}

export const MaterialFormSchema = object()
  .shape({
    name: string().label("Nom du matériau").nullable().required(),
    density: number().label("Densité").nullable().required().min(0),
  })
  .when("$usedInProject", {
    is: true,
    then: (schema) => schema.notEditable(),
  });
