import { withLoader } from "../../../services/routing/useLoader";
import { useCompany } from "../../../services/company/apiProvider";
import { useCallback, useMemo } from "react";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import {
  Content,
  Head,
  Tabs,
  ViewName,
  withProvideHead,
} from "../../../services/design-system/base-layout";
import Routes from "../../../services/routing/Routes";
import { Route, Outlet } from "react-router-dom";
import Index from "./client/Index";
import Users from "./client/Users";
import {
  CLIENT_LINK,
  INVITE_USERS,
  INVITE_USERS_LINK,
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_LINK,
  USERS,
  USERS_LINK,
} from "../../../routes/admin";
import PageContent from "../../../services/ui/elements/PageContent";
import Subscriptions from "./client/Subscriptions";
import InviteUsers from "./client/InviteUsers";

const Client = (): JSX.Element => {
  const tabs = useMemo(
    () => [
      {
        name: "Infos client",
        linkProps: {
          to: CLIENT_LINK,
          end: true,
        },
      },
      {
        name: "Utilisateurs",
        linkProps: {
          to: USERS_LINK,
        },
      },
      {
        name: "Abonnements",
        linkProps: {
          to: SUBSCRIPTIONS_LINK,
        },
      },
      {
        name: "Inviter un utilisateur",
        linkProps: {
          to: INVITE_USERS_LINK,
          className: "tab-item-special",
        },
      },
    ],
    [],
  );

  return (
    <>
      <ViewName name={"Client"} />
      <Head />
      <Tabs tabs={tabs} />

      <Content>
        <Routes>
          <Route
            element={
              <PageContent>
                <Outlet />
              </PageContent>
            }
          >
            <Route element={<Index />} index />
            <Route element={<Users />} path={USERS} />
            <Route element={<Subscriptions />} path={`${SUBSCRIPTIONS}/*`} />
            <Route element={<InviteUsers />} path={INVITE_USERS} />
          </Route>
        </Routes>
      </Content>
    </>
  );
};

const useLoad = () => {
  const {
    user: { AdministratedCompanyId },
  } = useAuth() as AuthAPIConnected;
  const { loadCompanyById, loadAdministratorsByCompanyId } = useCompany();

  return useCallback(
    () =>
      Promise.all([
        loadCompanyById(
          AdministratedCompanyId as NonNullable<typeof AdministratedCompanyId>,
        ),
        loadAdministratorsByCompanyId(
          AdministratedCompanyId as NonNullable<typeof AdministratedCompanyId>,
        ),
      ]),
    [AdministratedCompanyId, loadCompanyById, loadAdministratorsByCompanyId],
  );
};

export default withProvideHead(withLoader(Client, useLoad));
