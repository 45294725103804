import Form from "../../../../services/forms/Form";
import { number, object } from "yup";
import AutoField from "../../../../services/forms/AutoField";
import SubmitButton from "../../../../services/forms/SubmitButton";
import ValidationsErrors from "../../../../services/forms/ValidationsErrors";
import {
  ProjectAPILoaded,
  useProject,
} from "../../../../services/project/apiProvider";
import { useMemo, useState } from "react";
import { WrapUpForm } from "../../../../services/project/wrap-up";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import { getEstimatedTonnage } from "../../../../services/project/project";
import {
  ActionBar,
  ViewName,
} from "../../../../services/design-system/base-layout";
import Section from "../../../../services/ui/elements/Section";
import { PartialNullable } from "src/services/types/utility";

const WrapUpSchema = object()
  .shape({
    remainingSurface: number()
      .label("Surface restante à réaliser")
      .nullable()
      .required(),
    thickness: number()
      .label("Épaisseur")
      .nullable()
      .required()
      .maxFloatingPoints(),
    density: number().label("Densité matériau").nullable().required(),
  })
  .defined();

const WrapUpCalculatedFields = ({
  totalDelivered,
  totalOrdered,
  estimatedTonnage,
  submitedWrapUp,
}: {
  totalDelivered: number;
  totalOrdered: number;
  estimatedTonnage: number;
  submitedWrapUp: WrapUpForm | null;
}): JSX.Element => {
  if (submitedWrapUp === null) {
    return (
      <Section>
        <div className={"info info-m info-secondary"}>
          <p>Veuillez calculer pour obtenir les résultats du bouclage</p>
        </div>
      </Section>
    );
  }

  const remainingTonnage =
    ((submitedWrapUp.remainingSurface * submitedWrapUp.thickness) / 100) *
    submitedWrapUp.density;
  const adjustedTonnage = remainingTonnage + totalDelivered;
  const tonnageToOrder = adjustedTonnage - totalOrdered;
  const difference = adjustedTonnage - estimatedTonnage;
  const thicknessNeeded =
    submitedWrapUp.thickness -
    difference /
      ((submitedWrapUp.remainingSurface * submitedWrapUp.density) / 100);

  return (
    <Section>
      <div className={"info info-m info-secondary"}>
        <p>Tonnage restant à réaliser : {remainingTonnage.toFixed(2)}T</p>
        <p>
          Tonnage prévisionnel global recalé : {adjustedTonnage.toFixed(2)}T
        </p>
        <p>Tonnage complémentaire à commander : {tonnageToOrder.toFixed(2)}T</p>
        <p>
          Écart entre Tonnage prévisionnel et Tonnage recalé à l'avancement du
          chantier : {difference.toFixed(2)}T
        </p>
        <p>
          Épaisseur à prévoir pour atteindre le tonnage initial :{" "}
          {thicknessNeeded.toFixed(3)}cm
        </p>
      </div>
    </Section>
  );
};

const WrapUp = (): JSX.Element => {
  const { project, createWrapUp } = useProject() as ProjectAPILoaded;
  const { toastSuccess, toastError } = useToastsWithIntl(["project"]);

  const totalOrdered = useMemo(
    () => project.Orders.reduce((acc, o) => acc + o.tonnage, 0),
    [project],
  );

  const totalDelivered = useMemo(
    () =>
      project.DeliverySlips.reduce(
        (acc, ds) => acc + (ds.validationDate ? ds.tonnageDelivered : 0),
        0,
      ),
    [project],
  );
  const estimatedTonnage = useMemo(() => getEstimatedTonnage(project), [
    project,
  ]);

  const [submitedWrapUp, setSubmitedWrapUp] = useState<WrapUpForm | null>(null);

  return (
    <div>
      <ViewName name={"Bouclage"} />
      <Form<PartialNullable<WrapUpForm>>
        initialValues={{
          remainingSurface: null,
          thickness: project.thickness,
          density: project.density,
        }}
        onSubmit={(wrapUpForm: WrapUpForm) =>
          createWrapUp(wrapUpForm).then(
            () => {
              setSubmitedWrapUp(wrapUpForm);
              toastSuccess("project:create-wrap-up.SUCCESS");
            },
            () => toastError("project:create-wrap-up.ERROR"),
          )
        }
        schema={WrapUpSchema}
        resetFormOnSubmit
      >
        <AutoField name={"remainingSurface"} unit={"m²"} />
        <AutoField name={"thickness"} unit={"cm"} />

        <AutoField name={"density"} unit={"T/m3"} />

        <WrapUpCalculatedFields
          estimatedTonnage={estimatedTonnage}
          submitedWrapUp={submitedWrapUp}
          totalDelivered={totalDelivered}
          totalOrdered={totalOrdered}
        />

        <ValidationsErrors />

        <ActionBar>
          <SubmitButton>Calculer</SubmitButton>
        </ActionBar>
      </Form>
    </div>
  );
};

export default WrapUp;
