import {
  ActionBar,
  ViewName,
} from "../../../../services/design-system/base-layout";
import Form from "../../../../services/forms/Form";
import AutoField from "../../../../services/forms/AutoField";
import ValidationsErrors from "../../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../../services/forms/SubmitButton";
import {
  MaterialForm,
  MaterialFormSchema,
} from "../../../../services/material/material";
import { useCompany } from "../../../../services/company/apiProvider";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import { useNavigate } from "react-router-dom";
import { MATERIALS_LINK } from "../../../../routes/user";
import PageContent from "../../../../services/ui/elements/PageContent";

const New = (): JSX.Element => {
  const { createMaterial } = useCompany();
  const { toastError, toastSuccess } = useToastsWithIntl(["material"]);
  const navigate = useNavigate();

  return (
    <PageContent>
      <ViewName name={"Nouveau matériau"} />

      <Form
        initialValues={{}}
        onSubmit={(material: MaterialForm) => {
          return createMaterial(material).then(
            () => {
              toastSuccess("material:create-material.SUCCESS");
              navigate(MATERIALS_LINK);
            },
            () => {
              toastError("material:create-material.ERROR");
            },
          );
        }}
        schema={MaterialFormSchema}
      >
        <AutoField name={"name"} />
        <AutoField name={"density"} unit={"T/m3"} />

        <ValidationsErrors />

        <ActionBar>
          <SubmitButton>Enregistrer</SubmitButton>
        </ActionBar>
      </Form>
    </PageContent>
  );
};

export default New;
