import { CLIENTS_LINK } from "../super-admin";

export const CLIENT = ":clientId" as const;
export const CLIENT_LINK = `${CLIENTS_LINK}/${CLIENT}` as const;

export const USERS = "users" as const;
export const USERS_LINK = `${CLIENT_LINK}/${USERS}` as const;

export const SUBSCRIPTIONS = "subscriptions" as const;
export const SUBSCRIPTIONS_LINK = `${CLIENT_LINK}/${SUBSCRIPTIONS}` as const;
