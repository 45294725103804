import { addMethod, boolean, date, mixed, number, string } from "yup";
import { getNumericEnumEntries } from "../data-structures/enum";
import { Namespace, TFuncKey } from "react-i18next";
import { ReactNode } from "react";

addMethod(string, "password", function () {
  return this.required().meta({ password: true });
});

addMethod(date, "time", function () {
  return this.meta({ time: true });
});

addMethod(mixed, "disabled", function () {
  return this.test("disabled", () => true);
});

addMethod(mixed, "notEditable", function () {
  return this.nullable().optional().meta({ disabled: true });
});

addMethod(mixed, "notVisible", function () {
  return this.nullable()
    .optional()
    .meta({ notVisible: true })
    .transform(() => null);
});

addMethod(string, "multiline", function () {
  return this.meta({ multiline: true });
});

addMethod(number, "maxFloatingPoints", function (max: number = 2) {
  return this.test(
    "maxFloatingPoints",
    {
      key: "max_floating_point",
      values: { label: this.spec.label, count: max },
    },
    (number) => {
      const maxVerif = new RegExp(`^\\d+(\\.\\d{1,${max}})?$`);
      return maxVerif.test(String(number));
    },
  );
});

addMethod(
  mixed,
  "oneOfEnum",
  function (
    values: Record<string | number | symbol, unknown> | Array<unknown>,
    namespace?: Namespace,
    baseKey?: TFuncKey<Namespace>,
    nullValue?: boolean,
    type: "dropdown" | "radio" = "dropdown",
  ) {
    if (Array.isArray(values)) {
      return this.oneOf(values).meta({
        translate: namespace ? [namespace, baseKey] : undefined,
        type,
        enum: values,
      });
    } else {
      const numericEntries = getNumericEnumEntries(values);
      return this.clone({ nullable: nullValue ? true : this.spec.nullable })
        .oneOf(
          (numericEntries as (number | null)[][])
            .map(([id]) => id)
            .concat(nullValue ? [null] : []),
        )
        .meta({
          translate: [namespace, baseKey],
          enum: values,
          type,
        });
    }
  },
);

addMethod(boolean, "accept", function (label: string | ReactNode) {
  return this.required().oneOf([true]).meta({
    label,
  });
});
