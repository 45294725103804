import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  LoggedUser,
  User,
  UserToRegister,
  UserToLogin,
  UserToUpdateForm,
  UserPasswordForm,
} from "./user";
import baseAPI, {
  deleteLocalUser,
  getLocalUser,
  getUserById,
  isSetLocalUser,
  login as apiLogin,
  logout as apiLogout,
  lostPassword as apiLostPassword,
  register as apiRegister,
  resetPassword as apiResetPassword,
  setLocalUser,
  updateUserById,
  updateUserPasswordById,
  validateUserRegistration as apiValidateUserRegistration,
} from "./api";
import { AxiosError, AxiosPromise } from "axios";
import { useNavigate } from "react-router-dom";
import { LOGIN_LINK } from "../../routes/public";
import { useToastsWithIntl } from "../toast-notifications";

const defaultUser = isSetLocalUser() ? getLocalUser() : null;

export interface AuthAPI {
  user: LoggedUser | null;

  login(user: UserToLogin): Promise<void>;

  logout(): Promise<void>;

  checkUserValidity(): Promise<void>;

  register(user: UserToRegister): AxiosPromise<void>;

  validateUserRegistration(guid: string): AxiosPromise<void>;

  updateUser(newUser: UserToUpdateForm): Promise<void>;
  updateUserPassword(form: UserPasswordForm): AxiosPromise<void>;

  lostPassword(email: User["email"]): AxiosPromise<void>;

  resetPassword(
    guid: string,
    password: UserToLogin["password"],
  ): AxiosPromise<void>;
}

export interface AuthAPIConnected extends AuthAPI {
  user: LoggedUser;
}

/**
 * Default value should never be used
 */
export const AuthContext = createContext<AuthAPI | null>(null);

export function useProvideAuth(): AuthAPI {
  const [user, setUser] = useState<LoggedUser | null>(defaultUser);
  const navigate = useNavigate();
  const { toastError } = useToastsWithIntl(["auth"]);

  useMemo(() => {
    if (user !== null) {
      setLocalUser(user);
      baseAPI.defaults.headers["Authorization"] = `Bearer ${user.xsrfToken}`;
    } else {
      deleteLocalUser();
      delete baseAPI.defaults.headers["Authorization"];
    }
  }, [user]);

  useEffect(() => {
    const interceptor = baseAPI.interceptors.response.use(
      (res) => res,
      (error: AxiosError) => {
        if (error?.response) {
          if (error.response.status === 401) {
            setUser(null);
          } else if (
            error.response.status === 412 &&
            error.response.data === "Not enough activity"
          ) {
            setUser(null);
            toastError("auth:not-enough-activity");
          }
        }
        return Promise.reject(error);
      },
    );

    return () => {
      baseAPI.interceptors.response.eject(interceptor);
    };
  }, [toastError]);

  const login: AuthAPI["login"] = useCallback((u: UserToLogin) => {
    return apiLogin(u).then((res) => {
      setUser(res.data.user);
    });
  }, []);

  const logout: AuthAPI["logout"] = useCallback(() => {
    if (user !== null) {
      setUser(null);
      navigate(LOGIN_LINK);
    }
    return apiLogout().then(() => {
      return Promise.resolve();
    });
  }, [user, navigate]);

  const checkUserValidity: AuthAPI["checkUserValidity"] = useCallback(() => {
    if (isSetLocalUser()) {
      const u = getLocalUser() as LoggedUser;
      return getUserById(u.id).then(
        (res) => {
          const newUser = { ...u, ...res.data };
          setUser(newUser);
          setLocalUser(newUser);
        },
        (err) => {
          if (err?.response?.status === 401) {
            setUser(null);
            deleteLocalUser();
          }
        },
      );
    }
    return Promise.resolve();
  }, []);

  const register: AuthAPI["register"] = useCallback((user) => {
    return apiRegister(user);
  }, []);

  const validateUserRegistration: AuthAPI["validateUserRegistration"] = useCallback(
    (guid) => {
      return apiValidateUserRegistration(guid);
    },
    [],
  );

  const updateUser: AuthAPI["updateUser"] = useCallback((newUser) => {
    return updateUserById(newUser).then(() => {
      setUser((prevUser) =>
        prevUser
          ? {
              ...prevUser,
              ...newUser,
            }
          : null,
      );
    });
  }, []);

  const updateUserPassword: AuthAPI["updateUserPassword"] = useCallback(
    (form) =>
      updateUserPasswordById((user as NonNullable<typeof user>).id, form),
    [user],
  );

  const lostPassword: AuthAPI["lostPassword"] = useCallback((email) => {
    return apiLostPassword(email);
  }, []);

  const resetPassword: AuthAPI["resetPassword"] = useCallback(
    (guid, password) => {
      return apiResetPassword(guid, password);
    },
    [],
  );

  return {
    user,
    login,
    logout,
    checkUserValidity,
    register,
    validateUserRegistration,
    updateUser,
    updateUserPassword,
    lostPassword,
    resetPassword,
  };
}

export function useAuth(): AuthAPI {
  return useContext(AuthContext) as AuthAPI;
}

export const ProvideAuth = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const auth = useProvideAuth();

  useEffect(() => {
    auth.checkUserValidity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
