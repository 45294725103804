import { Department, DepartmentForm, FullDepartment } from "./department";
import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";

export function getDepartmentById(
  departmentId: Department["id"],
): AxiosPromise<FullDepartment> {
  return baseAPI.get(`/departments/${departmentId}`);
}

export function updateDepartment(
  departmentId: Department["id"],
  department: DepartmentForm,
): AxiosPromise<FullDepartment> {
  return baseAPI.put(`/departments/${departmentId}`, department);
}

export function deleteDepartment(
  departmentId: Department["id"],
): AxiosPromise<void> {
  return baseAPI.delete(`/departments/${departmentId}`);
}
