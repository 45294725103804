import { FullTruck, Truck, TruckForm } from "./truck";
import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";

export function getTruckById(truckId: Truck["id"]): AxiosPromise<FullTruck> {
  return baseAPI.get(`/trucks/${truckId}`);
}

export function updateTruck(
  truckId: Truck["id"],
  truck: TruckForm,
): AxiosPromise<FullTruck> {
  return baseAPI.put(`/trucks/${truckId}`, truck);
}

export function deleteTruck(truckId: Truck["id"]): AxiosPromise<void> {
  return baseAPI.delete(`/trucks/${truckId}`);
}

export function addTruckToFavorites(truckId: Truck["id"]): AxiosPromise<void> {
  return baseAPI.post(`/trucks/${truckId}/favorite`);
}

export function removeTruckFromFavorites(
  truckId: Truck["id"],
): AxiosPromise<void> {
  return baseAPI.delete(`/trucks/${truckId}/favorite`);
}

export function resetUserTruckFavorites(): AxiosPromise<void> {
  return baseAPI.delete(`/trucks/favorites`);
}

export function resetUserTruckIdentificationsNumbers(): AxiosPromise<void> {
  return baseAPI.delete(`/trucks/identifications-numbers`);
}
