import { Truck } from "../truck/truck";
import { formatISO, parseISO } from "date-fns";
import { Department } from "../department/department";
import { date, number, object, string } from "yup";
import { Provider } from "../provider/provider";

export interface DeliverySlipBase {
  deliverySlipDepartmentNumber: string | null;
  tonnageDelivered: number;
}

export interface DeliverySlipRaw extends DeliverySlipBase {
  id: number;
  loadingDate: string | null;
  deliveryDate: string | null;
  validationDate: string | null;
}

export interface DeliverySlip extends DeliverySlipBase {
  id: number;
  loadingDate: Date | null;
  deliveryDate: Date | null;
  validationDate: Date | null;
}

export interface FullDeliverySlipRaw extends DeliverySlipRaw {
  Truck: Truck & { Provider: Provider };
  Department: Department;
}

export interface FullDeliverySlip extends DeliverySlip {
  Truck: Truck & { Provider: Provider };
  Department: Department;
  material: string;
}

export interface DeliverySlipFormRaw extends DeliverySlipBase {
  loadingDate: string | null;
  deliveryDate: string | null;
}

export interface DeliverySlipForm extends DeliverySlipBase {
  loadingDate: Date | null;
  deliveryDate: Date | null;
  ProviderId: Provider["id"];
  TruckId: Truck["id"];
  DepartmentId: Department["id"];
}

export function mapDeliverySlipRawToDeliverySlip(
  deliverySlipRaw: FullDeliverySlipRaw,
): FullDeliverySlip;
export function mapDeliverySlipRawToDeliverySlip(
  deliverySlipRaw: DeliverySlipRaw,
): DeliverySlip;
export function mapDeliverySlipRawToDeliverySlip(
  deliverySlipRaw: DeliverySlipRaw | FullDeliverySlipRaw,
): DeliverySlip | FullDeliverySlip {
  return {
    ...deliverySlipRaw,
    loadingDate:
      deliverySlipRaw.loadingDate !== null
        ? parseISO(deliverySlipRaw.loadingDate)
        : deliverySlipRaw.loadingDate,
    deliveryDate:
      deliverySlipRaw.deliveryDate !== null
        ? parseISO(deliverySlipRaw.deliveryDate)
        : null,
    validationDate:
      deliverySlipRaw.validationDate === null
        ? null
        : parseISO(deliverySlipRaw.validationDate),
  };
}

export function mapDeliverySlipFormToDeliverySlipFormRaw(
  deliverySlip: DeliverySlipForm,
): DeliverySlipFormRaw {
  return {
    ...deliverySlip,
    loadingDate: deliverySlip.loadingDate
      ? formatISO(deliverySlip.loadingDate)
      : deliverySlip.loadingDate,
    deliveryDate: deliverySlip.deliveryDate
      ? formatISO(deliverySlip.deliveryDate)
      : null,
  };
}

export const DeliverySlipSchema = object()
  .shape({
    DepartmentId: number().label("Centrale").nullable().required(),
    ProviderId: number().label("Transporteur").nullable(),
    TruckId: number().label("Camion").nullable(),
    tonnageDelivered: number().label("Tonnage livré").nullable().required(),
    deliveryDate: date()
      .label("Date de livraison")
      .time()
      .nullable()
      .required(),
    loadingDate: date().label("Date de chargement").time().nullable(),
    deliverySlipDepartmentNumber: string().label("N° BL Centrale").nullable(),
  })
  .defined();
