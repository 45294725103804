import React, {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { Department, DepartmentForm, FullDepartment } from "./department";
import {
  getDepartmentById,
  updateDepartment as apiUpdateDepartment,
  deleteDepartment as apiDeleteDepartment,
} from "./api";

export interface DepartmentAPI {
  department: FullDepartment | null;

  loadDepartmentById(departmentId: Department["id"]): Promise<void>;

  updateDepartment(department: DepartmentForm): Promise<void>;

  deleteDepartment(): Promise<void>;
}

export interface DepartmentAPILoaded extends DepartmentAPI {
  department: NonNullable<DepartmentAPI["department"]>;
}

export const DepartmentContext = createContext<DepartmentAPI | null>(null);

export function useDepartment(): DepartmentAPI {
  return useContext(DepartmentContext) as DepartmentAPI;
}

export const ProvideDepartment = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [department, setDepartment] = useState<DepartmentAPI["department"]>(
    null,
  );

  const loadDepartmentById: DepartmentAPI["loadDepartmentById"] = useCallback(
    (departmentId) => {
      return getDepartmentById(departmentId).then(({ data }) => {
        setDepartment(data);
      });
    },
    [],
  );

  const updateDepartment: DepartmentAPI["updateDepartment"] = useCallback(
    (newDepartment) => {
      return apiUpdateDepartment(
        (department as NonNullable<typeof department>).id,
        newDepartment,
      ).then(({ data }) => {
        setDepartment(data);
      });
    },
    [department],
  );

  const deleteDepartment: DepartmentAPI["deleteDepartment"] = useCallback(() => {
    return apiDeleteDepartment(
      (department as NonNullable<typeof department>).id,
    ).then(() => {
      setDepartment(null);
    });
  }, [department]);

  return (
    <DepartmentContext.Provider
      value={{
        department,
        loadDepartmentById,
        updateDepartment,
        deleteDepartment,
      }}
    >
      {children}
    </DepartmentContext.Provider>
  );
};

export function withProvideDepartment<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideDepartment(props: P) {
    return (
      <ProvideDepartment>
        <WrappedComponent {...props} />
      </ProvideDepartment>
    );
  }

  WithProvideDepartment.displayName = `withProvideDepartment(${displayName})`;

  return WithProvideDepartment;
}
