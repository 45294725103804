import { useMemo, useState } from "react";
import { useField } from "formik";
import Dialog, {
  DialogCloseAPI,
  useDialogClose,
} from "../../ui/elements/Dialog";
import { CompanyAPILoaded, useCompany } from "../../company/apiProvider";
import { useSearch } from "../../data-structures/array";
import InputSearch from "../../ui/elements/InputSearch";
import Button from "../../ui/elements/Button";
import SxSelect from "../../forms/SxSelect";
import { useYupField } from "../../forms/Form";
import { AnySchema } from "yup";
import useLoader from "../../routing/useLoader";
import DepartmentForm from "./DepartmentForm";
import { useToastsWithIntl } from "../../toast-notifications";
import CardBody from "../../ui/elements/CardBody";
import CardHead from "../../ui/elements/CardHead";
import Section from "../../ui/elements/Section";
import List from "../../ui/elements/List";
import Loading from "../../routing/components/Loading";
import LoaderErrors from "../../routing/components/LoaderErrors";
import { ProjectAPILoaded, useProject } from "src/services/project/apiProvider";
import { FullDepartment } from "../department";

const SelectDepartmentModal = ({
  name,
  projectScope,
}: {
  name: string;
  projectScope: boolean;
}): JSX.Element => {
  const [openedCreateModal, setOpenedCreateModal] = useState(false);
  const [displayAll, setDisplayAll] = useState(!projectScope);
  const [, , helpers] = useField(name);
  const onClose = useDialogClose() as NonNullable<DialogCloseAPI>;
  const { toastSuccess, toastError } = useToastsWithIntl(["department"]);

  const { departments, createDepartment } = useCompany() as CompanyAPILoaded;
  const { project } = useProject() as ProjectAPILoaded;
  const projectDepartments = project
    ? project.Orders.filter((order) => order.orderNum).map(
        (order) => order.Department as FullDepartment,
      )
    : [];

  const [searchedDepartments, setSearch] = useSearch(
    displayAll ? [...departments.values()] : projectDepartments,
  );

  return (
    <Dialog className={"theme-light"} panel>
      <CardHead title={"Sélectionner une centrale"} />

      <CardBody>
        <InputSearch
          onChange={(event) => setSearch(event.currentTarget.value)}
        />

        {!projectScope && (
          <Section size={"s"}>
            <Button
              onClick={() => setOpenedCreateModal(true)}
              type={"button"}
              link
            >
              + Nouvelle centrale
            </Button>
          </Section>
        )}

        {projectScope && (
          <Section size={"s"}>
            <Button
              onClick={() => {
                setDisplayAll(!displayAll);
              }}
              type={"button"}
              link
            >
              {displayAll ? "Voir les centrales projet" : "Voir toutes"}
            </Button>
          </Section>
        )}

        <Section>
          <List>
            {searchedDepartments.map((department) => (
              <Button
                key={department.id}
                className={"list-item"}
                onClick={() => {
                  helpers.setValue(department.id);
                  onClose();
                }}
                variant={2}
                block
                link
              >
                {department.name}
              </Button>
            ))}
          </List>
        </Section>
      </CardBody>

      {openedCreateModal && (
        <Dialog onClose={() => setOpenedCreateModal(false)}>
          <CardHead title={"Nouvelle centrale"} />
          <CardBody>
            <DepartmentForm
              onSubmit={(department) =>
                createDepartment(department).then(
                  () => {
                    toastSuccess("department:create-department.SUCCESS");
                    setOpenedCreateModal(false);
                  },
                  () => {
                    toastError("department:create-department.ERROR");
                  },
                )
              }
            />
          </CardBody>
        </Dialog>
      )}
    </Dialog>
  );
};

const useLoad = () => {
  const { loadDepartments } = useCompany();

  return loadDepartments;
};

const SelectDepartmentField = ({
  name,
  projectScope,
}: {
  name: string;
  projectScope?: boolean;
}): JSX.Element => {
  const { departments } = useCompany() as CompanyAPILoaded;

  const fieldSchema = useYupField(name) as AnySchema;

  const isRequired = useMemo(
    () => fieldSchema.tests.find((t) => t.OPTIONS.name === "required"),
    [fieldSchema],
  );

  const { error, reload, loading } = useLoader(useLoad());

  return (
    <div className={"form-block"}>
      <label htmlFor={name}>
        {fieldSchema.spec.label} {isRequired && "*"}
      </label>

      {loading ? (
        <Loading />
      ) : error ? (
        <LoaderErrors error={error} reload={reload} />
      ) : (
        <SxSelect<number>
          getElementName={(departmentId) =>
            departments.get(departmentId)?.name || ""
          }
          id={name}
          name={name}
          selectModal={
            <SelectDepartmentModal
              name={name}
              projectScope={projectScope || false}
            />
          }
        />
      )}
    </div>
  );
};

export default SelectDepartmentField;
