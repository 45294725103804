import {
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../../services/design-system/base-layout";
import Routes from "../../../../services/routing/Routes";
import { Outlet, Route } from "react-router-dom";
import New from "./New";
import {
  NEW_TRUCK,
  NEW_TRUCK_LINK,
  TRUCK,
  TRUCK_LINK,
} from "../../../../routes/user/trucks";
import Link from "../../../../services/routing/components/Link";
import Truck from "./Truck";
import {
  CompanyAPILoaded,
  useCompany,
} from "../../../../services/company/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import { useMemo, useState } from "react";
import { SortTrucks } from "../../../../services/truck/truck";
import { useTranslation } from "react-i18next";
import { getNumericEnumEntries } from "../../../../services/data-structures/enum";
import InputSearch from "../../../../services/ui/elements/InputSearch";
import {
  compareBoolean,
  compareString,
  compareStringNullable,
  useSearch,
} from "../../../../services/data-structures/array";
import Button from "../../../../services/ui/elements/Button";
import { useConfirmationWithIntl } from "../../../../services/ui/ConfirmationDialog";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import PageContent from "../../../../services/ui/elements/PageContent";
import Section from "../../../../services/ui/elements/Section";
import List from "../../../../services/ui/elements/List";
import CreateLink from "../../../../services/ui/elements/CreateLink";
import ButtonsRow from "../../../../services/ui/layouts/ButtonsRow";

const useLoad = () => {
  const { loadTrucks } = useCompany();

  return loadTrucks;
};

const Index = withLoader(() => {
  const {
    trucks,
    resetUserTruckFavorites,
    resetUserTruckIdentificationsNumbers,
    addTruckToFavorites,
    removeTruckFromFavorites,
  } = useCompany() as CompanyAPILoaded;

  const { t } = useTranslation(["truck"]);
  const { toastError, toastSuccess } = useToastsWithIntl(["truck"]);
  const { confirm } = useConfirmationWithIntl(["truck"]);

  const trucksList = useMemo(() => [...trucks.values()], [trucks]);
  const [searchedTrucks, setSearch] = useSearch(trucksList);

  const [sortedBy, setSortedBy] = useState(SortTrucks.BY_PROVIDER);
  const sortedTrucks = useMemo(() => {
    const sortedTrucks = [...searchedTrucks];

    switch (sortedBy) {
      case SortTrucks.BY_PROVIDER:
        sortedTrucks.sort((truckA, truckB) =>
          compareString(truckA.Provider.name, truckB.Provider.name),
        );
        break;
      case SortTrucks.BY_FAVORITES:
        sortedTrucks.sort((truckA, truckB) =>
          compareBoolean(truckA.isFavorite, truckB.isFavorite),
        );
        break;
      case SortTrucks.BY_IDENTIFICATION_NUMBER:
        sortedTrucks.sort((truckA, truckB) =>
          compareStringNullable(
            truckA.TruckUserData.identificationNumber,
            truckB.TruckUserData.identificationNumber,
          ),
        );
        break;
    }

    return sortedTrucks;
  }, [searchedTrucks, sortedBy]);

  return (
    <>
      <Section size={"xs"}>
        <CreateLink to={NEW_TRUCK_LINK}>Nouveau camion</CreateLink>
      </Section>

      <Section size={"xs"}>
        <InputSearch
          onChange={(event) => setSearch(event.currentTarget.value)}
        />
      </Section>

      <Section size={"xs"}>
        <label className={"input-label"}>Tri par</label>
        <select
          className={"select fit-content"}
          onChange={(ev) => setSortedBy(Number(ev.target.value))}
          value={sortedBy}
        >
          {getNumericEnumEntries(SortTrucks).map(([value, key]) => (
            <option key={key} value={value}>
              {t(`truck:sort-trucks.${key}` as const)}
            </option>
          ))}
        </select>
      </Section>

      <Section>
        <Section size={"xs"}>
          <ButtonsRow>
            <Button
              onClick={() =>
                confirm(
                  "truck:reset-user-truck-favorites.CONFIRM",
                  () =>
                    resetUserTruckFavorites().then(
                      () => {
                        toastSuccess(
                          "truck:reset-user-truck-favorites.SUCCESS",
                        );
                      },
                      () => {
                        toastError("truck:reset-user-truck-favorites.ERROR");
                      },
                    ),
                  true,
                )
              }
              size={"s"}
            >
              Réinitialiser les favoris
            </Button>

            <Button
              onClick={() =>
                confirm(
                  "truck:reset-user-truck-identifications-numbers.CONFIRM",
                  () =>
                    resetUserTruckIdentificationsNumbers().then(
                      () => {
                        toastSuccess(
                          "truck:reset-user-truck-identifications-numbers.SUCCESS",
                        );
                      },
                      () => {
                        toastError(
                          "truck:reset-user-truck-identifications-numbers.ERROR",
                        );
                      },
                    ),
                  true,
                )
              }
              size={"s"}
              variant={2}
            >
              Réinitialiser les numéros
            </Button>
          </ButtonsRow>
        </Section>
        <List>
          {sortedTrucks.map((truck) => (
            <div key={truck.id} className={"list-item"}>
              <ButtonsRow>
                <Button
                  className={truck.isFavorite ? "btn-star-active" : ""}
                  onClick={() => {
                    if (truck.isFavorite) {
                      removeTruckFromFavorites(truck.id);
                    } else {
                      addTruckToFavorites(truck.id);
                    }
                  }}
                  size={"s"}
                  variant={1}
                >
                  {truck.isFavorite ? "★" : "☆"}
                </Button>

                {truck.TruckUserData?.identificationNumber && (
                  <div className={"chip"}>
                    {truck.TruckUserData?.identificationNumber}
                  </div>
                )}
              </ButtonsRow>

              <Section size={"s"}>
                <Link params={{ truckId: truck.id }} to={TRUCK_LINK}>
                  <div>{truck.Provider.name}</div>
                  <div>{truck.registrationNumber}</div>
                  <div>{truck.type}</div>
                  <div>{truck.phoneNumber}</div>
                </Link>
              </Section>
            </div>
          ))}
        </List>
      </Section>
    </>
  );
}, useLoad);

const Trucks = (): JSX.Element => {
  return (
    <>
      <ViewName name={"Camions"} />
      <Head />

      <Content>
        <Routes>
          <Route
            element={
              <PageContent>
                <Outlet />
              </PageContent>
            }
          >
            <Route element={<Index />} index />
            <Route element={<New />} path={NEW_TRUCK} />
            <Route element={<Truck />} path={TRUCK} />
          </Route>
        </Routes>
      </Content>
    </>
  );
};

export default withProvideHead(Trucks);
