import React, { FunctionComponent } from "react";
import Login from "./Login";
import {
  LOGIN,
  LOGIN_LINK,
  LOST_PASSWORD,
  LOST_PASSWORD_LINK,
  REGISTER,
  REGISTER_LINK,
  RESET_PASSWORD,
} from "../../routes/public";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import NavLink from "../../services/routing/components/NavLink";
import Link from "../../services/routing/components/Link";
import Register from "./Register";
import LostPassword from "./LostPassword";
import ResetPassword from "./ResetPassword";
import Routes from "src/services/routing/Routes";
import { Navigate, Route, Outlet } from "react-router-dom";
import {
  SiteLayout,
  Header,
  LayoutContent,
} from "../../services/design-system/base-layout";
import LogoRegulappro from "../../assets/img/logo-regulappro-white.png";
import ExternalLink from "src/services/ui/elements/ExternalLink";

const Public: FunctionComponent = () => {
  return (
    <SiteLayout>
      <Header>
        <Link to={LOGIN_LINK}>
          <img
            alt={"logo Regulappro"}
            className={"logo-header"}
            src={LogoRegulappro}
          />
        </Link>
      </Header>
      <Routes>
        <Route element={<Navigate to={LOGIN_LINK} />} index />
        <Route
          element={
            <LayoutContent className={"auth-layout-content"}>
              <Outlet />
            </LayoutContent>
          }
        >
          <Route element={<Login />} path={LOGIN} />
          <Route element={<Register />} path={REGISTER} />
          <Route element={<LostPassword />} path={LOST_PASSWORD} />
          <Route element={<ResetPassword />} path={RESET_PASSWORD} />
        </Route>
        <Route
          element={
            <LayoutContent>
              <Outlet />
            </LayoutContent>
          }
        ></Route>
      </Routes>
      <div className={"footer"}>
        <nav className={"nav"}>
          <NavLink className={"nav-item"} to={LOGIN_LINK}>
            Connexion
          </NavLink>
          <NavLink className={"nav-item"} to={REGISTER_LINK}>
            Inscription
          </NavLink>
          <NavLink className={"nav-item"} to={LOST_PASSWORD_LINK}>
            Mot de passe oublié
          </NavLink>
          <ExternalLink
            className={"nav-item"}
            href={"https://regulappro.fr/#societe"}
            target={"_blank"}
          >
            Mentions légales
          </ExternalLink>
        </nav>
      </div>
    </SiteLayout>
  );
};

export default withRedirectToPrivate(Public);
