import { withLoader } from "src/services/routing/useLoader";
import { useSuperAdmin } from "../../../services/super-admin/apiProvider";
import { useDate } from "../../../services/date/DateContext";
import { useSearch } from "../../../services/data-structures/array";
import InputSearch from "../../../services/ui/elements/InputSearch";
import {
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../services/design-system/base-layout";
import PageContent from "../../../services/ui/elements/PageContent";
import Table from "../../../services/ui/elements/Table";
import Section from "../../../services/ui/elements/Section";
import Button from "../../../services/ui/elements/Button";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import { useConfirmationWithIntl } from "../../../services/ui/ConfirmationDialog";

const Users = (): JSX.Element => {
  const { users, deleteUser } = useSuperAdmin();
  const { formatDate } = useDate();
  const { toastSuccess, toastError } = useToastsWithIntl(["auth"]);
  const { confirm } = useConfirmationWithIntl(["auth"]);

  const [searchedUsers, setSearch] = useSearch([...users.values()]);

  return (
    <>
      <ViewName name={"Liste des utilisateurs"} />
      <Head />

      <Content>
        <PageContent>
          <InputSearch
            onChange={(event) => setSearch(event.currentTarget.value)}
          />

          <Section>
            <Table>
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Prénom</th>
                  <th>Nom</th>
                  <th>E-mail</th>
                  <th>Tel</th>
                  <th>Date de création</th>
                  <th>Date dernière co.</th>
                  <th>Nb conn.</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {searchedUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.Company.name}</td>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>{user.phoneNumber}</td>
                    <td>{formatDate(user.createdAt)}</td>
                    <td>
                      {user.lastLogin !== null && formatDate(user.lastLogin)}
                    </td>
                    <td>{user.connectionNumber}</td>
                    <td>
                      {user.AdministratedCompany === null && !user.admin && (
                        <Button
                          onClick={() =>
                            confirm(
                              "auth:delete-user.CONFIRM",
                              () =>
                                deleteUser(user.id).then(
                                  () =>
                                    toastSuccess("auth:delete-user.SUCCESS"),
                                  () => toastError("auth:delete-user.ERROR"),
                                ),
                              true,
                            )
                          }
                        >
                          Supprimer
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Section>
        </PageContent>
      </Content>
    </>
  );
};

function useLoad() {
  const { loadUsers } = useSuperAdmin();

  return loadUsers;
}

export default withProvideHead(withLoader(Users, useLoad));
