import React, { FunctionComponent, useMemo } from "react";
import Link from "../../routing/components/Link";
import { LOST_PASSWORD_LINK, REGISTER_LINK } from "../../../routes/public";
import { object, string } from "yup";
import { UserToLogin } from "../user";
import { SUBMIT_BUTTON_ID } from "../../../fixtures/auth";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";
import Form from "../../forms/Form";
import AutoField from "../../forms/AutoField";
import { useTranslation } from "react-i18next";
import FormFooter from "../../forms/FormFooter";

interface Props {
  onSubmit: (values: UserToLogin) => Promise<unknown>;
  onCancel?: () => void;
}

const LoginForm: FunctionComponent<Props> = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation(["auth"]);
  const LoginSchema = useMemo(
    () =>
      object()
        .shape({
          email: string().label(t("auth:email")).required().email(),
          password: string().label(t("auth:password")).password(),
        })
        .defined(),
    [t],
  );

  return (
    <Form
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        return onSubmit(values);
      }}
      schema={LoginSchema}
    >
      <AutoField name={"email"} />

      <AutoField name={"password"} />

      <div className={"lost-password-cta"}>
        <Link to={LOST_PASSWORD_LINK}>Mot de passe oublié</Link>
      </div>

      <div>
        <ValidationsErrors />
      </div>

      <FormFooter>
        {onCancel && (
          <button onClick={onCancel} type={"button"}>
            Annuler
          </button>
        )}
        <SubmitButton data-testid={SUBMIT_BUTTON_ID} block>
          Connexion
        </SubmitButton>
        {!onCancel && (
          <div className={"register-cta"}>
            <Link to={REGISTER_LINK}>Inscription</Link>
          </div>
        )}
      </FormFooter>
    </Form>
  );
};

export default LoginForm;
