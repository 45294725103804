import { PROJECTS_LINK } from "../user";

export const PROJECT = ":projectId" as const;
export const PROJECT_LINK = `${PROJECTS_LINK}/${PROJECT}` as const;

export const DEPARTMENTS = "departments" as const;
export const DEPARTMENTS_LINK = `${PROJECT_LINK}/${DEPARTMENTS}` as const;

export const POS = "pos" as const;
export const POS_LINK = `${PROJECT_LINK}/${POS}` as const;

export const WRAP_UP = "wrap-up" as const;
export const WRAP_UP_LINK = `${PROJECT_LINK}/${WRAP_UP}` as const;

export const RECAP = "recap" as const;
export const RECAP_LINK = `${PROJECT_LINK}/${RECAP}` as const;

export const DELIVERY_SLIPS = "delivery-slips" as const;
export const DELIVERY_SLIPS_LINK = `${PROJECT_LINK}/${DELIVERY_SLIPS}` as const;

export const NEW = "new" as const;
export const NEW_LINK = `${PROJECTS_LINK}/${NEW}` as const;
