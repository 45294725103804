import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: "xs" | "s";
  indent?: boolean;
}

const Section: FunctionComponent<Props> = ({
  className,
  indent,
  size,
  ...sectionProps
}) => {
  return (
    <div
      {...sectionProps}
      className={cx(
        className,
        indent && "section-indent",
        size ? `section-${size}` : "section",
      )}
    />
  );
};

export default Section;
