import {
  Content,
  ViewName,
} from "../../../../../services/design-system/base-layout";
import Routes from "../../../../../services/routing/Routes";
import { Route, Outlet, useNavigate } from "react-router-dom";
import Link from "../../../../../services/routing/components/Link";
import {
  ProjectAPIDepartmentsRecapLoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import New from "./NewOrder";
import Section from "../../../../../services/ui/elements/Section";
import CreateLink from "../../../../../services/ui/elements/CreateLink";
import { compareDesc } from "date-fns";
import { WrapUp as IWrapUp } from "../../../../../services/project/wrap-up";
import { getEstimatedTonnage } from "src/services/project/project";
import { withLoader } from "src/services/routing/useLoader";
import React from "react";
import Table from "src/services/ui/elements/Table";
import Dialog from "src/services/ui/elements/Dialog";
import {
  DEPARTMENT_ORDERS,
  NEW_ORDER,
} from "src/routes/user/project/department";
import DepartmentOrdersPanel from "./DepartmentOrdersPanel";

const useLoad = () => {
  const { loadDepartmentsRecap } = useProject();

  return loadDepartmentsRecap;
};

const Index = withLoader(() => {
  const {
    project,
    departmentsRecap,
  } = useProject() as ProjectAPIDepartmentsRecapLoaded;

  const adjustedTonnage = getEstimatedTonnage(project);

  const lastWrapUp = project.WrapUps.sort((a, b) =>
    compareDesc(a.createdAt, b.createdAt),
  )[0] as IWrapUp | undefined;

  return (
    <div>
      <Section size={"xs"}>
        <CreateLink to={NEW_ORDER}>Nouvelle commande</CreateLink>
        <p className={"input-label"}>
          pour enregistrer une commande passée en centrale
        </p>
      </Section>

      <Section size={"xs"}>
        <div className={"info info-secondary info-m"}>
          Tonnage complémentaire à commander (T) suite au dernier bouclage :{" "}
          {lastWrapUp
            ? (
                lastWrapUp.adjustedTonnage -
                departmentsRecap.reduce(
                  (acc, dr) => acc + (dr.orderedTonnage || 0),
                  0,
                )
              ).toFixed(2)
            : (
                adjustedTonnage -
                departmentsRecap.reduce(
                  (acc, dr) => acc + (dr.orderedTonnage || 0),
                  0,
                )
              ).toFixed(2)}
        </div>
      </Section>

      <Section>
        <Table>
          <thead>
            <tr>
              <th>Centrale</th>
              <th>Tonnage cmdé en centrale</th>
              <th>Tonnage chargé & mis en oeuvre</th>
              <th>Tonnage chargé & en cours d'acheminement</th>
              <th>Tonnage cmdé & non chargé</th>
            </tr>
          </thead>
          <tbody>
            <tr className={"table-recap-row"}>
              <td>CUMUL</td>
              <td>
                {departmentsRecap.reduce(
                  (acc, dr) => acc + (dr.orderedTonnage || 0),
                  0,
                )}
              </td>
              <td>
                {departmentsRecap.reduce(
                  (acc, dr) => acc + (dr.deliveredTonnage || 0),
                  0,
                )}
              </td>
              <td>
                {departmentsRecap.reduce(
                  (acc, dr) => acc + (dr.toBeDeliveredTonnage || 0),
                  0,
                )}
              </td>
              <td>
                {departmentsRecap
                  .reduce(
                    (acc, dr) =>
                      acc +
                      ((dr.orderedTonnage || 0) -
                        (dr.deliveredTonnage || 0) -
                        (dr.toBeDeliveredTonnage || 0)),
                    0,
                  )
                  .toFixed(2)}
              </td>
            </tr>
            {departmentsRecap.map((dr) => (
              <tr key={dr.id}>
                <td>{dr.name}</td>
                <td>
                  <Link
                    className={"link-u"}
                    params={{ departmentId: dr.id }}
                    to={DEPARTMENT_ORDERS}
                  >
                    {dr.orderedTonnage}
                  </Link>
                </td>
                <td>{dr.deliveredTonnage || 0}</td>
                <td>{dr.toBeDeliveredTonnage || 0}</td>
                <td>
                  {(
                    (dr.orderedTonnage || 0) -
                    (dr.deliveredTonnage || 0) -
                    (dr.toBeDeliveredTonnage || 0)
                  ).toFixed(2)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </div>
  );
}, useLoad);

const Departments = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <div>
      <Content>
        <Routes>
          <Route
            element={
              <>
                <Index />
                <Outlet />
              </>
            }
          >
            <Route index />
            <Route
              element={
                <Dialog onClose={() => navigate(".")}>
                  <DepartmentOrdersPanel />
                </Dialog>
              }
              path={DEPARTMENT_ORDERS}
            />
          </Route>
          <Route
            element={
              <>
                <ViewName name={"Commandes"} />
                <Outlet />
              </>
            }
          >
            <Route element={<New />} path={NEW_ORDER} />
          </Route>
        </Routes>
      </Content>
    </div>
  );
};

export default Departments;
