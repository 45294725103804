import { withLoader } from "../../../services/routing/useLoader";
import { useSuperAdmin } from "../../../services/super-admin/apiProvider";
import {
  Content,
  Head,
  ViewName,
  withProvideHead,
} from "../../../services/design-system/base-layout";
import Routes from "../../../services/routing/Routes";
import { Outlet, Route } from "react-router-dom";
import Client from "./Client";
import Link from "../../../services/routing/components/Link";
import { CLIENT, CLIENT_LINK } from "../../../routes/super-admin/clients";
import React from "react";
import PageContent from "../../../services/ui/elements/PageContent";
import { useSearch } from "../../../services/data-structures/array";
import Section from "../../../services/ui/elements/Section";
import InputSearch from "../../../services/ui/elements/InputSearch";
import Table from "../../../services/ui/elements/Table";

const Index = () => {
  const { companies } = useSuperAdmin();

  const [searchedCompanies, setSearch] = useSearch(
    [...companies.values()],
    (c) => ({
      ...c,
      trialActive: c.trialActive ? "Active" : "Expirée",
    }),
  );

  return (
    <div>
      <Section size={"xs"}>
        <InputSearch onChange={(event) => setSearch(event.target.value)} />
      </Section>

      <Section>
        <Table>
          <thead>
            <tr>
              <th colSpan={3} />
              <th className={"center"} colSpan={5}>
                Abonnements
              </th>
            </tr>
            <tr>
              <th>Nom</th>
              <th>Groupe</th>
              <th>Ville</th>
              <th>Période d'essai</th>
              <th>Actifs</th>
              <th>Echéance</th>
              <th>Expirés</th>
              <th>à traiter</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {searchedCompanies.map((company) => (
              <tr key={company.id} className={"list-item"}>
                <td>{company.name}</td>
                <td>{company.group}</td>
                <td>{company.city}</td>
                <td>{company.trialActive ? "Active" : "Expirée"}</td>
                <td>{company.activeSubscriptions}</td>
                <td>{company.dueSubscriptions}</td>
                <td>{company.expiredSubscriptions}</td>
                <td>{company.toProcessSubscriptions}</td>
                <td>
                  <Link
                    className={"btn-1"}
                    params={{ clientId: company.id }}
                    to={CLIENT_LINK}
                  >
                    +
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </div>
  );
};

const Clients = (): JSX.Element => {
  return (
    <>
      <ViewName name={"Clients"} />
      <Head />

      <Content>
        <Routes>
          <Route
            element={
              <PageContent>
                <Outlet />
              </PageContent>
            }
          >
            <Route element={<Index />} index />
            <Route element={<Client />} path={`${CLIENT}/*`} />
          </Route>
        </Routes>
      </Content>
    </>
  );
};

function useLoad() {
  const { loadCompanies } = useSuperAdmin();

  return loadCompanies;
}

export default withProvideHead(withLoader(Clients, useLoad));
