import React, { useMemo } from "react";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import {
  LOGIN_LINK,
  LOST_PASSWORD_LINK,
  RESET_PASSWORD,
} from "../../routes/public";
// eslint-disable-next-line no-restricted-imports
import { Form, Formik } from "formik";
import Link from "../../services/routing/components/Link";
import { useTranslation } from "react-i18next";
import { object, ref, string } from "yup";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { useAuth } from "../../services/auth/apiProvider";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "../../services/routing/useParams";
import Card from "src/services/ui/elements/Card";
import CardBody from "../../services/ui/elements/CardBody";
import CardHead from "../../services/ui/elements/CardHead";
import FormFooter from "../../services/forms/FormFooter";
import Button from "../../services/ui/elements/Button";
import Input from "../../services/ui/elements/Input";

const ResetPassword: React.FC = () => {
  const { t } = useTranslation(["auth"]);
  const { resetPassword } = useAuth();
  const { toastSuccess, toastError } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();

  const ResetPasswordSchema = useMemo(
    () =>
      object().shape({
        password: string()
          .label(t("auth:password"))
          .required("Le mot de passe est requis")
          .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            {
              message:
                "Le mot de passe doit contenir 8 caractères min. dont au moins une lettre, un chiffre et un caractère spécial.",
            },
          ),
        passwordConfirmation: string()
          .required("La confirmation de mot de passe est obligatoire")
          .equals([ref("password")], "Les mots de passe ne correspondent pas."),
      }),
    [t],
  );

  const { guid } = useParams<typeof RESET_PASSWORD>();
  return (
    <Card className={"auth-card theme-light"}>
      <CardHead title={"Réinitialisation de votre mot de passe"} />

      <CardBody>
        <Formik
          initialValues={{ password: "", passwordConfirmation: "" }}
          onSubmit={(values, { setSubmitting }) => {
            resetPassword(guid, values.password).then(
              () => {
                setSubmitting(false);
                toastSuccess("auth:reset-password.SUCCESS");
                return navigate(LOGIN_LINK);
              },
              (err: AxiosError) => {
                setSubmitting(false);
                if (err.response?.status === 406) {
                  toastError("auth:reset-password.ERROR_LINK_ALREADY_USE");
                  return navigate(LOST_PASSWORD_LINK);
                } else {
                  toastError("auth:reset-password.ERROR");
                }
              },
            );
          }}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={ResetPasswordSchema}
        >
          {({ handleChange, errors, isSubmitting }) => (
            <Form>
              <div className={"form-block"}>
                <label className={"input-label"} htmlFor={"password"}>
                  Nouveau mot de passe *
                </label>
                <Input
                  id={"password"}
                  name={"password"}
                  onChange={handleChange}
                  placeholder={"**********"}
                  type={"password"}
                />
                <p>
                  8 caractères min. dont au moins une lettre, un chiffre et un
                  caractère spécial.
                </p>
              </div>

              <div className={"form-block"}>
                <label
                  className={"input-label"}
                  htmlFor={"passwordConfirmation"}
                >
                  Confirmation du nouveau mot de passe *
                </label>
                <Input
                  id={"passwordConfirmation"}
                  name={"passwordConfirmation"}
                  onChange={handleChange}
                  placeholder={"Password confirmation"}
                  type={"password"}
                />
              </div>

              <div>
                <ul>
                  {Object.values(errors).map((value, index) => (
                    <li key={index}>{value}</li>
                  ))}
                </ul>
              </div>

              <FormFooter>
                <Button disabled={isSubmitting} block>
                  Réinitialiser
                </Button>
                <div className={"go-back-to-login-cta"}>
                  <Link to={LOGIN_LINK}>Retour à la connexion</Link>
                </div>
              </FormFooter>
            </Form>
          )}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default withRedirectToPrivate(ResetPassword);
