import Routes from "../../services/routing/Routes";
import { Navigate, Route } from "react-router-dom";
import Projects from "./user/projects/Projects";
import {
  DEPARTMENTS,
  MATERIALS,
  PROJECTS,
  PROJECTS_LINK,
  REVIEW,
  TRUCKS,
} from "../../routes/user";
import Materials from "./user/materials/Materials";
import Trucks from "./user/trucks/Trucks";
import Departments from "./user/departments/Departments";
import React, { useCallback } from "react";
import { useCompany } from "../../services/company/apiProvider";
import { withLoader } from "../../services/routing/useLoader";
import { AuthAPIConnected, useAuth } from "../../services/auth/apiProvider";
import Review from "./user/Review";

export const User = (): JSX.Element => {
  return (
    <Routes>
      <Route element={<Navigate to={PROJECTS_LINK} replace />} index />

      <Route element={<Projects />} path={`${PROJECTS}/*`} />

      <Route element={<Materials />} path={`${MATERIALS}/*`} />
      <Route element={<Trucks />} path={`${TRUCKS}/*`} />
      <Route element={<Departments />} path={`${DEPARTMENTS}/*`} />

      <Route element={<Review />} path={REVIEW} />
    </Routes>
  );
};

function useLoad() {
  const { user } = useAuth() as AuthAPIConnected;
  const { loadCompanyById } = useCompany();

  return useCallback(() => loadCompanyById(user.CompanyId as number), [
    loadCompanyById,
    user.CompanyId,
  ]);
}

export default withLoader(User, useLoad);
