import React, { FunctionComponent } from "react";
import { useToastsWithIntl } from "../../services/toast-notifications";
import { LOGIN_LINK, REGISTER_LINK } from "../../routes/public";
import RegisterForm from "../../services/auth/components/RegisterForm";
import { useAuth } from "../../services/auth/apiProvider";
import useQueryParams from "../../services/routing/useQueryParams";
import Link from "../../services/routing/components/Link";
import { useNavigate } from "react-router-dom";
import Card from "../../services/ui/elements/Card";
import CardHead from "../../services/ui/elements/CardHead";
import CardBody from "../../services/ui/elements/CardBody";

const Register: FunctionComponent = () => {
  const auth = useAuth();
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);
  const navigate = useNavigate();
  const params = useQueryParams();

  if (params && params.type === "user") {
    return (
      <Card className={"auth-card theme-light"}>
        <CardHead title={"Création de compte utilisateur"} />

        <CardBody>
          <RegisterForm
            onSubmit={(registerInfos) => {
              return auth.register(registerInfos).then(
                () => {
                  toastSuccess("auth:register.TOAST_SUCCESS");
                  navigate(LOGIN_LINK);
                },
                () => {
                  toastError("auth:register.TOAST_ERROR");
                },
              );
            }}
            type={"user"}
          />

          <Link to={REGISTER_LINK}>Retour</Link>
        </CardBody>
      </Card>
    );
  }

  if (params && params.type === "company") {
    return (
      <Card className={"auth-card theme-light"} size={"l"}>
        <CardHead title={"Création de compte administrateur"} />

        <CardBody>
          <RegisterForm
            onSubmit={(registerInfos) => {
              return auth.register(registerInfos).then(
                () => {
                  toastSuccess("auth:register.TOAST_SUCCESS");
                  navigate(LOGIN_LINK);
                },
                () => {
                  toastError("auth:register.TOAST_ERROR");
                },
              );
            }}
            type={"company"}
          />

          <Link to={REGISTER_LINK}>Retour</Link>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card className={"auth-card"}>
      <CardHead title={"Création de compte"} />
      <CardBody>
        <p>
          Pour pouvoir créer un compte utilisateur, un compte administrateur
          doit préalablement être créé par un responsable de la société afin
          d'obtenir <strong>un code société</strong>.
        </p>
        <br />
        <Link
          className={"btn-1 btn-block"}
          queryParams={{ type: "company" }}
          to={REGISTER_LINK}
        >
          Compte Administrateur
        </Link>
        <p>
          A destination des personnes ayant vocation à{" "}
          <strong>
            <u>gérer le compte, les utilisateurs, et les abonnements.</u>
          </strong>
        </p>
        <Link
          className={"btn-2 btn-block"}
          queryParams={{ type: "user" }}
          to={REGISTER_LINK}
        >
          Compte Utilisateur
        </Link>
        <p>
          A destination des personnes ayant vocation à{" "}
          <strong>
            <u>utiliser l’application sur les chantiers.</u>
          </strong>
        </p>
        <br />
        <Link to={LOGIN_LINK}>J'ai déjà un compte</Link>
      </CardBody>
    </Card>
  );
};

export default Register;
