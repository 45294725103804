import { RECAP_LINK } from "./projects";

export const ORDERS_AND_DELIVERIES = "orders-and-deliveries" as const;
export const ORDERS_AND_DELIVERIES_LINK = `${RECAP_LINK}/${ORDERS_AND_DELIVERIES}` as const;

export const TRUCKS = "trucks" as const;
export const TRUCKS_LINK = `${RECAP_LINK}/${TRUCKS}` as const;

export const TRUCKS_DETAILS = ":truckId" as const;
export const TRUCKS_DETAILS_LINK = `${TRUCKS_LINK}/${TRUCKS_DETAILS}` as const;

export const WRAP_UPS = "wrap-ups" as const;
export const WRAP_UPS_LINK = `${RECAP_LINK}/${WRAP_UPS}` as const;

export const REPORT = "report" as const;
export const REPORT_LINK = `${RECAP_LINK}/${REPORT}` as const;
