import React, {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import { FullMaterial, Material, MaterialForm } from "./material";
import {
  getMaterialById,
  updateMaterial as apiUpdateMaterial,
  deleteMaterial as apiDeleteMaterial,
} from "./api";

export interface MaterialAPI {
  material: FullMaterial | null;

  loadMaterialById(materialId: Material["id"]): Promise<void>;

  updateMaterial(material: MaterialForm): Promise<void>;

  deleteMaterial(): Promise<void>;
}

export interface MaterialAPILoaded extends MaterialAPI {
  material: NonNullable<MaterialAPI["material"]>;
}

export const MaterialContext = createContext<MaterialAPI | null>(null);

export function useMaterial(): MaterialAPI {
  return useContext(MaterialContext) as MaterialAPI;
}

export const ProvideMaterial = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [material, setMaterial] = useState<MaterialAPI["material"]>(null);

  const loadMaterialById: MaterialAPI["loadMaterialById"] = useCallback(
    (materialId) => {
      return getMaterialById(materialId).then(({ data }) => {
        setMaterial(data);
      });
    },
    [],
  );

  const updateMaterial: MaterialAPI["updateMaterial"] = useCallback(
    (newMaterial) => {
      return apiUpdateMaterial(
        (material as NonNullable<typeof material>).id,
        newMaterial,
      ).then(({ data }) => {
        setMaterial(data);
      });
    },
    [material],
  );

  const deleteMaterial: MaterialAPI["deleteMaterial"] = useCallback(() => {
    return apiDeleteMaterial(
      (material as NonNullable<typeof material>).id,
    ).then(() => {
      setMaterial(null);
    });
  }, [material]);

  return (
    <MaterialContext.Provider
      value={{ material, loadMaterialById, updateMaterial, deleteMaterial }}
    >
      {children}
    </MaterialContext.Provider>
  );
};

export function withProvideMaterial<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideMaterial(props: P) {
    return (
      <ProvideMaterial>
        <WrappedComponent {...props} />
      </ProvideMaterial>
    );
  }

  WithProvideMaterial.displayName = `withProvideMaterial(${displayName})`;

  return WithProvideMaterial;
}
