import { FunctionComponent } from "react";
import { useFormikContext } from "formik";
import Button, { ButtonProps } from "../ui/elements/Button";

interface SubmitButtonProps extends ButtonProps {}

const SubmitButton: FunctionComponent<SubmitButtonProps> = (props) => {
  const { isSubmitting } = useFormikContext();

  return (
    <Button {...props} disabled={isSubmitting} type={"submit"}>
      {isSubmitting ? "Chargement..." : props.children}
    </Button>
  );
};

export default SubmitButton;
