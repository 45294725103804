/* eslint-disable i18next/no-literal-string */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import AUTH_FR from "../auth/i18n/fr.json";
import VALIDATIONS_FR from "../validations/i18n/fr.json";
import MATERIAL_FR from "../material/i18n/fr.json";
import TRUCK_FR from "../truck/i18n/fr.json";
import DEPARTMENT_FR from "../department/i18n/fr.json";
import PROVIDER_FR from "../provider/i18n/fr.json";
import PROJECT_FR from "../project/i18n/fr.json";
import COMPANY_FR from "../company/i18n/fr.json";
import REVIEW_FR from "../review/i18n/fr.json";
import SUBSCRIPTION_FR from "../subscription/i18n/fr.json";
import { setLocale } from "yup";

i18n.use(initReactI18next).init({
  ns: [
    "auth",
    "validations",
    "material",
    "truck",
    "department",
    "provider",
    "project",
    "company",
    "review",
  ],
  resources: {
    fr: {
      auth: AUTH_FR,
      validations: VALIDATIONS_FR,
      material: MATERIAL_FR,
      truck: TRUCK_FR,
      department: DEPARTMENT_FR,
      provider: PROVIDER_FR,
      project: PROJECT_FR,
      company: COMPANY_FR,
      review: REVIEW_FR,
      subscription: SUBSCRIPTION_FR,
    },
  },
  lng: "fr",
  fallbackLng: "fr",

  interpolation: {
    escapeValue: false,
  },
});

export interface ValidationError {
  key: string;
  values: Record<string, unknown>;
}

function getError(key: string) {
  return (values: unknown) => ({ key, values } as ValidationError);
}

setLocale({
  mixed: {
    default: getError("invalid_field"),
    notType(props) {
      if (props.type === "date") return getError("required")(props);
      else return getError("invalid_field")(props);
    },
    required: getError("required"),
    defined: getError("defined"),
    oneOf: getError("one_of"),
  },
  string: {
    email: getError("user.email.invalid"),
    matches: getError("matches"),
    min: getError("string.min"),
    max: getError("string.max"),
  },
  number: {
    positive: getError("positive"),
    max: getError("max"),
  },
  date: {
    max: getError("date:GENERIC_MAX"),
    min: getError("date:GENERIC_MIN"),
  },
});

export default i18n;
