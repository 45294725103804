import {
  CompanyAPILoaded,
  useCompany,
} from "../../../../services/company/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import React, { useCallback, useMemo } from "react";
import { CompanyForm } from "../../../../services/company/CompanyForm";
import Section from "src/services/ui/elements/Section";
import { useReview } from "src/services/review/apiProvider";

const Index = (): JSX.Element => {
  const { reviews } = useReview();
  const { company } = useCompany() as CompanyAPILoaded;

  const averageRating = useMemo(() => {
    const companyReviews = [...reviews.values()].filter(
      (review) => review.User.Company.id === company.id,
    );
    return (
      companyReviews.reduce((sum, curReview) => sum + curReview.note, 0) /
      companyReviews.length
    );
  }, [reviews, company.id]);

  return (
    <div>
      <Section>
        <div className={"info"}>
          <p>
            Note moyenne :{" "}
            {averageRating ? averageRating.toFixed(2) + "/5" : "N/A"}
          </p>
        </div>
      </Section>
      <CompanyForm />
    </div>
  );
};

const useLoad = () => {
  const { loadAdministrators } = useCompany() as CompanyAPILoaded;
  const { loadReviews } = useReview();

  return useCallback(() => Promise.all([loadAdministrators(), loadReviews()]), [
    loadAdministrators,
    loadReviews,
  ]);
};

export default withLoader(Index, useLoad);
