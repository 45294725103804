import { useEffect, useRef } from "react";

export const useOnChange = (
  triggerVariable: unknown,
  onChange: () => void,
): void => {
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      onChange();
    }
  }, [triggerVariable, onChange]);
};
