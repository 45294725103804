import React, { useEffect, useState } from "react";
import withRedirectToPrivate from "../../services/auth/hocs/withRedirectToPrivate";
import { useToastsWithIntl } from "../../services/toast-notifications";
import useQueryParams from "../../services/routing/useQueryParams";
import LoginForm from "../../services/auth/components/LoginForm";
import { useAuth } from "../../services/auth/apiProvider";
import { useNavigate } from "react-router-dom";
import CardHead from "../../services/ui/elements/CardHead";
import CardBody from "../../services/ui/elements/CardBody";
import Card from "../../services/ui/elements/Card";
import axios from "axios";

const Login: React.FC = () => {
  const { login, validateUserRegistration } = useAuth();
  const { toastError } = useToastsWithIntl(["auth"]);
  const [accountValidated, setAccountValidated] = useState(false);
  const { guid, redirectTo } = useQueryParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof guid === "string") {
      validateUserRegistration(guid).then(
        () => setAccountValidated(true),
        () => toastError("auth:validate-user-registration"),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guid]);

  return (
    <Card className={"auth-card theme-light"}>
      <CardHead title={"Connexion"} />

      <CardBody>
        {guid && accountValidated && (
          <p>
            Votre compte a bien été validé, vous pouvez désormais vous
            connecter.
          </p>
        )}

        <LoginForm
          onSubmit={(loginInfos) => {
            return login(loginInfos).then(
              () => {
                if (redirectTo && typeof redirectTo === "string") {
                  navigate(redirectTo);
                }
              },
              (error) => {
                if (
                  axios.isAxiosError(error) &&
                  error.response?.status === 400 &&
                  error.response?.data?.error === "Too much connections"
                ) {
                  toastError(
                    "auth:login.TOO_MUCH_CONNECTIONS.TITLE",
                    "auth:login.TOO_MUCH_CONNECTIONS.DESCRIPTION",
                    {
                      emails: error.response.data.users,
                    },
                  );
                } else if (
                  axios.isAxiosError(error) &&
                  error.response?.status === 412
                ) {
                  toastError("auth:login.NOT_VALIDATED");
                } else if (
                  axios.isAxiosError(error) &&
                  error.response?.status === 409
                ) {
                  toastError("auth:login.CURRENTLY_USED");
                } else {
                  toastError("auth:login.TOAST_ERROR");
                }
              },
            );
          }}
        />
      </CardBody>
    </Card>
  );
};

export default withRedirectToPrivate(Login);
