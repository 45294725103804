import { array, number, object, string } from "yup";
import { Fonctions, Gender, User } from "../auth/user";

export interface Company {
  id: number;
  name: string;
  group: string | null;
  code: string;
  siret: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  landlineNumber: string;
}

export interface FullCompany extends Company {
  Administrators: User[];
}

export const CompanySchema = object().shape({
  name: string().label("Raison sociale").nullable().required(),
  group: string()
    .label("Si appartenance à un groupe, préciser lequel")
    .nullable(),
  siret: string()
    .label("SIREN ou SIRET")
    .nullable()
    .required()
    .min(6)
    .max(14)
    .test(
      "is-num",
      () => ({ key: "siret_siren" }),
      (val) => /^[0-9]{6}([0-9]{8})?$/g.test(val || ""),
    ),
  address: string().label("Adresse").nullable().required(),
  postalCode: string()
    .label("Code postal")
    .nullable()
    .required()
    .matches(/^\d{5}$/),
  city: string().label("Ville").nullable().required(),
  country: string().label("Pays").nullable().required(),
  landlineNumber: string()
    .label("Téléphone fixe")
    .nullable()
    .test(
      "isPhoneNumber",
      () => ({ key: "user.phoneNumber.matches" }),
      (value) =>
        value ? /^(0|[+]33|0033)[1-9]([0-9]{8})$/g.test(value) : true,
    ),
  code: string().label("Code société").notEditable(),
  Administrators: array().of(
    object({
      email: string().label("E-mail"),
      firstname: string().label("Prénom"),
      lastname: string().label("Nom"),
      phoneNumber: string().label("Numéro de téléphone"),
      gender: number()
        .label("Civilité")
        .nullable()
        .oneOfEnum(Gender, "auth", "user.gender"),
      fonction: number()
        .label("Fonction")
        .nullable()
        .oneOfEnum(Fonctions, "auth", "user.fonction"),
    }).notEditable(),
  ),
});

export interface CompanyUpdateForm {
  name: string;
  siret: string;
  address: string;
  postalCode: string;
  city: string;
  landlineNumber: string;
}
