import { ViewName } from "../../../../services/design-system/base-layout";
import { useCompany } from "../../../../services/company/apiProvider";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import { useNavigate } from "react-router-dom";
import { DEPARTMENTS_LINK } from "../../../../routes/user";
import DepartmentForm from "../../../../services/department/components/DepartmentForm";

const New = (): JSX.Element => {
  const { createDepartment } = useCompany();
  const { toastError, toastSuccess } = useToastsWithIntl(["department"]);
  const navigate = useNavigate();

  return (
    <div>
      <ViewName name={"Nouvelle centrale"} />

      <DepartmentForm
        onSubmit={(department) =>
          createDepartment(department).then(
            () => {
              toastSuccess("department:create-department.SUCCESS");
              navigate(DEPARTMENTS_LINK);
            },
            () => {
              toastError("department:create-department.ERROR");
            },
          )
        }
      />
    </div>
  );
};

export default New;
