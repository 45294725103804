import React, {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Company, CompanyUpdateForm, FullCompany } from "./company";
import {
  createDepartment as apiCreateDepartment,
  createMaterial as apiCreateMaterial,
  createProvider as apiCreateProvider,
  createProject as apiCreateProject,
  createSubscription as apiCreateSubscription,
  getAdministrators,
  getCompanyById,
  getDepartments,
  getMaterials,
  getProjects,
  getProviders,
  getTrucks,
  getUsers,
  inviteUsers as apiInviteUsers,
  updateCompany as apiUpdateCompany,
  getSubscriptions,
} from "./api";
import { Material, MaterialForm } from "../material/material";
import { FullTruck, Truck, TruckForm } from "../truck/truck";
import {
  Department,
  DepartmentForm,
  FullDepartment,
} from "../department/department";
import {
  FullProject,
  mapProjectRawToProject,
  Project,
  ProjectForm,
} from "../project/project";
import { mapUserRawToUser, User } from "../auth/user";
import { FullProvider, Provider, ProviderForm } from "../provider/provider";
import {
  addTruckToFavorites as apiAddTruckToFavorites,
  removeTruckFromFavorites as apiRemoveTruckFromFavorites,
  resetUserTruckFavorites as apiResetUserTruckFavorites,
  resetUserTruckIdentificationsNumbers as apiResetUserTruckIdentificationsNumbers,
} from "../truck/api";
import { createTruck as apiCreateTruck } from "../provider/api";
import { copyMapAndDelete, copyMapAndReplace } from "../data-structures/map";
import { AxiosPromise } from "axios";
import {
  mapSubscriptionRawToSubscription,
  NewSubscriptionForm,
  Subscription,
} from "../subscription/subscription";
import { validateSubscription as apiValidateSubscription } from "../subscription/api";
import { extendTrialPeriod as apiExtendTrialPeriod } from "../subscription/api";
import { deleteUserById } from "../auth/api";

export interface CompanyAPI {
  company: FullCompany | null;
  loadCompanyById(companyId: Company["id"]): Promise<void>;
  reload(): Promise<void>;

  updateCompany(company: CompanyUpdateForm): Promise<void>;

  /* Materials */
  materials: Map<Material["id"], Material>;
  loadMaterials(): Promise<void>;
  createMaterial(material: MaterialForm): Promise<Material>;

  /* Trucks */
  trucks: Map<Truck["id"], FullTruck>;
  loadTrucks(): Promise<void>;
  createTruck(truck: TruckForm): AxiosPromise<FullTruck>;

  /* User favorites trucks */
  addTruckToFavorites(truckId: Truck["id"]): Promise<void>;
  removeTruckFromFavorites(truckId: Truck["id"]): Promise<void>;
  resetUserTruckFavorites(): Promise<void>;

  /* Trucks data */
  resetUserTruckIdentificationsNumbers(): Promise<void>;

  /* Departments */
  departments: Map<Department["id"], FullDepartment>;
  loadDepartments(): Promise<void>;
  createDepartment(department: DepartmentForm): Promise<FullDepartment>;

  /* Providers */
  providers: Map<Provider["id"], Provider>;
  loadProviders(): Promise<void>;
  createProvider(provider: ProviderForm): Promise<FullProvider>;

  /* Projects */
  projects: Map<Project["id"], Project>;
  loadProjects(): Promise<void>;
  createProject(project: ProjectForm): Promise<FullProject>;

  /* Users */
  users: Map<User["id"], User>;
  loadUsers(): Promise<void>;
  inviteUsers(emails: string[]): Promise<void>;
  deleteUser(userId: User["id"]): Promise<void>;

  /* Administrators */
  administrators: Map<User["id"], User>;
  loadAdministratorsByCompanyId(companyId: Company["id"]): Promise<void>;
  loadAdministrators(): Promise<void>;

  /* Subscriptions */
  subscriptions: Map<Subscription["id"], Subscription>;
  loadSubscriptions(): Promise<void>;
  createSubscription(subscriptionForm: NewSubscriptionForm): Promise<void>;
  validateSubscription(subscriptionId: Subscription["id"]): Promise<void>;
  extendTrialPeriod(subscriptionId: Subscription["id"]): Promise<void>;
}

export interface CompanyAPILoaded extends CompanyAPI {
  company: NonNullable<CompanyAPI["company"]>;
}

export const CompanyContext = createContext<CompanyAPI | null>(null);

export function useCompany(): CompanyAPI {
  return useContext(CompanyContext) as CompanyAPI;
}

const useProvideCompany = () => {
  const [company, setCompany] = useState<CompanyAPI["company"]>(null);

  const loadCompanyById: CompanyAPI["loadCompanyById"] = useCallback(
    (companyId) => {
      return getCompanyById(companyId).then(({ data }) => {
        setCompany(data);
      });
    },
    [],
  );

  const reload: CompanyAPI["reload"] = useCallback(() => {
    return loadCompanyById((company as NonNullable<typeof company>).id);
  }, [loadCompanyById, company]);

  const updateCompany: CompanyAPI["updateCompany"] = useCallback(
    (companyForm) => {
      return apiUpdateCompany(
        (company as NonNullable<typeof company>).id,
        companyForm,
      ).then(({ data }) => setCompany(data));
    },
    [company],
  );

  return { company, loadCompanyById, reload, updateCompany };
};

const useProvideMaterials = (companyId: Company["id"] | undefined) => {
  const [materials, setMaterials] = useState<CompanyAPI["materials"]>(
    new Map(),
  );

  const loadMaterials: CompanyAPI["loadMaterials"] = useCallback(
    () =>
      getMaterials(companyId as NonNullable<typeof companyId>).then(
        ({ data }) => {
          setMaterials(
            new Map(data.map((material) => [material.id, material])),
          );
        },
      ),
    [companyId],
  );

  const createMaterial: CompanyAPI["createMaterial"] = useCallback(
    (material) => {
      return apiCreateMaterial(
        companyId as NonNullable<typeof companyId>,
        material,
      ).then(({ data }) => {
        setMaterials((prevMaterials) =>
          new Map(prevMaterials).set(data.id, data),
        );
        return data;
      });
    },
    [companyId],
  );

  return {
    materials,
    loadMaterials,
    createMaterial,
  };
};

const useProvideTrucks = (companyId: Company["id"] | undefined) => {
  const [trucks, setTrucks] = useState<CompanyAPI["trucks"]>(new Map());

  const loadTrucks: CompanyAPI["loadTrucks"] = useCallback(
    () =>
      getTrucks(companyId as NonNullable<typeof companyId>).then(({ data }) => {
        setTrucks(new Map(data.map((truck) => [truck.id, truck])));
      }),
    [companyId],
  );

  const createTruck: CompanyAPI["createTruck"] = useCallback((truck) => {
    return apiCreateTruck(truck.ProviderId, truck).then((response) => {
      setTrucks((prevTrucks) =>
        copyMapAndReplace(prevTrucks, response.data.id, response.data),
      );
      return response;
    });
  }, []);

  const addTruckToFavorites: CompanyAPI["addTruckToFavorites"] = useCallback(
    (truckId) => {
      return apiAddTruckToFavorites(truckId).then(() => {
        setTrucks((prevTrucks) => {
          const prevTruck = prevTrucks.get(truckId) as FullTruck;
          return copyMapAndReplace(prevTrucks, truckId, {
            ...prevTruck,
            isFavorite: true,
          });
        });
      });
    },
    [],
  );
  const removeTruckFromFavorites: CompanyAPI["removeTruckFromFavorites"] = useCallback(
    (truckId) => {
      return apiRemoveTruckFromFavorites(truckId).then(() => {
        setTrucks((prevTrucks) => {
          const prevTruck = prevTrucks.get(truckId) as FullTruck;
          return copyMapAndReplace(prevTrucks, truckId, {
            ...prevTruck,
            isFavorite: false,
          });
        });
      });
    },
    [],
  );

  const resetUserTruckFavorites: CompanyAPI["resetUserTruckFavorites"] = useCallback(() => {
    return apiResetUserTruckFavorites().then(() => {
      setTrucks(
        (prevTrucks) =>
          new Map(
            [...prevTrucks.values()].map((truck) => [
              truck.id,
              {
                ...truck,
                isFavorite: false,
              },
            ]),
          ),
      );
    });
  }, []);

  const resetUserTruckIdentificationsNumbers: CompanyAPI["resetUserTruckIdentificationsNumbers"] = useCallback(() => {
    return apiResetUserTruckIdentificationsNumbers().then(() => {
      setTrucks(
        (prevTrucks) =>
          new Map(
            [...prevTrucks.values()].map((truck) => [
              truck.id,
              {
                ...truck,
                TruckUserData: {
                  ...truck.TruckUserData,
                  identificationNumber: null,
                },
              },
            ]),
          ),
      );
    });
  }, []);

  return {
    trucks,
    loadTrucks,
    createTruck,
    addTruckToFavorites,
    removeTruckFromFavorites,
    resetUserTruckFavorites,
    resetUserTruckIdentificationsNumbers,
  };
};

const useProvideDepartments = (companyId: Company["id"] | undefined) => {
  const [departments, setDepartments] = useState<CompanyAPI["departments"]>(
    new Map(),
  );

  const loadDepartments: CompanyAPI["loadDepartments"] = useCallback(
    () =>
      getDepartments(companyId as NonNullable<typeof companyId>).then(
        ({ data }) => {
          setDepartments(
            new Map(data.map((department) => [department.id, department])),
          );
        },
      ),
    [companyId],
  );

  const createDepartment: CompanyAPI["createDepartment"] = useCallback(
    (department) => {
      return apiCreateDepartment(
        companyId as NonNullable<typeof companyId>,
        department,
      ).then(({ data }) => {
        setDepartments((prevDepartments) =>
          new Map(prevDepartments).set(data.id, data),
        );
        return data;
      });
    },
    [companyId],
  );

  return {
    departments,
    loadDepartments,
    createDepartment,
  };
};

const useProviderProviders = (companyId: Company["id"] | undefined) => {
  const [providers, setProviders] = useState<CompanyAPI["providers"]>(
    new Map(),
  );

  const loadProviders: CompanyAPI["loadProviders"] = useCallback(
    () =>
      getProviders(companyId as NonNullable<typeof companyId>).then(
        ({ data }) => {
          setProviders(
            new Map(data.map((provider) => [provider.id, provider])),
          );
        },
      ),
    [companyId],
  );

  const createProvider: CompanyAPI["createProvider"] = useCallback(
    (provider) => {
      return apiCreateProvider(
        companyId as NonNullable<typeof companyId>,
        provider,
      ).then(({ data }) => {
        setProviders((prevProviders) =>
          new Map(prevProviders).set(data.id, data),
        );
        return data;
      });
    },
    [companyId],
  );

  return {
    providers,
    loadProviders,
    createProvider,
  };
};

const useProvideProjects = (companyId: Company["id"] | undefined) => {
  const [projects, setProjects] = useState<CompanyAPI["projects"]>(new Map());

  const loadProjects: CompanyAPI["loadProjects"] = useCallback(
    () =>
      getProjects(companyId as NonNullable<typeof companyId>).then(({ data }) =>
        setProjects(
          new Map(
            data.map((project) => [
              project.id,
              mapProjectRawToProject(project),
            ]),
          ),
        ),
      ),
    [companyId],
  );

  const createProject: CompanyAPI["createProject"] = useCallback(
    (project) =>
      apiCreateProject(
        companyId as NonNullable<typeof companyId>,
        project,
      ).then(({ data }) => mapProjectRawToProject(data)),
    [companyId],
  );

  return {
    projects,
    loadProjects,
    createProject,
  };
};

const useProvideUsers = (companyId: Company["id"] | undefined) => {
  const [users, setUsers] = useState<CompanyAPI["users"]>(new Map());

  const loadUsers: CompanyAPI["loadUsers"] = useCallback(
    () =>
      getUsers(companyId as NonNullable<typeof companyId>).then(({ data }) =>
        setUsers(new Map(data.map((u) => [u.id, mapUserRawToUser(u)]))),
      ),
    [companyId],
  );

  const inviteUsers: CompanyAPI["inviteUsers"] = useCallback(
    (emails) => {
      return apiInviteUsers(
        companyId as NonNullable<typeof companyId>,
        emails,
      ).then(() => {
        /*Do nothing*/
      });
    },
    [companyId],
  );

  const deleteUser: CompanyAPI["deleteUser"] = useCallback(
    (userId) =>
      deleteUserById(userId).then(() =>
        setUsers((prevUsers) => copyMapAndDelete(prevUsers, userId)),
      ),
    [],
  );

  return { users, loadUsers, inviteUsers, deleteUser };
};

const useProvideAdministrators = (companyId: Company["id"] | undefined) => {
  const [administrators, setAdministrators] = useState<
    CompanyAPI["administrators"]
  >(new Map());

  const loadAdministratorsByCompanyId: CompanyAPI["loadAdministratorsByCompanyId"] = useCallback(
    (companyId1) =>
      getAdministrators(companyId1).then(({ data }) =>
        setAdministrators(
          new Map(data.map((u) => [u.id, mapUserRawToUser(u)])),
        ),
      ),
    [],
  );

  const loadAdministrators: CompanyAPI["loadAdministrators"] = useCallback(
    () =>
      loadAdministratorsByCompanyId(companyId as NonNullable<typeof companyId>),
    [companyId, loadAdministratorsByCompanyId],
  );

  return { administrators, loadAdministrators, loadAdministratorsByCompanyId };
};

const useProvideSubscriptions = (companyId: Company["id"] | undefined) => {
  const [subscriptions, setSubscriptions] = useState(new Map());

  const loadSubscriptions: CompanyAPI["loadSubscriptions"] = useCallback(
    () =>
      getSubscriptions(
        companyId as NonNullable<typeof companyId>,
      ).then(({ data }) =>
        setSubscriptions(
          new Map(
            data.map((sub) => [sub.id, mapSubscriptionRawToSubscription(sub)]),
          ),
        ),
      ),
    [companyId],
  );

  const createSubscription: CompanyAPI["createSubscription"] = useCallback(
    (subscriptionForm) =>
      apiCreateSubscription(companyId as NonNullable<typeof companyId>, {
        ...subscriptionForm,
        duration: subscriptionForm.duration * 12,
      }).then(({ data }) =>
        setSubscriptions((prevSubscriptions) =>
          new Map(prevSubscriptions).set(
            data.id,
            mapSubscriptionRawToSubscription(data),
          ),
        ),
      ),
    [companyId],
  );

  const validateSubscription: CompanyAPI["validateSubscription"] = useCallback(
    (subscriptionId) =>
      apiValidateSubscription(subscriptionId).then(({ data }) =>
        setSubscriptions((prevSubscriptions) =>
          copyMapAndReplace(
            prevSubscriptions,
            data.id,
            mapSubscriptionRawToSubscription(data),
          ),
        ),
      ),
    [],
  );

  const extendTrialPeriod: CompanyAPI["extendTrialPeriod"] = useCallback(
    (subscriptionId) =>
      apiExtendTrialPeriod(subscriptionId).then(({ data }) =>
        setSubscriptions((prevSubscriptions) =>
          copyMapAndReplace(
            prevSubscriptions,
            data.id,
            mapSubscriptionRawToSubscription(data),
          ),
        ),
      ),
    [],
  );

  return {
    subscriptions,
    loadSubscriptions,
    createSubscription,
    validateSubscription,
    extendTrialPeriod,
  };
};

export const ProvideCompany = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const companyAPI = useProvideCompany();
  const companyId = useMemo(() => companyAPI.company?.id, [companyAPI.company]);
  const materialsAPI = useProvideMaterials(companyId);
  const trucksAPI = useProvideTrucks(companyId);
  const departmentsAPI = useProvideDepartments(companyId);
  const providersAPI = useProviderProviders(companyId);
  const projectsAPI = useProvideProjects(companyId);
  const usersAPI = useProvideUsers(companyId);
  const administratorsAPI = useProvideAdministrators(companyId);
  const subscriptionsAPI = useProvideSubscriptions(companyId);

  return (
    <CompanyContext.Provider
      value={{
        ...companyAPI,
        ...materialsAPI,
        ...trucksAPI,
        ...departmentsAPI,
        ...providersAPI,
        ...projectsAPI,
        ...usersAPI,
        ...administratorsAPI,
        ...subscriptionsAPI,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export function withProvideCompany<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideCompany(props: P) {
    return (
      <ProvideCompany>
        <WrappedComponent {...props} />
      </ProvideCompany>
    );
  }

  WithProvideCompany.displayName = `withProvideCompany(${displayName})`;

  return WithProvideCompany;
}
