import {
  ActionBar,
  ViewName,
} from "../../../../../services/design-system/base-layout";
import Form from "../../../../../services/forms/Form";
import {
  DeliverySlipForm,
  DeliverySlipSchema,
} from "../../../../../services/project/deliverySlip";
import AutoField from "../../../../../services/forms/AutoField";
import SubmitButton from "../../../../../services/forms/SubmitButton";
import ValidationsErrors from "../../../../../services/forms/ValidationsErrors";
import SelectDepartmentField from "../../../../../services/department/components/SelectDepartmentField";
import SelectTruckField from "../../../../../services/truck/components/SelectTruckField";
import {
  ProjectAPILoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import { useToastsWithIntl } from "../../../../../services/toast-notifications";
import { useMemo } from "react";
import { useParams } from "../../../../../services/routing/useParams";
import { DELIVERY_SLIP_LINK } from "../../../../../routes/user/delivery-slips";
import NotFound from "../../../../../services/routing/NotFound";
import Button from "../../../../../services/ui/elements/Button";
import { useConfirmationWithIntl } from "../../../../../services/ui/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SelectProviderField from "src/services/provider/components/SelectProviderField";

const DeliverySlip = (): JSX.Element => {
  const deliverySlipId = Number(
    useParams<typeof DELIVERY_SLIP_LINK>().deliverySlipId,
  );
  const {
    project,
    updateDeliverySlip,
    deleteDeliverySlip,
    validateDeliverySlip,
    invalidateDeliverySlip,
  } = useProject() as ProjectAPILoaded;
  const { toastSuccess, toastError } = useToastsWithIntl(["project"]);
  const { confirm } = useConfirmationWithIntl(["project"]);
  const navigate = useNavigate();

  const deliverySlip = useMemo(
    () => project.DeliverySlips.find((ds) => ds.id === deliverySlipId),
    [project.DeliverySlips, deliverySlipId],
  );

  if (!deliverySlip) return <NotFound />;

  return (
    <div>
      <ViewName name={`Bon de Livraison`} />

      <Form
        initialValues={{
          ...deliverySlip,
          ProviderId: deliverySlip.Truck?.Provider.id,
        }}
        onSubmit={(values: DeliverySlipForm) => {
          return updateDeliverySlip(deliverySlip.id, values).then(
            () => {
              toastSuccess("project:update-delivery-slip.SUCCESS");
              navigate("..");
            },
            (error) => {
              if (axios.isAxiosError(error) && error.response?.status === 409) {
                toastError(
                  "project:update-delivery-slip.TONNAGE_HIGER_THAN_ORDERED",
                );
              } else {
                toastError("project:update-delivery-slip.ERROR");
              }
            },
          );
        }}
        schema={DeliverySlipSchema}
      >
        <SelectDepartmentField name={"DepartmentId"} />
        <AutoField name={"tonnageDelivered"} unit={"T"} />
        <AutoField name={"deliveryDate"} />
        <SelectProviderField name={"ProviderId"} />
        <SelectTruckField name={"TruckId"} />
        <AutoField name={"loadingDate"} />
        <AutoField name={"deliverySlipDepartmentNumber"} />

        <p>
          Statut :{" "}
          <span className={!deliverySlip.validationDate ? "body-alert" : ""}>
            {!deliverySlip.validationDate
              ? "À valider - non pris en compte dans le bouclage"
              : "Validé"}
          </span>
        </p>

        <ValidationsErrors />

        <ActionBar>
          <SubmitButton>Enregistrer</SubmitButton>

          <Button
            onClick={() => {
              if (deliverySlip.validationDate) {
                invalidateDeliverySlip(deliverySlip.id).then(
                  () =>
                    toastSuccess("project:invalidate-delivery-slip.SUCCESS"),
                  () => toastError("project:invalidate-delivery-slip.ERROR"),
                );
              } else {
                validateDeliverySlip(deliverySlip.id).then(
                  () => toastSuccess("project:validate-delivery-slip.SUCCESS"),
                  () => toastError("project:validate-delivery-slip.ERROR"),
                );
              }
            }}
            type={"button"}
          >
            {deliverySlip.validationDate ? "Invalider" : "Valider"}
          </Button>

          <Button
            onClick={() =>
              confirm(
                "project:delete-delivery-slip.CONFIRMATION",
                () =>
                  deleteDeliverySlip(deliverySlip.id).then(
                    () => {
                      toastSuccess("project:delete-delivery-slip.SUCCESS");
                      navigate("..");
                    },
                    () => toastError("project:delete-delivery-slip.ERROR"),
                  ),
                true,
              )
            }
            type={"button"}
            variant={"danger"}
          >
            Supprimer
          </Button>
        </ActionBar>
      </Form>
    </div>
  );
};

export default DeliverySlip;
