import { number, object, string } from "yup";
import { Provider } from "../provider/provider";

interface TruckBase {
  type: string | null;
  registrationNumber: string;
  phoneNumber: string | null;
}

export interface Truck extends TruckBase {
  id: number;
}

export interface FullTruck extends Truck {
  usedInProject: boolean;
  Provider: Provider;
  isFavorite: boolean;
  TruckUserData: TruckUserData;
}

export interface TruckForm extends TruckBase {
  ProviderId: Provider["id"];
  TruckUserData: TruckUserData;
}

export interface TruckUserData {
  identificationNumber: string | null;
}

export const TruckFormSchema = object()
  .shape({
    ProviderId: number()
      .label("Transporteur")
      .nullable()
      .when("usedInProject", {
        is: true,
        then: (schema) => schema.notEditable(),
        otherwise: (schema) => schema.required(),
      }),
    type: string().label("Type").nullable(),
    registrationNumber: string()
      .label("Immatriculation")
      .nullable()
      .matches(/^[a-zA-Z0-9-]+$/)
      .uppercase()
      .when("usedInProject", {
        is: true,
        then: (schema) => schema.notEditable(),
        otherwise: (schema) => schema.required(),
      }),
    phoneNumber: string().label("Téléphone").nullable(),
    TruckUserData: object({
      identificationNumber: string().label("N° de camion").nullable(),
    }),
  })
  .defined();

export enum SortTrucks {
  BY_PROVIDER,
  BY_FAVORITES,
  BY_IDENTIFICATION_NUMBER,
}
