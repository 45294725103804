import { object, string } from "yup";

interface ProviderBase {
  name: string;
}

export interface Provider extends ProviderBase {
  id: number;
}

export interface FullProvider extends Provider {
  usedInTruck: boolean;
}

export interface ProviderForm extends ProviderBase {}

export const ProviderFormSchema = object()
  .shape({
    name: string().label("Nom du transporteur").nullable().required(),
  })
  .when("$usedInTruck", { is: true, then: (schema) => schema.notEditable() });
