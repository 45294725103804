import { useField } from "formik";

const FRadio = ({
  name,
  value,
  type = "number",
  parse = type === "number"
    ? (v) => (v.length !== 0 ? parseInt(v, 10) : null)
    : (v) => (v.length !== 0 ? parseFloat(v) : null),
}: {
  name: string;
  value: number;
  type?: "number" | "float";
  parse?(val: string): unknown;
}): JSX.Element => {
  const [field, , helper] = useField(name);

  return (
    <input
      {...field}
      checked={value === field.value}
      onChange={(event) => helper.setValue(parse(event.target.value))}
      type={"radio"}
      value={value}
    />
  );
};

export default FRadio;
