import { ReactNode } from "react";
import { cx } from "@emotion/css";

const ExternalLink = ({
  href,
  children,
  target,
  className,
}: {
  href: string;
  children: ReactNode;
  target?: string;
  className?: string;
}): JSX.Element => {
  return (
    <a
      className={cx(["link", className])}
      href={href}
      rel={"noopener noreferrer"}
      target={target}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
