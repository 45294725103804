const ButtonsRow = ({
  breakpoint,
  children,
}: {
  breakpoint?: "s";
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div className={breakpoint ? `buttons-row-${breakpoint}` : "buttons-row"}>
      {children}
    </div>
  );
};

export default ButtonsRow;
