import Dialog from "../../../../services/ui/elements/Dialog";
import { useNavigate } from "react-router-dom";
import CardHead from "../../../../services/ui/elements/CardHead";
import CardBody from "../../../../services/ui/elements/CardBody";
import { useReview } from "../../../../services/review/apiProvider";
import { useParams } from "../../../../services/routing/useParams";
import { REVIEW_DETAILS_LINK } from "../../../../routes/super-admin/reviews";
import NotFound from "../../../../services/routing/NotFound";
import Section from "../../../../services/ui/elements/Section";
import SectionTitle from "../../../../services/ui/elements/SectionTitle";

const ReviewDetails = (): JSX.Element => {
  const navigate = useNavigate();
  const { reviews } = useReview();
  const { reviewId } = useParams<typeof REVIEW_DETAILS_LINK>();
  const review = reviews.get(Number(reviewId));

  return (
    <Dialog onClose={() => navigate("..")}>
      {review ? (
        <>
          <CardHead title={"Détails"} />
          <CardBody>
            <Section size={"s"}>
              <SectionTitle title={"Détails"} />
              <p>{review.details || "-"}</p>
            </Section>
            <Section size={"s"}>
              <SectionTitle title={"Précisions sur l'avis défavorable"} />
              <p>{review.extendAccessPrecisions || "-"}</p>
            </Section>
          </CardBody>
        </>
      ) : (
        <NotFound />
      )}
    </Dialog>
  );
};

export default ReviewDetails;
