import { USER_LINK } from "./private";

export const PROJECTS = "/projects" as const;
export const PROJECTS_LINK = `${USER_LINK}${PROJECTS}` as const;

export const MATERIALS = "materials" as const;
export const MATERIALS_LINK = `${USER_LINK}/${MATERIALS}` as const;

export const TRUCKS = "trucks" as const;
export const TRUCKS_LINK = `${USER_LINK}/${TRUCKS}` as const;

export const DEPARTMENTS = "departments" as const;
export const DEPARTMENTS_LINK = `${USER_LINK}/${DEPARTMENTS}` as const;

export const REVIEW = "review" as const;
export const REVIEW_LINK = `${USER_LINK}/${REVIEW}` as const;
