import {
  Content,
  ViewName,
} from "../../../../../services/design-system/base-layout";
import Routes from "../../../../../services/routing/Routes";
import { Route, Outlet } from "react-router-dom";
import {
  ProjectAPIDepartmentsRecapLoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import New from "./NewOrder";
import Section from "../../../../../services/ui/elements/Section";
import CreateLink from "../../../../../services/ui/elements/CreateLink";
import { withLoader } from "src/services/routing/useLoader";
import Table from "src/services/ui/elements/Table";
import { NEW_ORDER } from "src/routes/user/project/department";
import Button from "src/services/ui/elements/Button";
import { useConfirmationWithIntl } from "src/services/ui/ConfirmationDialog";
import { useToastsWithIntl } from "src/services/toast-notifications";
import iconTrashWhite from "../../../../../assets/img/icons/icon-trash-white.svg";

const useLoad = () => {
  const { loadDepartmentsRecap } = useProject();

  return loadDepartmentsRecap;
};

const Index = withLoader(() => {
  const {
    project,
    deleteOrder,
  } = useProject() as ProjectAPIDepartmentsRecapLoaded;

  const { confirm } = useConfirmationWithIntl(["project"]);
  const { toastError, toastSuccess } = useToastsWithIntl(["project"]);

  return (
    <div>
      <Section size={"xs"}>
        <CreateLink to={NEW_ORDER}>Nouveau BDC</CreateLink>
        <p className={"input-label"}>
          pour ajouter un bon de commande au projet
        </p>
      </Section>

      <Section>
        <Table>
          <thead>
            <tr>
              <th>Centrale</th>
              <th>N° de commande</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {project.Orders.map((order) => {
              return order.orderNum != null ? (
                <tr key={order.id}>
                  <td>{order.Department.name}</td>
                  <td>{order.orderNum}</td>
                  <td>
                    <Button
                      onClick={() =>
                        confirm(
                          "project:delete-order.CONFIRMATION",
                          () =>
                            deleteOrder(order.id).then(
                              () => {
                                toastSuccess("project:delete-order.SUCCESS");
                              },
                              () => {
                                toastError("project:delete-order.ERROR");
                              },
                            ),
                          true,
                        )
                      }
                      type={"button"}
                      variant={"danger"}
                    >
                      <img
                        alt={"delete icon"}
                        className={"icon"}
                        src={iconTrashWhite}
                      />
                    </Button>
                  </td>
                </tr>
              ) : (
                ""
              );
            })}
          </tbody>
        </Table>
      </Section>
    </div>
  );
}, useLoad);

const PurchaseOrders = (): JSX.Element => {
  return (
    <div>
      <Content>
        <Routes>
          <Route
            element={
              <>
                <Index />
                <Outlet />
              </>
            }
          >
            <Route index />
          </Route>
          <Route
            element={
              <>
                <ViewName name={"Bons de commande"} />
                <Outlet />
              </>
            }
          >
            <Route element={<New />} path={NEW_ORDER} />
          </Route>
        </Routes>
      </Content>
    </div>
  );
};

export default PurchaseOrders;
