import { AxiosPromise } from "axios";
import { Subscription, SubscriptionRaw } from "./subscription";
import baseAPI from "../auth/api";

export function validateSubscription(
  subscriptionId: Subscription["id"],
): AxiosPromise<SubscriptionRaw> {
  return baseAPI.post(`/subscriptions/${subscriptionId}/validate`);
}

export function extendTrialPeriod(
  subscriptionId: Subscription["id"],
): AxiosPromise<SubscriptionRaw> {
  return baseAPI.post(`/subscriptions/${subscriptionId}/extend-trial-period`);
}
