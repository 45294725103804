import { ADMIN_LINK } from "./private";

export const CLIENT = "client" as const;
export const CLIENT_LINK = `${ADMIN_LINK}/${CLIENT}` as const;

export const USERS = "users" as const;
export const USERS_LINK = `${CLIENT_LINK}/${USERS}` as const;

export const INVITE_USERS = "invite-users" as const;
export const INVITE_USERS_LINK = `${CLIENT_LINK}/${INVITE_USERS}` as const;

export const SUBSCRIPTIONS = "subscriptions" as const;
export const SUBSCRIPTIONS_LINK = `${CLIENT_LINK}/${SUBSCRIPTIONS}` as const;

export const NEW_SUBSCRIPTION = "new" as const;
export const NEW_SUBSCRIPTION_LINK = `${SUBSCRIPTIONS_LINK}/${NEW_SUBSCRIPTION}` as const;
