import React, { FunctionComponent, useEffect, useState } from "react";
import {
  ADMIN,
  ADMIN_LINK,
  PRIVATE,
  PROFILE,
  PROFILE_LINK,
  SUPER_ADMIN,
  SUPER_ADMIN_LINK,
  USER,
  USER_LINK,
} from "../../routes/private";
import { getShortName } from "../../services/auth/user";
import NavLink from "../../services/routing/components/NavLink";
import Link from "../../services/routing/components/Link";
import ExternalLink from "../../services/ui/elements/ExternalLink";
import { LOGOUT_BUTTON_ID } from "../../fixtures/auth";
import { AuthAPIConnected, useAuth } from "../../services/auth/apiProvider";
import Routes from "src/services/routing/Routes";
import { Navigate, Route, useLocation } from "react-router-dom";
import Profile from "./Profile";
import {
  AppLayout,
  ContentView,
  Header,
  LayoutContent,
  Sidebar,
} from "../../services/design-system/base-layout";
import {
  DEPARTMENTS_LINK,
  MATERIALS_LINK,
  REVIEW_LINK,
  TRUCKS_LINK,
  PROJECTS_LINK,
} from "../../routes/user";
import User from "./User";
import SuperAdmin from "./SuperAdmin";
import { withProvideCompany } from "../../services/company/apiProvider";
import {
  CLIENTS_LINK,
  REVIEWS_LINK,
  USERS_LINK,
} from "../../routes/super-admin";
import LogoRegulappro from "../../assets/img/logo-regulappro-white.png";
import Admin from "./Admin";
import { CLIENT_LINK } from "../../routes/admin";
import { withProvideReview } from "../../services/review/apiProvider";
import Button from "src/services/ui/elements/Button";

const PrivateSidebar = ({ expanded }: { expanded?: boolean }) => {
  const { user } = useAuth() as AuthAPIConnected;

  return (
    <Sidebar expanded={!!expanded}>
      {user.AdministratedCompanyId !== null && (
        <div className={"sidebar-section"}>
          <div className={"section-title"}>ADMINISTRATION</div>

          <nav className={"sidebar-nav"}>
            <NavLink className={"nav-item"} to={CLIENT_LINK}>
              Gestion de compte
            </NavLink>
          </nav>
        </div>
      )}

      {user.CompanyId !== null && (
        <>
          <div className={"sidebar-section"}>
            <div className={"section-title"}>PROJETS</div>

            <nav className={"sidebar-nav"}>
              <NavLink className={"nav-item"} to={PROJECTS_LINK}>
                Tous les projets
              </NavLink>
            </nav>
          </div>

          <div className={"sidebar-section"}>
            <div className={"section-title"}>BASES DE DONNÉES</div>

            <nav className={"sidebar-nav"}>
              <NavLink className={"nav-item"} to={MATERIALS_LINK}>
                Matériaux
              </NavLink>
              <NavLink className={"nav-item"} to={TRUCKS_LINK}>
                Camions
              </NavLink>
              <NavLink className={"nav-item"} to={DEPARTMENTS_LINK}>
                Centrales
              </NavLink>
            </nav>
          </div>

          <div className={"sidebar-section"}>
            <div className={"section-title"}>AVIS &amp; CONTACT</div>
            <nav className={"sidebar-nav"}>
              <NavLink className={"nav-item"} to={REVIEW_LINK}>
                Laisser un avis
              </NavLink>
              <ExternalLink
                className={"nav-item"}
                href={`mailto:contact@od-1.fr?subject=${encodeURIComponent(
                  "Contact depuis l'application RegulAppro",
                )}`}
              >
                Contact
              </ExternalLink>
              <ExternalLink
                className={"nav-item"}
                href={`https://regulappro.fr/`}
                target={"_blank"}
              >
                Site internet
              </ExternalLink>
            </nav>
          </div>
        </>
      )}

      {user.admin && (
        <div className={"sidebar-section"}>
          <div className={"section-title"}>Super admin</div>

          <div className={"sidebar-nav"}>
            <NavLink className={"nav-item"} to={CLIENTS_LINK}>
              Clients
            </NavLink>
            <NavLink className={"nav-item"} to={USERS_LINK}>
              Utilisateurs
            </NavLink>
            <NavLink className={"nav-item"} to={REVIEWS_LINK}>
              Avis
            </NavLink>
          </div>
        </div>
      )}
    </Sidebar>
  );
};

const Private: FunctionComponent = () => {
  const { user, logout } = useAuth() as AuthAPIConnected;
  const location = useLocation();
  const [expandedSidebar, setExpandedSidebar] = useState(false);

  useEffect(() => {
    setExpandedSidebar(false);
  }, [location]);

  return (
    <AppLayout className={"sticky"}>
      <Header>
        <nav className={"nav"}>
          <Button
            className={"menu-button"}
            onClick={() => setExpandedSidebar(!expandedSidebar)}
            size={"s"}
            variant={"icon"}
          >
            <span
              className={expandedSidebar ? "icon-close" : "icon-burger-menu"}
            ></span>
          </Button>
          <Link to={PRIVATE}>
            <img
              alt={"logo Regulappro"}
              className={"logo-header"}
              src={LogoRegulappro}
            />
          </Link>
        </nav>

        <nav className={"nav"}>
          <NavLink className={"nav-item"} to={PROFILE_LINK}>
            <span className={"icon-user"}></span>
            <span>{getShortName(user)}</span>
          </NavLink>
          <button
            className={"nav-item"}
            data-testid={LOGOUT_BUTTON_ID}
            onClick={logout}
            type={"button"}
          >
            <span className={"icon-logout"}></span>
            <span>Déconnexion</span>
          </button>
        </nav>
      </Header>

      <LayoutContent>
        <PrivateSidebar expanded={!!expandedSidebar} />

        <ContentView className={"sticky has-sticky-bottom"}>
          <Routes>
            <Route
              element={
                <Navigate
                  to={
                    user.admin
                      ? SUPER_ADMIN_LINK
                      : user.AdministratedCompanyId !== null
                      ? ADMIN_LINK
                      : USER_LINK
                  }
                  replace
                />
              }
              index
            />
            <Route element={<Profile />} path={`${PROFILE}/*`} />

            {user.CompanyId !== null && (
              <Route element={<User />} path={`${USER}/*`} />
            )}

            {user.admin && (
              <Route element={<SuperAdmin />} path={`${SUPER_ADMIN}/*`} />
            )}

            {user.AdministratedCompanyId !== null && (
              <Route element={<Admin />} path={`${ADMIN}/*`} />
            )}
          </Routes>
        </ContentView>
      </LayoutContent>
    </AppLayout>
  );
};

export default withProvideReview(withProvideCompany(Private));
