import { DetailedHTMLProps, FunctionComponent } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<
    React.TableHTMLAttributes<HTMLTableElement>,
    HTMLTableElement
  > {}

const Table: FunctionComponent<Props> = ({ className, ...sectionProps }) => {
  return (
    <div className={"auto-scroll"}>
      <table {...sectionProps} className={cx(className, "table")} />
    </div>
  );
};

export default Table;
