import { ProviderForm, ProviderFormSchema } from "../provider";
import AutoField from "../../forms/AutoField";
import ValidationsErrors from "../../forms/ValidationsErrors";
import SubmitButton from "../../forms/SubmitButton";
import Form from "../../forms/Form";
import { ActionBar } from "../../design-system/base-layout";

interface Props {
  onSubmit: (values: ProviderForm) => Promise<void>;
}

const NewProviderForm = ({ onSubmit }: Props): JSX.Element => {
  return (
    <Form
      initialValues={{}}
      onSubmit={(provider: ProviderForm) => onSubmit(provider)}
      schema={ProviderFormSchema}
    >
      <AutoField name={"name"} />

      <ValidationsErrors />

      <ActionBar>
        <SubmitButton>Enregistrer</SubmitButton>
      </ActionBar>
    </Form>
  );
};

export default NewProviderForm;
