import { ActionBar } from "../../../services/design-system/base-layout";
import Form from "../../../services/forms/Form";
import { object, ref, string } from "yup";
import AutoField from "../../../services/forms/AutoField";
import Section from "../../../services/ui/elements/Section";
import SectionTitle from "../../../services/ui/elements/SectionTitle";
import ContentBlock from "../../../services/ui/elements/ContentBlock";
import ValidationsErrors from "../../../services/forms/ValidationsErrors";
import SubmitButton from "../../../services/forms/SubmitButton";
import InputTip from "../../../services/ui/elements/InputTip";
import { AuthAPIConnected, useAuth } from "../../../services/auth/apiProvider";
import { useToastsWithIntl } from "../../../services/toast-notifications";
import { UserPasswordForm } from "../../../services/auth/user";

const UpdatePasswordSchema = object({
  oldPassword: string().label("Mot de passe actuel").password(),
  newPassword: string()
    .label("Nouveau mot de passe")
    .password()
    .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/),
  newPasswordConf: string()
    .label("Confirmation du nouveau mot de passe")
    .password()
    .equals([ref("newPassword")], "Les mots de passe ne correspondent pas."),
});

const UpdatePassword = (): JSX.Element => {
  const { updateUserPassword } = useAuth() as AuthAPIConnected;
  const { toastError, toastSuccess } = useToastsWithIntl(["auth"]);

  return (
    <div>
      <Form
        initialValues={{}}
        onSubmit={(values: UserPasswordForm) =>
          updateUserPassword(values).then(
            () => toastSuccess("auth:update-user-password.SUCCESS"),
            (error) => {
              if (error.response?.status === 412) {
                toastError(
                  "auth:update-user-password.ERROR_INVALID_OLD_PASSWORD",
                );
              } else {
                toastError("auth:update-user-password.ERROR");
              }
            },
          )
        }
        schema={UpdatePasswordSchema}
      >
        <Section size={"xs"}>
          <SectionTitle title={"Modifier mon mot de passe"} />
          <ContentBlock size={"s"}>
            <AutoField name={"oldPassword"} />
            <AutoField name={"newPassword"}>
              <InputTip>
                8 caractères min. dont au moins une lettre, un chiffre et un
                caractère spécial.
              </InputTip>
            </AutoField>
            <AutoField name={"newPasswordConf"} />
          </ContentBlock>
        </Section>

        <ValidationsErrors />

        <ActionBar>
          <SubmitButton>Enregistrer</SubmitButton>
        </ActionBar>
      </Form>
    </div>
  );
};

export default UpdatePassword;
