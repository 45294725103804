import { useContext, useEffect, useMemo, useState } from "react";
import { ProjectContext } from "../apiProvider";
import Form from "../../forms/Form";
import SelectMaterialField from "../../material/components/SelectMaterialField";
import {
  FullProject,
  ProjectForm as ProjectFormType,
  ProjectSchema,
} from "../project";
import AutoField from "../../forms/AutoField";
import { ActionBar } from "../../design-system/base-layout";
import SubmitButton from "../../forms/SubmitButton";
import ValidationsErrors from "../../forms/ValidationsErrors";
import { CompanyAPILoaded, useCompany } from "../../company/apiProvider";
import { useToastsWithIntl } from "../../toast-notifications";
import { useNavigate } from "react-router-dom";
import { useField, useFormikContext } from "formik";
import Button from "src/services/ui/elements/Button";
import CardHead from "src/services/ui/elements/CardHead";
import Dialog from "src/services/ui/elements/Dialog";
import CardBody from "src/services/ui/elements/CardBody";
import CardFooter from "src/services/ui/elements/CardFooter";
import { PROJECTS_LINK } from "src/routes/user";
import iconTrashWhite from "src/assets/img/icons/icon-trash-white.svg";
import { useConfirmationWithIntl } from "src/services/ui/ConfirmationDialog";
import iconDuplicate from "src/assets/img/icons/icon-duplicate.svg";

const DensityField = () => {
  const { materials } = useCompany() as CompanyAPILoaded;
  const [field] = useField("MaterialId");
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (materials.has(field.value))
      setFieldValue("density", materials.get(field.value)!.density);
    else setFieldValue("density", null);
  }, [materials, field.value, setFieldValue]);

  return <AutoField name={"density"} />;
};

const ProjectForm = (): JSX.Element => {
  const { createProject } = useCompany();
  const projectAPI = useContext(ProjectContext);
  const { toastSuccess, toastError } = useToastsWithIntl(["project"]);
  const navigate = useNavigate();
  const [openedPostCreationModal, setOpenedPostCreationModal] = useState(false);
  const currentDate = useMemo(() => new Date(), []);
  const [project, setProject] = useState<FullProject>();
  const { confirm } = useConfirmationWithIntl(["project"]);
  const deleteProject = projectAPI?.project
    ? projectAPI.deleteProject
    : () => Promise.resolve();

  return (
    <>
      <Form
        initialValues={
          projectAPI?.project
            ? projectAPI.project
            : { ...projectAPI, date: currentDate }
        }
        onSubmit={(form) =>
          projectAPI?.project
            ? projectAPI.updateProject(form).then(
                () => toastSuccess("project:update-project.SUCCESS"),
                () => toastError("project:update-project.ERROR"),
              )
            : createProject(form).then(
                (fullProject) => {
                  setProject(fullProject);
                  setOpenedPostCreationModal(true);
                },
                () => toastError("project:create-project.ERROR"),
              )
        }
        schema={ProjectSchema}
      >
        <AutoField name={"affairId"} />
        <AutoField name={"date"} />

        <AutoField name={"description"} />

        <AutoField name={"address"} />
        <AutoField name={"postalCode"} />
        <AutoField name={"city"} />
        <AutoField name={"country"} />

        <SelectMaterialField name={"MaterialId"} />

        <DensityField />
        <AutoField name={"thickness"} unit={"cm"} />
        <AutoField name={"area"} unit={"m²"} />

        <ValidationsErrors />

        <ActionBar>
          <Button
            onClick={() => navigate(PROJECTS_LINK)}
            type={"button"}
            variant={2}
          >
            Annuler
          </Button>
          <SubmitButton>Enregistrer</SubmitButton>
          {projectAPI?.project && (
            <Button
              onClick={() =>
                confirm(
                  "project:delete-project.CONFIRM",
                  () =>
                    deleteProject().then(
                      () => {
                        toastSuccess("project:delete-project.SUCCESS");
                        navigate(PROJECTS_LINK);
                      },
                      () => toastError("project:delete-project.ERROR"),
                    ),
                  true,
                )
              }
              type={"button"}
              variant={"danger"}
            >
              <img
                alt={"delete icon"}
                className={"icon"}
                src={iconTrashWhite}
              />
            </Button>
          )}
          {projectAPI?.project && (
            <Button
              onClick={() =>
                confirm(
                  "project:duplicate-project.CONFIRM",
                  () =>
                    createProject({
                      ...projectAPI?.project,
                      id: null,
                      MaterialId: null,
                      area: null,
                      thickness: null,
                      density: null,
                      date: currentDate,
                    } as ProjectFormType).then(
                      (fullProject) => {
                        toastSuccess("project:duplicate-project.SUCCESS");
                        navigate(`${PROJECTS_LINK}/${fullProject.id}`);
                      },
                      () => toastError("project:duplicate-project.ERROR"),
                    ),
                  true,
                )
              }
              type={"button"}
              variant={"duplicate"}
            >
              <img
                alt={"duplicate icon"}
                className={"icon"}
                src={iconDuplicate}
              />
            </Button>
          )}
        </ActionBar>
      </Form>
      {openedPostCreationModal && (
        <Dialog>
          <CardHead title={"Projet créé avec succès"} />
          <CardBody>
            <p>Votre projet est désormais créé, vous pouvez maintenant :</p>
            <p>
              1/ Sélectionnez l'onglet « CENTRALES » pour enregistrer les
              commandes passées en centrale.
            </p>
            <p>
              2/ Utilisez l'onglet « LIVRAISONS » pour enregistrer les bons à
              réception sur chantier.
            </p>
            <p>
              3/ Cliquez sur l'onglet « BOUCLAGE » pour recaler vos besoins en
              fonction de la consommation réelle.
            </p>
          </CardBody>
          <CardFooter>
            <Button
              onClick={() => navigate(`${PROJECTS_LINK}/${project?.id}`)}
              type={"button"}
              variant={1}
            >
              OK
            </Button>
          </CardFooter>
        </Dialog>
      )}
    </>
  );
};

export default ProjectForm;
