import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const FormFooter: FunctionComponent<Props> = ({
  className,
  ...formFooterProps
}) => {
  return <div {...formFooterProps} className={cx("form-footer", className)} />;
};

export default FormFooter;
