import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { FullProvider, Provider, ProviderForm } from "./provider";
import { FullTruck, TruckForm } from "../truck/truck";

export function getProviderById(
  providerId: Provider["id"],
): AxiosPromise<FullProvider> {
  return baseAPI.get(`/providers/${providerId}`);
}

export function updateProvider(
  providerId: Provider["id"],
  provider: ProviderForm,
): AxiosPromise<FullProvider> {
  return baseAPI.put(`/providers/${providerId}`, provider);
}

export function deleteProvider(providerId: Provider["id"]): AxiosPromise<void> {
  return baseAPI.delete(`/providers/${providerId}`);
}

export function createTruck(
  providerId: Provider["id"],
  truck: TruckForm,
): AxiosPromise<FullTruck> {
  return baseAPI.post(`/providers/${providerId}/trucks`, truck);
}
