import ProjectForm from "../../../../services/project/components/ProjectForm";
import {
  ProjectAPILoaded,
  useProject,
} from "../../../../services/project/apiProvider";
import { getEstimatedTonnage } from "../../../../services/project/project";
import Section from "../../../../services/ui/elements/Section";

const Infos = (): JSX.Element => {
  const { project } = useProject() as ProjectAPILoaded;
  const adjustedTonnage = getEstimatedTonnage(project);

  return (
    <div>
      <Section>
        <div className={"info info-secondary info-m"}>
          Tonnage prévisionnel : {adjustedTonnage.toFixed(2)}T
        </div>
      </Section>

      <ProjectForm />

      <Section>
        <div className={"info info-secondary info-m"}>
          Tonnage prévisionnel : {adjustedTonnage.toFixed(2)}T
        </div>
      </Section>
    </div>
  );
};

export default Infos;
