import Routes from "../../services/routing/Routes";
import { Navigate, Route } from "react-router-dom";
import Clients from "./super-admin/Clients";
import {
  CLIENTS,
  CLIENTS_LINK,
  REVIEWS,
  USERS,
} from "../../routes/super-admin";
import withProvideSuperAdmin from "../../services/super-admin/apiProvider";
import Users from "./super-admin/Users";
import React from "react";
import Reviews from "./super-admin/Reviews";

const SuperAdmin = (): JSX.Element => {
  return (
    <>
      <Routes>
        <Route element={<Navigate to={CLIENTS_LINK} replace />} index />

        <Route element={<Clients />} path={`${CLIENTS}/*`} />
        <Route element={<Users />} path={USERS} />
        <Route element={<Reviews />} path={`${REVIEWS}/*`} />
      </Routes>
    </>
  );
};

export default withProvideSuperAdmin(SuperAdmin);
