import { parseISO, startOfDay } from "date-fns";
import { date, number, object } from "yup";

export interface SubscriptionBase {
  id: number;
  quantity: number;
  duration: number;
}

export interface SubscriptionRaw extends SubscriptionBase {
  requestStartDate: string;
  validationDate: string | null;
  createdAt: string;
}

export interface Subscription extends SubscriptionBase {
  requestStartDate: Date;
  validationDate: Date | null;
  createdAt: Date;
}

export function mapSubscriptionRawToSubscription(
  subscription: SubscriptionRaw,
): Subscription {
  return {
    ...subscription,
    requestStartDate: parseISO(subscription.requestStartDate),
    validationDate:
      subscription.validationDate !== null
        ? parseISO(subscription.validationDate)
        : subscription.validationDate,
    createdAt: parseISO(subscription.createdAt),
  };
}

export const NewSubscriptionSchema = object({
  quantity: number().label("Quantité").nullable().required().min(0),
  duration: number()
    .label("Durée d'engagement")
    .nullable()
    .required()
    .oneOfEnum([1, 3], "subscription", "duration", false, "radio"),
  requestStartDate: date()
    .label("Date début souhaitée")
    .nullable()
    .required()
    .min(startOfDay(new Date())),
});

export interface NewSubscriptionForm {
  quantity: number;
  duration: number;
  requestStartDate: Date;
}

export type SubscriptionDurationFormType = 1 | 3;

export function getCalendarPrice(
  quantity: Subscription["quantity"],
  duration: SubscriptionDurationFormType,
): number | null {
  switch (duration) {
    case 1:
      switch (quantity) {
        case 1:
          return 120;
        case 2:
          return 109.5;
        case 3:
          return 103.3;
        case 4:
          return 98.9;
        case 5:
          return 95.5;
        case 6:
          return 92.8;
        case 7:
          return 90.4;
        case 8:
          return 88.4;
        case 9:
          return 86.6;
        case 10:
          return 85;
        default:
          return null;
      }
    case 3:
      switch (quantity) {
        case 1:
          return 100;
        case 2:
          return 91.25;
        case 3:
          return 86.1;
        case 4:
          return 82.4;
        case 5:
          return 79.6;
        case 6:
          return 77.3;
        case 7:
          return 75.3;
        case 8:
          return 73.7;
        case 9:
          return 72.2;
        case 10:
          return 70.9;
        default:
          return null;
      }
  }
}

export function getTotalPrice(
  quantity: Subscription["quantity"],
  duration: SubscriptionDurationFormType,
): number | null {
  const price = getCalendarPrice(quantity, duration);
  return price !== null ? quantity * price * 365 * duration : null;
}
