import Dialog from "../../../../../services/ui/elements/Dialog";
import { useNavigate } from "react-router-dom";
import CardHead from "../../../../../services/ui/elements/CardHead";
import CardBody from "../../../../../services/ui/elements/CardBody";
import CardFooter from "../../../../../services/ui/elements/CardFooter";
import ButtonsRow from "../../../../../services/ui/layouts/ButtonsRow";
import Form from "../../../../../services/forms/Form";
import {
  getCalendarPrice,
  getTotalPrice,
  NewSubscriptionForm,
  NewSubscriptionSchema,
  SubscriptionDurationFormType,
} from "../../../../../services/subscription/subscription";
import AutoField from "../../../../../services/forms/AutoField";
import SubmitButton from "../../../../../services/forms/SubmitButton";
import Button from "../../../../../services/ui/elements/Button";
import Section from "../../../../../services/ui/elements/Section";
import ValidationsErrors from "../../../../../services/forms/ValidationsErrors";
import {
  CompanyAPILoaded,
  useCompany,
} from "../../../../../services/company/apiProvider";
import { useToastsWithIntl } from "../../../../../services/toast-notifications";
import { useNumberFormat } from "../../../../../services/number/numberFormatApiProvider";
import { useFormikContext } from "formik";
import { useMemo } from "react";

const SubscriptionPrice = (): JSX.Element => {
  const { toPrice } = useNumberFormat();

  const { values } = useFormikContext<NewSubscriptionForm>();

  const price = useMemo(() => {
    if (!values.quantity || !values.duration) return null;

    const calendarPrice = getCalendarPrice(
      values.quantity,
      values.duration as SubscriptionDurationFormType,
    );
    const totalPrice = getTotalPrice(
      values.quantity,
      values.duration as SubscriptionDurationFormType,
    );

    return { calendarPrice, totalPrice };
  }, [values.quantity, values.duration]);

  return (
    <Section size={"s"}>
      {price !== null &&
        (price.calendarPrice !== null && price.totalPrice !== null ? (
          <div className={"info info-m"}>
            <p>
              Notre tarif : {toPrice(price.calendarPrice)} HT / utilisateur /
              mois
            </p>
          </div>
        ) : (
          <p>Un devis sur mesure vous sera envoyé</p>
        ))}
    </Section>
  );
};

const New = (): JSX.Element => {
  const navigate = useNavigate();
  const { toastSuccess, toastError } = useToastsWithIntl(["subscription"]);
  const { createSubscription } = useCompany() as CompanyAPILoaded;

  return (
    <Dialog className={"theme-light"} onClose={() => navigate("..")}>
      <CardHead title={"Demande de devis"} />

      <Form
        initialValues={{}}
        onSubmit={(values: NewSubscriptionForm) =>
          createSubscription(values).then(
            () => {
              toastSuccess("subscription:create-subscription.success");
              navigate("..");
            },
            () => toastError("subscription:create-subscription.error"),
          )
        }
        schema={NewSubscriptionSchema}
      >
        <CardBody>
          <AutoField name={"quantity"} />
          <AutoField name={"duration"} />
          <AutoField name={"requestStartDate"} />

          <SubscriptionPrice />

          <ValidationsErrors />
        </CardBody>
        <CardFooter>
          <ButtonsRow>
            <Button onClick={() => navigate("..")} type={"button"} variant={2}>
              Annuler
            </Button>
            <SubmitButton>Envoyer</SubmitButton>
          </ButtonsRow>
        </CardFooter>
      </Form>
    </Dialog>
  );
};

export default New;
