import {
  ProjectAPILoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import { useDate } from "../../../../../services/date/DateContext";
import Table from "../../../../../services/ui/elements/Table";

const WrapUps = (): JSX.Element => {
  const { project } = useProject() as ProjectAPILoaded;
  const { formatDate } = useDate();

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Date</th>
            <th>
              Surface restante lors du bouclage (m<sup>2</sup>)
            </th>
            <th>Tonnage prévisionnel recalé (T)</th>
          </tr>
        </thead>
        <tbody>
          {project.WrapUps.map((wu) => (
            <tr key={wu.id}>
              <td>{formatDate(wu.createdAt)}</td>
              <td>{wu.remainingSurface}</td>
              <td>{wu.adjustedTonnage}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default WrapUps;
