export const PRIVATE = "/private" as const;

export const USER = "user" as const;
export const USER_LINK = `${PRIVATE}/${USER}` as const;

export const ADMIN = "admin" as const;
export const ADMIN_LINK = `${PRIVATE}/${ADMIN}` as const;

export const SUPER_ADMIN = "super-admin" as const;
export const SUPER_ADMIN_LINK = `${PRIVATE}/${SUPER_ADMIN}` as const;

export const PROFILE = "/profile" as const;
export const PROFILE_LINK = `${PRIVATE}${PROFILE}` as const;
