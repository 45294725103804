import Section from "../../../../services/ui/elements/Section";
import SectionTitle from "../../../../services/ui/elements/SectionTitle";
import Form from "../../../../services/forms/Form";
import { UsersToInviteSchema } from "../../../../services/super-admin/user";
import AutoField from "../../../../services/forms/AutoField";
import InputTip from "../../../../services/ui/elements/InputTip";
import ValidationsErrors from "../../../../services/forms/ValidationsErrors";
import FormFooter from "../../../../services/forms/FormFooter";
import SubmitButton from "../../../../services/forms/SubmitButton";
import { useCompany } from "../../../../services/company/apiProvider";
import { useToastsWithIntl } from "../../../../services/toast-notifications";

const InviteUsers = (): JSX.Element => {
  const { inviteUsers } = useCompany();
  const { toastSuccess, toastError } = useToastsWithIntl(["company", "auth"]);

  return (
    <div>
      <Section>
        <SectionTitle title={"Inviter des utilisateurs sur votre compte"} />

        <Section size={"s"}>
          <Form
            initialValues={{ users: "" }}
            onSubmit={({ users }) =>
              inviteUsers(users.split("\n")).then(
                () => toastSuccess("company:invite-users.SUCCESS"),
                () => {
                  toastError("company:invite-users.ERROR");
                  return Promise.reject();
                },
              )
            }
            schema={UsersToInviteSchema}
            resetFormOnSubmit
          >
            <AutoField name={"users"}>
              <InputTip>Une adresse e-mail par ligne</InputTip>
            </AutoField>

            <ValidationsErrors />

            <FormFooter>
              <SubmitButton>Envoyer</SubmitButton>
            </FormFooter>
          </Form>
        </Section>
      </Section>
    </div>
  );
};

export default InviteUsers;
