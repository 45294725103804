import { DetailedHTMLProps, FunctionComponent, HTMLAttributes } from "react";
import { cx } from "@emotion/css";

interface Props
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: "l";
}

const Card: FunctionComponent<Props> = ({ className, size, ...cardProps }) => {
  return (
    <div
      {...cardProps}
      className={cx("card", size && `card-${size}`, className)}
    />
  );
};

export default Card;
