import { fr } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import {
  format,
  Locale,
  formatDistanceToNow as dfFormatDistanceToNow,
  formatDuration as dfFormatDuration,
} from "date-fns";
import { useCallback, useMemo } from "react";

interface DateAPI {
  locale: Locale;
  formatDate: (
    date: Date,
    dateFormat?: string,
    options?: DateFNSFormatOptions,
  ) => string;
  formatDistanceToNow: (
    date: Date | number,
    options?: {
      includeSeconds?: boolean;
      addSuffix?: boolean;
    },
  ) => string;
  formatDuration: (
    duration: Duration,
    options?: {
      format?: string[];
      zero?: boolean;
      delimiter?: string;
    },
  ) => string;
}

type DateFNSFormatOptions = {
  locale?: Locale;
  weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
  firstWeekContainsDate?: number;
  useAdditionalWeekYearTokens?: boolean;
  useAdditionalDayOfYearTokens?: boolean;
};

const LOCALES: Record<string, Locale> = {
  fr: fr,
};

export function useDate(): DateAPI {
  const { i18n } = useTranslation();

  const locale = useMemo(() => LOCALES[i18n.language] || fr, [i18n]);

  const formatDate = useCallback(
    (
      date: Date,
      dateFormat: string = "PP",
      options: DateFNSFormatOptions = { locale },
    ) => {
      return format(date, dateFormat, options);
    },
    [locale],
  );

  const formatDistanceToNow: DateAPI["formatDistanceToNow"] = useCallback(
    (date, options) => dfFormatDistanceToNow(date, { ...options, locale }),
    [locale],
  );

  const formatDuration: DateAPI["formatDuration"] = useCallback(
    (duration, options) => dfFormatDuration(duration, { ...options, locale }),
    [locale],
  );

  return {
    locale,
    formatDate,
    formatDistanceToNow,
    formatDuration,
  };
}
