import {
  Tab,
  Tabs,
  ViewName,
} from "../../../../services/design-system/base-layout";
import { useMemo } from "react";
import {
  ORDERS_AND_DELIVERIES,
  ORDERS_AND_DELIVERIES_LINK,
  REPORT,
  REPORT_LINK,
  TRUCKS,
  TRUCKS_LINK,
  WRAP_UPS,
  WRAP_UPS_LINK,
} from "../../../../routes/user/recap";
import Routes from "../../../../services/routing/Routes";
import { Route, Navigate } from "react-router-dom";
import OrdersAndDeliveries from "./recap/OrdersAndDeliveries";
import {
  ProjectAPILoaded,
  useProject,
} from "../../../../services/project/apiProvider";
import Trucks from "./recap/Trucks";
import WrapUps from "./recap/WrapUps";
import Report from "./recap/Report";

const Recap = (): JSX.Element => {
  const { project } = useProject() as ProjectAPILoaded;

  const tabs = useMemo<
    Tab<
      | typeof ORDERS_AND_DELIVERIES_LINK
      | typeof TRUCKS_LINK
      | typeof WRAP_UPS_LINK
      | typeof REPORT_LINK
    >[]
  >(
    () => [
      {
        name: "Écart chantier",
        linkProps: {
          to: ORDERS_AND_DELIVERIES_LINK,
          params: { projectId: project.id },
        },
      },
      {
        name: "Tours par camion",
        linkProps: {
          to: TRUCKS_LINK,
          params: { projectId: project.id },
        },
      },
      {
        name: "Historique des bouclages",
        linkProps: {
          to: WRAP_UPS_LINK,
          params: { projectId: project.id },
        },
      },
      {
        name: "Transmission rapport",
        linkProps: {
          to: REPORT_LINK,
          params: { projectId: project.id },
        },
      },
    ],
    [project],
  );

  return (
    <div>
      <ViewName name={"Récapitulatif"} />
      <Tabs tabs={tabs} />

      <Routes>
        <Route
          element={<Navigate to={ORDERS_AND_DELIVERIES} replace />}
          index
        />
        <Route element={<OrdersAndDeliveries />} path={ORDERS_AND_DELIVERIES} />
        <Route element={<Trucks />} path={`${TRUCKS}/*`} />
        <Route element={<WrapUps />} path={WRAP_UPS} />
        <Route element={<Report />} path={REPORT} />
      </Routes>
    </div>
  );
};

export default Recap;
