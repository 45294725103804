import { FullMaterial, Material, MaterialForm } from "./material";
import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";

export function getMaterialById(
  materialId: Material["id"],
): AxiosPromise<FullMaterial> {
  return baseAPI.get(`/materials/${materialId}`);
}

export function updateMaterial(
  materialId: Material["id"],
  material: MaterialForm,
): AxiosPromise<FullMaterial> {
  return baseAPI.put(`/materials/${materialId}`, material);
}

export function deleteMaterial(materialId: Material["id"]): AxiosPromise<void> {
  return baseAPI.delete(`/materials/${materialId}`);
}
