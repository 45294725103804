import { Company, CompanyUpdateForm, FullCompany } from "./company";
import { AxiosPromise } from "axios";
import baseAPI from "../auth/api";
import { FullMaterial, Material, MaterialForm } from "../material/material";
import { FullTruck } from "../truck/truck";
import { DepartmentForm, FullDepartment } from "../department/department";
import { FullProjectRaw, ProjectForm, ProjectRaw } from "../project/project";
import { UserRaw } from "../auth/user";
import { FullProvider, Provider, ProviderForm } from "../provider/provider";
import {
  NewSubscriptionForm,
  SubscriptionRaw,
} from "../subscription/subscription";

export function getCompanyById(
  companyId: Company["id"],
): AxiosPromise<FullCompany> {
  return baseAPI.get(`/companies/${companyId}`);
}

export function updateCompany(
  companyId: Company["id"],
  company: CompanyUpdateForm,
): AxiosPromise<FullCompany> {
  return baseAPI.put(`/companies/${companyId}`, company);
}

export function getMaterials(
  companyId: Company["id"],
): AxiosPromise<Material[]> {
  return baseAPI.get(`/companies/${companyId}/materials`);
}

export function createMaterial(
  companyId: Company["id"],
  material: MaterialForm,
): AxiosPromise<FullMaterial> {
  return baseAPI.post(`/companies/${companyId}/materials`, material);
}

export function getTrucks(companyId: Company["id"]): AxiosPromise<FullTruck[]> {
  return baseAPI.get(`/companies/${companyId}/trucks`);
}
export function getDepartments(
  companyId: Company["id"],
): AxiosPromise<FullDepartment[]> {
  return baseAPI.get(`/companies/${companyId}/departments`);
}

export function createDepartment(
  companyId: Company["id"],
  department: DepartmentForm,
): AxiosPromise<FullDepartment> {
  return baseAPI.post(`/companies/${companyId}/departments`, department);
}

export function getProviders(
  companyId: Company["id"],
): AxiosPromise<Provider[]> {
  return baseAPI.get(`/companies/${companyId}/providers`);
}

export function createProvider(
  companyId: Company["id"],
  provider: ProviderForm,
): AxiosPromise<FullProvider> {
  return baseAPI.post(`/companies/${companyId}/providers`, provider);
}

export function getProjects(
  companyId: Company["id"],
): AxiosPromise<ProjectRaw[]> {
  return baseAPI.get(`/companies/${companyId}/projects`);
}

export function createProject(
  companyId: Company["id"],
  project: ProjectForm,
): AxiosPromise<FullProjectRaw> {
  return baseAPI.post(`/companies/${companyId}/projects`, project);
}

export function getUsers(companyId: Company["id"]): AxiosPromise<UserRaw[]> {
  return baseAPI.get(`/companies/${companyId}/users`);
}

export function inviteUsers(
  companyId: Company["id"],
  emails: string[],
): AxiosPromise<void> {
  return baseAPI.post(`/companies/${companyId}/invite-users`, emails);
}

export function getAdministrators(
  companyId: Company["id"],
): AxiosPromise<UserRaw[]> {
  return baseAPI.get(`/companies/${companyId}/administrators`);
}

export function getSubscriptions(
  companyId: Company["id"],
): AxiosPromise<SubscriptionRaw[]> {
  return baseAPI.get(`/companies/${companyId}/subscriptions`);
}

export function createSubscription(
  companyId: Company["id"],
  subscriptionForm: NewSubscriptionForm,
): AxiosPromise<SubscriptionRaw> {
  return baseAPI.post(
    `/companies/${companyId}/subscriptions`,
    subscriptionForm,
  );
}
