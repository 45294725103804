import {
  CompanyAPILoaded,
  useCompany,
  withProvideCompany,
} from "../../../services/company/apiProvider";
import { useParams } from "../../../services/routing/useParams";
import React, { useCallback, useMemo } from "react";
import { withLoader } from "../../../services/routing/useLoader";
import {
  Tab,
  Tabs,
  ViewName,
} from "../../../services/design-system/base-layout";
import Routes from "../../../services/routing/Routes";
import Index from "./client/Index";
import {
  CLIENT_LINK,
  SUBSCRIPTIONS,
  SUBSCRIPTIONS_LINK,
  USERS,
  USERS_LINK,
} from "../../../routes/super-admin/clients";
import { Route } from "react-router-dom";
import Users from "./client/Users";
import Subscriptions from "./client/Subscriptions";

const Client = (): JSX.Element => {
  const { company } = useCompany() as CompanyAPILoaded;
  const tabs = useMemo<
    Tab<typeof CLIENT_LINK | typeof USERS_LINK | typeof SUBSCRIPTIONS_LINK>[]
  >(
    () => [
      {
        name: "Infos client",
        linkProps: {
          to: CLIENT_LINK,
          params: { clientId: company.id },
          end: true,
        },
      },
      {
        name: "Utilisateurs",
        linkProps: {
          to: USERS_LINK,
          params: { clientId: company.id },
        },
      },
      {
        name: "Abonnements",
        linkProps: {
          to: SUBSCRIPTIONS_LINK,
          params: { clientId: company.id },
        },
      },
    ],
    [company.id],
  );

  return (
    <div>
      <ViewName name={company.name} />
      <Tabs tabs={tabs} />

      <Routes>
        <Route element={<Index />} index />
        <Route element={<Users />} path={USERS} />
        <Route element={<Subscriptions />} path={SUBSCRIPTIONS} />
      </Routes>
    </div>
  );
};

function useLoad() {
  const { loadCompanyById } = useCompany();
  const clientId = Number(useParams<typeof CLIENT_LINK>().clientId);

  return useCallback(() => loadCompanyById(clientId), [
    loadCompanyById,
    clientId,
  ]);
}

export default withProvideCompany(withLoader(Client, useLoad));
