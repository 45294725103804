import Routes from "../../services/routing/Routes";
import { Route, Navigate } from "react-router-dom";
import { CLIENT, CLIENT_LINK } from "../../routes/admin";
import Client from "./admin/Client";

const Admin = (): JSX.Element => {
  return (
    <>
      <Routes>
        <Route element={<Navigate to={CLIENT_LINK} replace />} index />
        <Route element={<Client />} path={`${CLIENT}/*`} />
      </Routes>
    </>
  );
};

export default Admin;
