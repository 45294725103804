import { useMemo, useState } from "react";

export function orderByField<P, K extends keyof P>(
  field: K,
  asc?: boolean,
): (a: P, b: P) => number {
  return asc
    ? function (a: P, b: P) {
        return a[field] > b[field] ? -1 : 1;
      }
    : function (a: P, b: P) {
        return a[field] > b[field] ? 1 : -1;
      };
}

export function deepSearch<E>(element: E, word: string): boolean {
  if (!element) return false;
  if (typeof element === "string") {
    return element.toLowerCase().indexOf(word) >= 0;
  } else if (typeof element === "number") {
    return (
      element.toString(10).indexOf(word) >= 0 || parseFloat(word) === element
    );
  } else if (Array.isArray(element)) {
    return element.some((subElement) => deepSearch(subElement, word));
  } else if (typeof element === "object") {
    const properties = Object.getOwnPropertyNames(element) as (keyof E)[];
    for (const i in properties) {
      if (deepSearch(element[properties[i]], word)) return true;
    }
  }
  return false;
}

export function deepSearchStart<E>(element: E, word: string): boolean {
  if (!element) return false;
  if (typeof element === "string") {
    return element.toLowerCase().startsWith(word);
  } else if (typeof element === "number") {
    return (
      element.toString(10).startsWith(word) || parseFloat(word) === element
    );
  } else if (Array.isArray(element)) {
    return element.some((subElement) => deepSearchStart(subElement, word));
  } else if (typeof element === "object") {
    const properties = Object.getOwnPropertyNames(element) as (keyof E)[];
    for (const i in properties) {
      if (deepSearchStart(element[properties[i]], word)) return true;
    }
  }
  return false;
}

export function searchGenerator<E>(
  searcher: (element: unknown, text: string) => boolean,
  exclusive: boolean | undefined = true,
): (
  expression: string,
  mapper?: (element: E) => unknown,
) => (element: E) => boolean {
  return (expression: string, mapper?: (element: E) => unknown) => {
    if (expression.length === 0) return () => true;
    const words = expression
      .split(" ")
      .map((e) => e.toLowerCase())
      .filter((s) => s.length !== 0);

    const caller = exclusive
      ? (...params: Parameters<Array<string>["every"]>) =>
          words.every(...params)
      : (...params: Parameters<Array<string>["every"]>) =>
          words.some(...params);

    return mapper
      ? (element: E) => {
          const mappedElement = mapper(element);
          return caller((w) => searcher(mappedElement, w));
        }
      : (element: E) => caller((w) => searcher(element, w));
  };
}

export function useSearcher<E>(
  searcher: (element: E, text: string) => boolean,
  baseList: E[],
  mapper?: (element: E) => unknown,
  exclusive: boolean | undefined = true,
): [E[], (search: string) => void] {
  const [search, setSearch] = useState<string>("");

  const filter = useMemo(
    () => searchGenerator(searcher, exclusive)(search, mapper),
    [search, mapper, searcher, exclusive],
  );

  const newList = useMemo(() => baseList.filter(filter), [baseList, filter]);

  return [newList, setSearch];
}

export function useSearch<E>(
  baseList: E[],
  mapper?: (element: E) => unknown,
): [E[], (search: string) => void] {
  return useSearcher(deepSearch, baseList, mapper);
}

export function useSearchStart<E>(
  baseList: E[],
  mapper?: (element: E) => unknown,
): [E[], (search: string) => void] {
  return useSearcher(deepSearchStart, baseList, mapper, false);
}

export function spliceReturn<T>(array: Array<T>, index: number): Array<T> {
  const newArray = array.slice(0);
  newArray.splice(index, 1);
  return newArray;
}

export function replaceInArray<T>(
  array: Array<T>,
  index: number,
  newObject: T,
): Array<T> {
  const newArray = array.slice(0);
  newArray[index] = newObject;
  return newArray;
}

export function compareBoolean(boolA: boolean, boolB: boolean): number {
  return boolA === boolB ? 0 : boolA ? -1 : 1;
}

export function compareString(stringA: string, stringB: string): number {
  return stringA.localeCompare(stringB);
}

export function compareStringNullable(
  stringA: string | null,
  stringB: string | null,
): number {
  if (stringA === null && stringB === null) return 0;
  if (stringA === null) return 1;
  if (stringB === null) return -1;

  return compareString(stringA, stringB);
}
