import React, { useMemo } from "react";
import { PROFILE_LINK } from "../../routes/private";
import {
  Content,
  Head,
  Tabs,
  ViewName,
  withProvideHead,
} from "../../services/design-system/base-layout";
import PageContent from "../../services/ui/elements/PageContent";
import Routes from "../../services/routing/Routes";
import { Route } from "react-router-dom";
import Index from "./profile/Index";
import UpdatePassword from "./profile/UpdatePassword";
import { UPDATE_PASSWORD, UPDATE_PASSWORD_LINK } from "../../routes/profile";

const Profile: React.FC = () => {
  const tabs = useMemo(
    () => [
      {
        name: "Informations personnelles",
        linkProps: { to: PROFILE_LINK, end: true },
      },
      {
        name: "Mot de passe",
        linkProps: { to: UPDATE_PASSWORD_LINK },
      },
    ],
    [],
  );

  return (
    <>
      <ViewName name={"Profil"} />
      <Tabs tabs={tabs} />
      <Head />

      <Content>
        <PageContent>
          <Routes>
            <Route element={<Index />} index />
            <Route element={<UpdatePassword />} path={UPDATE_PASSWORD} />
          </Routes>
        </PageContent>
      </Content>
    </>
  );
};

export default withProvideHead(Profile);
