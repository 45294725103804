import { useCompany } from "../../../../services/company/apiProvider";
import { withLoader } from "../../../../services/routing/useLoader";
import { useDate } from "../../../../services/date/DateContext";
import Section from "../../../../services/ui/elements/Section";
import Table from "../../../../services/ui/elements/Table";
import Button from "../../../../services/ui/elements/Button";
import { useToastsWithIntl } from "../../../../services/toast-notifications";
import {
  addMonths,
  compareAsc,
  eachDayOfInterval,
  isFuture,
  isPast,
} from "date-fns";
import { useMemo } from "react";

const Subscriptions = (): JSX.Element => {
  const {
    subscriptions,
    validateSubscription,
    extendTrialPeriod,
  } = useCompany();
  const { formatDate, formatDuration } = useDate();
  const { toastSuccess, toastError } = useToastsWithIntl(["subscription"]);

  const trial = useMemo(
    () =>
      [...subscriptions.values()].sort((a, b) =>
        compareAsc(a.createdAt, b.createdAt),
      )[0],
    [subscriptions],
  );

  return (
    <div>
      {!!trial && !!trial.validationDate && (
        <Section>
          <div className={"info info-secondary info-m"}>
            <p>
              <strong>Période d'essai :</strong>{" "}
              {isPast(addMonths(trial.validationDate!, trial.duration))
                ? "Expiré"
                : "En cours"}
            </p>
            <p>
              <strong>Date de début :</strong>{" "}
              {formatDate(trial.validationDate!)} /{" "}
              <strong>Date de fin :</strong>{" "}
              {formatDate(addMonths(trial.validationDate!, trial.duration))}{" "}
              {isFuture(addMonths(trial.validationDate!, trial.duration)) && (
                <>
                  / <strong>Durée restante :</strong>{" "}
                  {formatDuration(
                    {
                      days: eachDayOfInterval({
                        start: Date.now(),
                        end: addMonths(trial.validationDate!, trial.duration),
                      }).length,
                    },
                    { zero: true, format: ["days"] },
                  )}
                </>
              )}
            </p>
            <Button
              onClick={() =>
                extendTrialPeriod(trial.id).then(
                  () =>
                    toastSuccess("subscription:extend-trial-period.success"),
                  () => toastError("subscription:extend-trial-period.error"),
                )
              }
            >
              Prolonger l'abonnement de 1 mois
            </Button>
          </div>
        </Section>
      )}

      <Section>
        <Table>
          <thead>
            <tr>
              <th>Date demande</th>
              <th>Nombre</th>
              <th>Durée</th>
              <th>Date souhaitée</th>
              <th>Date début</th>
              <th>Date fin</th>
              <th>Durée restante</th>
              <th>Statut</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {[...subscriptions.values()].map((s) => (
              <tr key={s.id}>
                <td>{formatDate(s.createdAt)}</td>
                <td>{s.quantity}</td>
                <td>{s.duration} mois</td>
                <td>{formatDate(s.requestStartDate)}</td>
                <td>
                  {s.validationDate !== null && formatDate(s.validationDate)}
                </td>
                <td>
                  {s.validationDate !== null &&
                    formatDate(addMonths(s.validationDate, s.duration))}
                </td>
                <td>
                  {s.validationDate !== null &&
                    (isFuture(addMonths(s.validationDate, s.duration))
                      ? formatDuration(
                          {
                            days: eachDayOfInterval({
                              start: Date.now(),
                              end: addMonths(s.validationDate, s.duration),
                            }).length,
                          },
                          { zero: true, format: ["days"] },
                        )
                      : "Terminé")}
                </td>
                <td>
                  {s.validationDate !== null
                    ? isPast(addMonths(s.validationDate, s.duration))
                      ? "Expirée"
                      : "Actif"
                    : "Attente règlement"}
                </td>
                <td>
                  {s.validationDate === null && (
                    <Button
                      onClick={() =>
                        validateSubscription(s.id).then(
                          () =>
                            toastSuccess(
                              "subscription:validate-subscription.success",
                            ),
                          () =>
                            toastError(
                              "subscription:validate-subscription.error",
                            ),
                        )
                      }
                    >
                      Valider
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </div>
  );
};

const useLoad = () => {
  const { loadSubscriptions } = useCompany();

  return loadSubscriptions;
};

export default withLoader(Subscriptions, useLoad);
