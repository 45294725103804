import { ViewName } from "../../../../../services/design-system/base-layout";
import Form from "../../../../../services/forms/Form";
import {
  DeliverySlipForm,
  DeliverySlipSchema,
} from "../../../../../services/project/deliverySlip";
import AutoField from "../../../../../services/forms/AutoField";
import SubmitButton from "../../../../../services/forms/SubmitButton";
import ValidationsErrors from "../../../../../services/forms/ValidationsErrors";
import SelectDepartmentField from "../../../../../services/department/components/SelectDepartmentField";
import SelectTruckField from "../../../../../services/truck/components/SelectTruckField";
import {
  ProjectAPILoaded,
  useProject,
} from "../../../../../services/project/apiProvider";
import { useToastsWithIntl } from "../../../../../services/toast-notifications";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SelectProviderField from "src/services/provider/components/SelectProviderField";
import { useMemo } from "react";

const New = (): JSX.Element => {
  const { createDeliverySlip } = useProject() as ProjectAPILoaded;
  const { toastSuccess, toastError } = useToastsWithIntl(["project"]);
  const navigate = useNavigate();

  const currentDate = useMemo(() => new Date(), []);

  return (
    <div>
      <ViewName name={"Nouveau bon de livraison"} />

      <Form
        initialValues={{ deliveryDate: currentDate }}
        onSubmit={(values: DeliverySlipForm) => {
          return createDeliverySlip(values).then(
            () => {
              toastSuccess("project:create-delivery-slip.SUCCESS");
              navigate("..");
            },
            (error) => {
              if (axios.isAxiosError(error) && error.response?.status === 409) {
                toastError(
                  "project:create-delivery-slip.TONNAGE_HIGER_THAN_ORDERED",
                );
              } else {
                toastError("project:create-delivery-slip.ERROR");
              }
            },
          );
        }}
        schema={DeliverySlipSchema}
      >
        <SelectDepartmentField name={"DepartmentId"} />
        <AutoField name={"tonnageDelivered"} unit={"T"} />
        <AutoField name={"deliveryDate"} />
        <SelectProviderField name={"ProviderId"} />
        <SelectTruckField name={"TruckId"} />
        <AutoField name={"loadingDate"} />
        <AutoField name={"deliverySlipDepartmentNumber"} />

        <ValidationsErrors />

        <SubmitButton>Enregistrer</SubmitButton>
      </Form>
    </div>
  );
};

export default New;
