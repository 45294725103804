import {
  ComponentType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import {
  DepartmentRecap,
  FullProject,
  mapProjectRawToProject,
  Project,
  ProjectForm,
  TruckRecap,
} from "./project";
import {
  getProjectById,
  createWrapUp as apiCreateWrapUp,
  createOrder as apiCreateOrder,
  deleterOrder as apiDeleteOrder,
  createDeliverySlip as apiCreateDeliverySlip,
  updateDeliverySlip as apiUpdateDeliverySlip,
  deleterDeliverySlip as apiDeleteDeliverySlip,
  validateDeliverySlip as apiValidateDeliverySlip,
  invalidateDeliverySlip as apiInvalidateDeliverySlip,
  syncDeliverySlips as apiSyncDeliverySlips,
  unsyncDeliverySlips as apiUnsyncDeliverySlips,
  sendReport as apiSendReport,
  getDepartmentsRecap,
  getTrucksRecap,
  updateProject as apiUpdateProject,
  deleteProject as apiDeleteProject,
} from "./api";
import { WrapUpForm } from "./wrap-up";
import { Order, OrderForm } from "./order";
import { spliceReturn, replaceInArray } from "../data-structures/array";
import {
  DeliverySlip,
  DeliverySlipForm,
  mapDeliverySlipFormToDeliverySlipFormRaw,
  mapDeliverySlipRawToDeliverySlip,
} from "./deliverySlip";

export interface ProjectAPI {
  project: FullProject | null;

  loadProjectById(projectId: Project["id"]): Promise<void>;
  updateProject(form: ProjectForm): Promise<void>;
  deleteProject(): Promise<void>;

  createWrapUp(wrapUpForm: WrapUpForm): Promise<void>;

  createOrder(order: OrderForm): Promise<void>;
  deleteOrder(orderId: Order["id"]): Promise<void>;

  createDeliverySlip(deliverySlip: DeliverySlipForm): Promise<void>;
  updateDeliverySlip(
    deliverySlipId: DeliverySlip["id"],
    deliverySlip: DeliverySlipForm,
  ): Promise<void>;
  deleteDeliverySlip(deliverySlipId: DeliverySlip["id"]): Promise<void>;
  validateDeliverySlip(deliverySlipId: DeliverySlip["id"]): Promise<void>;
  invalidateDeliverySlip(deliverySlipId: DeliverySlip["id"]): Promise<void>;
  syncDeliverySlips(deliverySlipId: DeliverySlip["id"]): Promise<void>;
  unsyncDeliverySlips(deliverySlipId: DeliverySlip["id"]): Promise<void>;

  departmentsRecap: DepartmentRecap[] | null;
  loadDepartmentsRecap(): Promise<void>;

  trucksRecap: TruckRecap[] | null;
  loadTrucksRecap(): Promise<void>;

  sendReport(users: string[]): Promise<void>;
}

export interface ProjectAPILoaded extends ProjectAPI {
  project: NonNullable<ProjectAPI["project"]>;
}

export interface ProjectAPIDepartmentsRecapLoaded extends ProjectAPILoaded {
  departmentsRecap: NonNullable<ProjectAPILoaded["departmentsRecap"]>;
}

export interface ProjectAPITrucksRecapLoaded extends ProjectAPILoaded {
  trucksRecap: NonNullable<ProjectAPILoaded["trucksRecap"]>;
}

export const ProjectContext = createContext<ProjectAPI | null>(null);

export function useProject(): ProjectAPI {
  return useContext(ProjectContext) as ProjectAPI;
}

export const ProvideProject = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [project, setProject] = useState<ProjectAPI["project"]>(null);

  const loadProjectById: ProjectAPI["loadProjectById"] = useCallback(
    (projectId) => {
      return getProjectById(projectId).then(({ data }) => {
        setProject(mapProjectRawToProject(data));
      });
    },
    [],
  );

  const updateProject: ProjectAPI["updateProject"] = useCallback(
    (form) =>
      apiUpdateProject(
        (project as ProjectAPILoaded["project"]).id,
        form,
      ).then(({ data }) => setProject(mapProjectRawToProject(data))),
    [project],
  );

  const deleteProject: ProjectAPI["deleteProject"] = useCallback(
    () =>
      apiDeleteProject((project as ProjectAPILoaded["project"]).id).then(() =>
        Promise.resolve(),
      ),
    [project],
  );

  const createWrapUp: ProjectAPI["createWrapUp"] = useCallback(
    (wrapUpForm) => {
      return apiCreateWrapUp(
        (project as ProjectAPILoaded["project"]).id,
        wrapUpForm,
      ).then(({ data }) => {
        setProject(mapProjectRawToProject(data));
      });
    },
    [project],
  );

  const createOrder: ProjectAPI["createOrder"] = useCallback(
    (order) => {
      return apiCreateOrder(
        (project as NonNullable<typeof project>).id,
        order,
      ).then(({ data }) => {
        setProject(mapProjectRawToProject(data));
      });
    },
    [project],
  );

  const deleteOrder: ProjectAPI["deleteOrder"] = useCallback((orderId) => {
    return apiDeleteOrder(orderId).then(() => {
      setProject((prevState: FullProject) => ({
        ...prevState,
        Orders: spliceReturn(
          prevState.Orders,
          prevState.Orders.findIndex((order) => order.id === orderId),
        ),
      }));
    });
  }, []);

  const createDeliverySlip: ProjectAPI["createDeliverySlip"] = useCallback(
    (deliverySlip) => {
      return apiCreateDeliverySlip(
        (project as NonNullable<typeof project>).id,
        mapDeliverySlipFormToDeliverySlipFormRaw(deliverySlip),
      ).then(({ data }) => {
        setProject(mapProjectRawToProject(data));
      });
    },
    [project],
  );

  const updateDeliverySlip: ProjectAPI["updateDeliverySlip"] = useCallback(
    (deliverySlipId, deliverySlip) => {
      return apiUpdateDeliverySlip(
        deliverySlipId,
        mapDeliverySlipFormToDeliverySlipFormRaw(deliverySlip),
      ).then(({ data }) => {
        setProject((prevState: FullProject) => ({
          ...prevState,
          DeliverySlips: replaceInArray(
            prevState.DeliverySlips,
            prevState.DeliverySlips.findIndex(
              (deliverySlip) => deliverySlip.id === deliverySlipId,
            ),
            mapDeliverySlipRawToDeliverySlip(data),
          ),
        }));
      });
    },
    [],
  );

  const deleteDeliverySlip: ProjectAPI["deleteDeliverySlip"] = useCallback(
    (deliverySlipId) => {
      return apiDeleteDeliverySlip(deliverySlipId).then(() => {
        setProject((prevState: FullProject) => ({
          ...prevState,
          DeliverySlips: spliceReturn(
            prevState.DeliverySlips,
            prevState.DeliverySlips.findIndex(
              (deliverySlip) => deliverySlip.id === deliverySlipId,
            ),
          ),
        }));
      });
    },
    [],
  );

  const validateDeliverySlip: ProjectAPI["validateDeliverySlip"] = useCallback(
    (deliverySlipId) => {
      return apiValidateDeliverySlip(deliverySlipId).then(({ data }) => {
        setProject((prevState: FullProject) => ({
          ...prevState,
          DeliverySlips: replaceInArray(
            prevState.DeliverySlips,
            prevState.DeliverySlips.findIndex(
              (deliverySlip) => deliverySlip.id === deliverySlipId,
            ),
            mapDeliverySlipRawToDeliverySlip(data),
          ),
        }));
      });
    },
    [],
  );

  const invalidateDeliverySlip: ProjectAPI["invalidateDeliverySlip"] = useCallback(
    (deliverySlipId) => {
      return apiInvalidateDeliverySlip(deliverySlipId).then(({ data }) => {
        setProject((prevState: FullProject) => ({
          ...prevState,
          DeliverySlips: replaceInArray(
            prevState.DeliverySlips,
            prevState.DeliverySlips.findIndex(
              (deliverySlip) => deliverySlip.id === deliverySlipId,
            ),
            mapDeliverySlipRawToDeliverySlip(data),
          ),
        }));
      });
    },
    [],
  );

  const syncDeliverySlips: ProjectAPI["syncDeliverySlips"] = useCallback(
    (projectId) => {
      return apiSyncDeliverySlips(projectId).then(({ data }) => {
        setProject((prevState: FullProject) => ({
          ...prevState,
          syncStartDate: data.syncStartDate,
        }));
      });
    },
    [],
  );

  const unsyncDeliverySlips: ProjectAPI["syncDeliverySlips"] = useCallback(
    (projectId) => {
      return apiUnsyncDeliverySlips(projectId).then(({ data }) => {
        setProject((prevState: FullProject) => ({
          ...prevState,
          syncStartDate: data.syncStartDate,
        }));
      });
    },
    [],
  );

  const [departmentsRecap, setDepartmentsRecap] = useState<
    ProjectAPI["departmentsRecap"]
  >(null);
  const loadDepartmentsRecap: ProjectAPI["loadDepartmentsRecap"] = useCallback(() => {
    return getDepartmentsRecap(
      (project as ProjectAPILoaded["project"]).id,
    ).then(({ data }) => {
      setDepartmentsRecap(data);
    });
  }, [project]);

  const [trucksRecap, setTrucksRecap] = useState<ProjectAPI["trucksRecap"]>(
    null,
  );
  const loadTrucksRecap: ProjectAPI["loadTrucksRecap"] = useCallback(() => {
    return getTrucksRecap((project as ProjectAPILoaded["project"]).id).then(
      ({ data }) => {
        setTrucksRecap(data);
      },
    );
  }, [project]);

  const sendReport: ProjectAPI["sendReport"] = useCallback(
    (users) => {
      return apiSendReport(
        (project as ProjectAPILoaded["project"]).id,
        users,
      ).then(() => {
        return;
      });
    },
    [project],
  );

  return (
    <ProjectContext.Provider
      value={{
        project,
        loadProjectById,
        updateProject,
        deleteProject,
        createWrapUp,
        departmentsRecap,
        loadDepartmentsRecap,
        trucksRecap,
        loadTrucksRecap,
        createOrder,
        deleteOrder,
        createDeliverySlip,
        updateDeliverySlip,
        deleteDeliverySlip,
        validateDeliverySlip,
        invalidateDeliverySlip,
        syncDeliverySlips,
        unsyncDeliverySlips,
        sendReport,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export function withProvideProject<P extends Record<string, unknown>>(
  WrappedComponent: ComponentType<P>,
): ComponentType<P> {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || "Component";

  function WithProvideProject(props: P) {
    return (
      <ProvideProject>
        <WrappedComponent {...props} />
      </ProvideProject>
    );
  }

  WithProvideProject.displayName = `withProvideProject(${displayName})`;

  return WithProvideProject;
}
