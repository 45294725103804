import { number, object, string } from "yup";

export interface ReviewForm {
  note: number;
  materialType: MaterialType | null;
  dailyTonnage: DailyTonnage | null;
  profitsBrought: string | null;
  improvementsWanted: string | null;
  details: string | null;
  extendAccess: ExtendAccess | null;
  extendAccessPrecisions: string | null;
}

export interface Review {
  id: number;
  note: number;
  materialType: MaterialType | null;
  dailyTonnage: DailyTonnage | null;
  profitsBrought: string | null;
  improvementsWanted: string | null;
  details: string | null;
  extendAccess: ExtendAccess | null;
  extendAccessPrecisions: string | null;
}

export enum MaterialType {
  WHITE,
  BLACK,
  BLACK_AND_WHITE,
}

export enum DailyTonnage {
  HUNDRED,
  HUNDRED2,
  HUNDRED3,
  HUNDRED4,
  HUNDRED5,
  HUNDRED6,
  HUNDRED7,
  HUNDRED8,
  HUNDRED9,
  THOUSAND,
}

export enum ExtendAccess {
  VERY,
  NORMAL,
  LITTLE,
  NOT,
}

export const ReviewSchema = object({
  note: number().label("Note").nullable().required().oneOfEnum([1, 2, 3, 4, 5]),
  materialType: number()
    .label("Type de matériaux concernés")
    .nullable()
    .oneOfEnum(MaterialType, "review", "material-type"),
  dailyTonnage: number()
    .label("A partir de quel tonnage l'application vous est utile ?")
    .nullable()
    .oneOfEnum(DailyTonnage, "review", "daily-tonnage"),
  profitsBrought: string().label("Bénéfices apportés").nullable(),
  improvementsWanted: string().label("Améliorations souhaitées").nullable(),
  details: string().label("Précisions").nullable().multiline(),
  extendAccess: number()
    .label("Seriez-vous favorable à la prolongation de votre accès ?")
    .nullable()
    .oneOfEnum(ExtendAccess, "review", "extend-access"),
  extendAccessPrecisions: string()
    .label("Précisez les raisons")
    .nullable()
    .when("extendAccess", {
      is(extendAccess: number | null) {
        return extendAccess !== null && extendAccess >= 2;
      },
      otherwise: (schema) => schema.notVisible(),
    }),
});
