import { number, object, string } from "yup";

interface DepartmentBase {
  name: string;
  address: string | null;
  postalCode: string;
  city: string;
  country: string;
  landlineNumber: string | null;
  email: string | null;
  storageCapacity: number | null;
  productionCapacity: number | null;
  externalId: number | null;
}
export interface Department extends DepartmentBase {
  id: number;
}

export interface FullDepartment extends Department {
  usedInProject: boolean;
}

export interface DepartmentForm extends DepartmentBase {}

export const DepartmentFormSchema = object()
  .shape({
    name: string()
      .label("Nom")
      .nullable()
      .when("$usedInProject", {
        is: true,
        then: (schema) => schema.notEditable(),
        otherwise: (schema) => schema.required(),
      }),
    externalId: string()
      .label(
        "Votre Code Client attribué par cette Centrale (renseignez vous auprès de la Centrale)",
      )
      .nullable(),
    address: string().label("Adresse").nullable(),
    postalCode: string()
      .label("Code postal ou département")
      .nullable()
      .required()
      .matches(/^(\d{5}|\d{2})$/)
      .when("$usedInProject", {
        is: true,
        then: (schema) => schema.notEditable(),
        otherwise: (schema) => schema.required(),
      }),
    city: string()
      .label("Ville")
      .nullable()
      .required()
      .when("$usedInProject", {
        is: true,
        then: (schema) => schema.notEditable(),
        otherwise: (schema) => schema.required(),
      }),
    country: string()
      .label("Pays")
      .nullable()
      .required()
      .when("$usedInProject", {
        is: true,
        then: (schema) => schema.notEditable(),
        otherwise: (schema) => schema.required(),
      }),
    landlineNumber: string().label("Téléphone").nullable(),
    email: string().label("Email").nullable(),
    storageCapacity: number().label("Capacité de stockage").nullable(),
    productionCapacity: number().label("Capacité de fabrication").nullable(),
  })
  .defined();
